import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  MarkerType,
  useReactFlow,
  ReactFlowProvider,
} from "react-flow-renderer";
import dagre from "dagre";
import NodeWrapper from "./NodeWrapper";

// Define nodeTypes outside of the component
const nodeTypes = {
  custom: NodeWrapper, // Custom node for TankIcon
};

const applyDagreLayout = (nodes, edges) => {
  const g = new dagre.graphlib.Graph();
  g.setGraph({});
  // g.setGraph({ rankdir: 'LR' }); // Set layout to horizontal (Left to Right)

  g.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => {
    g.setNode(node.id, { width: 172, height: 36 });
  });

  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  // Apply Dagre layout
  dagre.layout(g);

  // Adjust the positions after applying Dagre layout
  nodes.forEach((node) => {
    const nodeWithPosition = g.node(node.id);
    node.position = {
      x: nodeWithPosition.x, // - 85, // Apply horizontal adjustments
      y: node.position.y, // Keep the vertical position fixed
    };
  });

  return nodes;
};

const getColor = (color = "RED") => {
  switch (color) {
    case "RED":
      return "#64242e";
    case "ROSE":
      return "#c74f59";
    case "WHITE":
      return "#f9d573";
    default:
      return "#64242e";
  }
};

const FlowChart = ({ operations, toggleShowOperationData }) => {
  // const { fitView } = useReactFlow();

  const initialNodesAndEdges = (data) => {
    const nodes = [];
    const edges = [];
  
    let yOffset = 100; // Initial Y position for the first operation
    const xOffsetStart = 100; // Starting X position for nodes
    const tankStateMap = new Map(); // Tracks the latest node for each tank
  
    data.forEach((operation, i) => {
      if (["DESTEMMING", "NEW_LOT", "DECANT"].includes(operation.operation_type)) {
        // Add a parent node for the operation
        const parentNodeId = `parent_${operation.operation_id}`;
        nodes.push({
          id: parentNodeId,
          type: "custom",
          position: { x: xOffsetStart + 200, y: yOffset },
          data: {
            operation_type: operation.operation_type,
            ...(operation.operation_type === "DESTEMMING" && { lot: { ...operation?.transfers?.[0] } }),
            ...(operation.operation_type !== "DESTEMMING" && { 
              tank: { 
                ...operation?.transfers?.[0], 
                name: operation?.transfers?.[0]?.source_tank_name,
                batch_name: operation?.transfers?.[0]?.source_batch_name,
                quantity: operation?.transfers?.[0]?.source_batch_quantity,
              } }),
          },
        });
    
        const spacing = 300; // Horizontal spacing between child nodes
        let xPosition = xOffsetStart;
    
        operation?.transfers?.forEach((transfer) => {
          // Create a new node for the tank's current state
          const currentNodeId = `${operation.operation_id}_${transfer.destination_tank_id}`;
          nodes.push({
            id: currentNodeId,
            type: "custom",
            position: { x: xPosition, y: yOffset + (["DESTEMMING", "NEW_LOT"].includes(operation.operation_type) ? 200 : 250) },
            data: {
              operation_id: operation?.operation_id,
              tank: {
                name: transfer.destination_tank_name,
                batch_name: transfer.batch_name,
                quantity: transfer.quantity,
                capacity: transfer.capacity,
                color: transfer.color,
                type: transfer?.type || "TANK",
                is_focused: false,
              },
            },
          });
    
          // Connect parent operation node to the current tank state node
          edges.push({
            id: `e_${parentNodeId}-${currentNodeId}`,
            source: parentNodeId,
            target: currentNodeId,
            label: `${transfer.arrow_quantity}L`,
            animated: true,
            arrowHeadType: "arrowclosed",
            type: "smoothstep",
            style: { stroke: getColor(transfer?.color), strokeWidth: 2 },
            markerEnd: {
              type: MarkerType.Arrow,
              width: 15,
              height: 15,
              color: getColor(transfer?.color),
            },
          });
    
          // Check if this tank has a previous state
          const previousTankNodeId = tankStateMap.get(transfer.destination_tank_id);
          if (previousTankNodeId && previousTankNodeId !== currentNodeId) {
            // Connect the previous state of the tank to the current state
            edges.push({
              id: `e_${previousTankNodeId}-${currentNodeId}`,
              source: previousTankNodeId,
              target: currentNodeId,
              label: null, // You can customize the label here if necessary
              animated: true,
              arrowHeadType: "arrowclosed",
              type: "smoothstep",
              style: { stroke: getColor(transfer?.color), strokeWidth: 2 },
              markerEnd: {
                type: MarkerType.Arrow,
                width: 15,
                height: 15,
                color: getColor(transfer?.color),
              },
            });
          }
    
          // Update the latest state of the tank in the map
          tankStateMap.set(transfer.destination_tank_id, currentNodeId);
    
          xPosition += spacing;
        });
    
      } else if (["TREATMENT", "ANALYSIS"].includes(operation.operation_type)) {
        // Add a parent node for the operation
        const parentNodeId = `parent_${operation.operation_id}`;
        nodes.push({
          id: parentNodeId,
          type: "custom",
          position: { x: xOffsetStart, y: yOffset + 100 },
          data: {
            operation_type: operation.operation_type,
            treatments: operation?.treatments || [],
          },
        });
    
        if (i === 0) {
          // First operation (TREATMENT or ANALYSIS) as before
          if (data[i + 1]) {
            const nextOperation = data[i + 1];
            const nextNodeId = `parent_${nextOperation.operation_id}`;
            edges.push({
              id: `e_${parentNodeId}-${nextNodeId}`,
              source: parentNodeId,
              target: nextNodeId,
              label: null, // You can customize the label here if necessary
              animated: true,
              arrowHeadType: "arrowclosed",
              type: "smoothstep",
              style: { stroke: getColor(operation?.transfers?.[0]?.color), strokeWidth: 2 },
              markerEnd: {
                type: MarkerType.Arrow,
                width: 15,
                height: 15,
                color: getColor(operation?.transfers?.[0]?.color),
              },
            });
          }
        } else {
          // Not the first operation (TREATMENT or ANALYSIS)
          const prevOperation = data[i - 1];
    
          // Identify the most recent tank node from the previous operation's transfers
          let previousTankNodeId = null;
          if (prevOperation?.transfers?.length > 0) {
            const previousTransfer = prevOperation.transfers[prevOperation.transfers.length - 1];
            previousTankNodeId = `${prevOperation.operation_id}_${previousTransfer.destination_tank_id}`;
          }
    
          if (previousTankNodeId) {
            // Connect from the tank node to the current operation node
            edges.push({
              id: `e_${previousTankNodeId}-${parentNodeId}`,
              source: previousTankNodeId,
              target: parentNodeId,
              label: null, // You can customize the label here if necessary
              animated: true,
              arrowHeadType: "arrowclosed",
              type: "smoothstep",
              style: { stroke: getColor(operation?.transfers?.[0]?.color), strokeWidth: 2 },
              markerEnd: {
                type: MarkerType.Arrow,
                width: 15,
                height: 15,
                color: getColor(operation?.transfers?.[0]?.color),
              },
            });
          }
        }
      }
    
      // Increase the yOffset for the next operation
      yOffset += 300;
      // yOffset += (['ANALYSIS'].includes(operation.operation_type) && i === 0) ? 150 : 300; // Larger vertical spacing between parent nodes    
    });        
  
    return { nodes, edges };
  };
  
  
  // const initialNodesAndEdges2 = (data) => {
  //   const nodes = [];
  //   const edges = [];

  //   let yOffset = 100; // Initial Y position for the first operation
  //   const xOffsetStart = 100; // Starting X position for nodes

  //   data.forEach((operation, index) => {
  //     if (
  //       operation.operation_type === "DESTEMMING" ||
  //       operation.operation_type === "NEW_LOT"
  //     ) {
  //       // Add a parent node
  //       const parentNodeId = `parent_${operation.operation_id}`;
  //       nodes.push({
  //         id: parentNodeId,
  //         type: "custom",
  //         position: { x: xOffsetStart + 200, y: yOffset },
  //         data: {
  //           operation_type: operation.operation_type,
  //           lot: { ...operation?.transfers?.[0] },
  //         }, // Pass lot data to the component
  //       });

  //       yOffset += 200; // Increment y position for children

  //       const spacing = 300;
  //       let xPosition = xOffsetStart;

  //       operation.transfers.forEach((transfer) => {
  //         if (!transfer.source_tank_id) {
  //           const nodeId = `${operation.operation_id}_${transfer.destination_tank_id}`;

  //           nodes.push({
  //             id: nodeId,
  //             type: "custom",
  //             position: { x: xPosition, y: yOffset }, // Apply yOffset here
  //             data: {
  //               operation_id: operation?.operation_id,
  //               tank: {
  //                 name: transfer.destination_tank_name,
  //                 batch_name: transfer.batch_name,
  //                 quantity: transfer.quantity,
  //                 capacity: transfer.capacity,
  //                 color: transfer.color,
  //                 type: transfer?.type || "TANK",
  //                 is_focused: false,
  //               },
  //             },
  //           });

  //           edges.push({
  //             id: `e_${parentNodeId}-${nodeId}}`,
  //             source: parentNodeId,
  //             target: nodeId,
  //             label: `${transfer.arrow_quantity}L`,
  //             animated: true,
  //             arrowHeadType: "arrowclosed",
  //             type: "smoothstep",
  //             style: { stroke: getColor(transfer?.color), strokeWidth: 2 },
  //             markerEnd: {
  //               type: MarkerType.Arrow,
  //               width: 15,
  //               height: 15,
  //               color: getColor(transfer?.color),
  //             },
  //           });

  //           xPosition += spacing;
  //         }
  //       });

  //       // Increase the yOffset for the next operation's Y position
  //       yOffset += 200; // Increased vertical spacing between operations
  //     }

  //     if (operation.operation_type === "DECANT") {
  //       const sourceTank = operation.transfers.find(
  //         (transfer) => transfer.source_tank_id
  //       );
  //       const decantSpacing = 300;
  //       let xPosition = xOffsetStart;

  //       operation.transfers.forEach((transfer) => {
  //         if (transfer.source_tank_id) {
  //           const nodeId = `${operation.operation_id}_${transfer.destination_tank_id}`;

  //           nodes.push({
  //             id: nodeId,
  //             type: "custom",
  //             position: { x: xPosition, y: yOffset }, // Apply yOffset here
  //             data: {
  //               operation_id: operation?.operation_id,
  //               tank: {
  //                 name: transfer.destination_tank_name,
  //                 batch_name: transfer.batch_name,
  //                 quantity: transfer.quantity,
  //                 capacity: transfer.capacity,
  //                 color: transfer.color,
  //                 type: transfer?.type || "TANK",
  //                 is_focused: false,
  //               },
  //             },
  //           });

  //           const prevOperationId =
  //             index === 0 ? null : data?.[index - 1]?.operation_id;
  //           const edgeSource = `${prevOperationId}_${sourceTank.source_tank_id}`;
  //           const edgeTarget = `${operation.operation_id}_${transfer.destination_tank_id}`;

  //           edges.push({
  //             id: `e_${edgeSource}-${edgeTarget}}`,
  //             source: edgeSource,
  //             target: edgeTarget,
  //             label: `${transfer.arrow_quantity}L`,
  //             animated: true,
  //             arrowHeadType: "arrowclosed",
  //             type: "smoothstep",
  //             style: { stroke: getColor(transfer?.color), strokeWidth: 2 },
  //             markerEnd: {
  //               type: MarkerType.Arrow,
  //               width: 15,
  //               height: 15,
  //               color: getColor(transfer?.color),
  //             },
  //           });

  //           xPosition += decantSpacing;
  //         }
  //       });

  //       // Increase the yOffset for the next operation
  //       yOffset += 200; // Increased vertical spacing between operations
  //     }
  //   });

  //   return { nodes, edges };
  // };

  // Handler for node clicks
  const handleNodeClick = (event, node) => {
    const operation_id = node?.data?.operation_id;
    if (operation_id) {
      toggleShowOperationData(operation_id);
      // fitView({ padding: 0.1, duration: 800 }); // Fit view with animation
    }
  };

  const { nodes, edges } = initialNodesAndEdges(operations);
  const nodesWithLayout = applyDagreLayout(nodes, edges);

  return (
      <div style={{ height: "80vh" }}>
        <ReactFlow
          nodes={nodesWithLayout}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodeClick={handleNodeClick}
          fitView
        >
          <MiniMap nodeColor={() => "#8b0000"} />
          <Controls />
          <Background />
        </ReactFlow>
      </div>
  );
};

export default FlowChart;
