import { useEffect, useMemo, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";

import "./styles/index.scss";

import {
  flexRender,
  getCoreRowModel,
  SortingState,
  PaginationState,
  ColumnFiltersState,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getExpandedRowModel,
  ColumnDef,
  OnChangeFn,
  FilterFn,
  FilterFns,
  SortingFn,
  sortingFns,
} from "@tanstack/react-table";

import FooterCell from "./FooterCell";
import useLabels from "../../hooks/useLabels";
import DebouncedInput from "./DebouncedInput";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CTA, Tooltip } from "../Generic";
import ElementCard from "./ElementCard";
import CardFilters from "../CardFilters";

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  // store the itemRank info
  addMeta({
    itemRank,
  });
  // console.log("fuzzy", row, columnId, value);

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const MultipleRowsFilterFn = (row, columnId, value) => {
  const array = [];
  const val = value
    ?.trim()
    ?.toLowerCase?.()
    ?.replace(/[^ 0-9A-Z]+/gi, ""); // clear non-alphanumeric characters

  const col = row.getAllCells()?.find((c) => c?.id.includes(columnId)) || null;
  const meta = col.column.columnDef?.meta;

  if (meta?.selector === "aggregate") {
    const len = row.original?.origins?.length || 0;
    for (let i = 0; i < len; i++)
      array.push(
        `${row.original?.origins?.[i]} ${row.original?.years?.[i]} ${row.original?.varieties?.[i]}`
      );
  }

  switch (columnId) {
    case "cellarman":
      const cellarmansColumn =
        row.getAllCells()?.find((c) => c?.id.includes("cellarman")) || null;
      if (cellarmansColumn !== null) {
        array.push(
          ...cellarmansColumn.column.columnDef?.meta?.array?.map(
            (c) => c?.username
          )
        );
      }
      break;
    case "lotTanks":
      array.push(...(row.original?.tanks?.map((t) => t?.tank_name) || []));
      break;
    case "operationSrcTank":
      array.push(
        ...(row.original?.src_tanks?.map((t) => t?.preOperation?.tank?.name) ||
          [])
      );
      break;
    case "substance_name":
      const substanceColumn =
        row.getAllCells()?.find((c) => c?.id.includes("substance_name")) ||
        null;
      const substanceColumnType =
        substanceColumn === null
          ? null
          : substanceColumn.column.columnDef?.meta?.array || null;

      if (substanceColumn !== null && substanceColumnType !== null) {
        let filteredSubstances = [];
        if (substanceColumnType === "analysis") {
          const substanceColumnArray =
            substanceColumn.column.columnDef?.meta?.protocols?.map((p) => {
              return {
                substance_name: p?.name,
                translated_name: p?.translated_name,
              };
            });
          const currentSubstanceColumnArray = row.original?.[
            substanceColumnType
          ]?.map((a) => a?.substance_name);
          filteredSubstances.push(
            ...substanceColumnArray
              ?.filter((s) =>
                currentSubstanceColumnArray?.includes(s?.substance_name)
              )
              ?.map((a) => a?.translated_name)
          );
        } else {
          filteredSubstances.push(
            ...row.original?.[substanceColumnType]?.map(
              (s) => s?.substance_name
            )
          );
        }
        array.push(...filteredSubstances);
      }
      break;
    case "operationDestTank":
      array.push(
        ...(row.original?.dest_tanks?.map((t) => t?.preOperation?.tank?.name) ||
          [])
      );
      break;
    // case "substance_name":
    //   const protocolType =  row.original?.type;
    //   const array = row?.original?.[protocolType?.toLowerCase()]?.map((s) => s?.substance_name) || [];
    //   const lenS = row.original?.origins?.length || 0;
    //   console.log('sssff', protocolType, row?.original, '-->', array)
    //   for (let i = 0; i < lenS; i++)
    //     array.push(
    //       `${row.original?.origins?.[i]} `
    //     );
    //   break;
  }
  if (array?.length > 0 && val)
    return !!array?.find((item) => item?.toLowerCase?.().includes(val));

  return false;
};

const DateInRange = (row, columnId, value) => {
  const date = new Date(row.getValue(columnId));

  const [startDate, endDate] = value; // value => two date input values
  const start = startDate ? new Date(startDate) : null;
  const end = endDate ? new Date(endDate) : null;

  // if one filter defined and date is null filter it
  if ((start || end) && !date) return false;
  if (start && !end) {
    return date.getTime() >= start.getTime();
  } else if (!start && end) {
    return date.getTime() <= end.getTime();
  } else if (start && end) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
  } else return true;
};

const QuantityInRange = (row, columnId, value) => {
  const quantity = parseInt(row.original?.actual_quantity) || null;
  if (quantity !== null && quantity >= 0) {
    return quantity >= value?.[0] && quantity <= value?.[1];
  }

  return false;
};

const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;
  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank || "",
      rowB.columnFiltersMeta[columnId]?.itemRank || ""
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const PrioritySortingFn = (rowA, rowB, columnId) => {
  return rowA.original?.priority < rowB.original?.priority ? -1 : 1;
};

const TableEdit = ({
  defaultData = [],
  additionalData = [],
  columns,
  newRow,
  isPagination = false,
  isSearchBar = true,
  isEditable = false,
  isSorting = true,
  groupHandler = null,
  errors,
  control,
  setValue,
  getValues,
  rowClickHandler,
  analysisState,
  minEditableIndex,
  maxEditableIndex,
  type,
  modalHandler,
  setModifyMode,
  setRows,
  reset,
  setIsReset,
  filterType = null,
  isLegend = true,
  isMobile = false,
}) => {
  const [getLabel] = useLabels();
  const [data, setData] = useState(() => [...defaultData]);
  const [originalData, setOriginalData] = useState(() => [...defaultData]);
  const [editedRows, setEditedRows] = useState({});
  const [sorting, setSorting] = useState([]);
  const [headerOver, setHeaderOver] = useState(-1);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    if (
      !defaultData ||
      data?.length === 0 ||
      ["winemaking", "PRE", "POST", "treatment", "analysis", "analysisDetail", "operation", "treatmentApplication", "treatmentApplicationUser", "lot"].includes(type)
    ) {
      setData([...defaultData]);
      setOriginalData([...defaultData]);
    }
  }, [defaultData]);

  // useEffect(() => {
  //   // if (!data || data?.length === 0) {
  //     setData([...defaultData]);
  //     setOriginalData([...defaultData]);
  //   // }
  //   console.log('quiiii', data, originalData)
  // }, []);

  useEffect(() => {
    if (reset === true) {
      setData([]);
      setOriginalData([]);
      setIsReset(false);
    }
  }, [reset]);

  const table = useReactTable({
    data,
    columns,
    enableColumnFilters: true,
    enableFilters: true,
    enableColumnFilter: true,
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzyFilter: fuzzyFilter,
      MultipleRowsFilterFn: MultipleRowsFilterFn,
      InNumberRange2: QuantityInRange,
      DateInRange: DateInRange,
    },
    sortingFns: {
      PrioritySortingFn: PrioritySortingFn,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    globalSortingFn: fuzzySort,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),         // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
    getExpandedRowModel: getExpandedRowModel(),
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 2,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: isPagination
      ? getPaginationRowModel()
      : () => {
          return null;
        },
    enableRowSelection: true,
    meta: {
      minEditableIndex,
      maxEditableIndex,
      analysisState,
      editedRows,
      type,
      modalHandler,
      setModifyMode,
      setValue,
      setEditedRows,
      revertData: (rowIndex, revert, substanceIndex) => {
        if (revert && substanceIndex) {
          // rowIndex is substance_id in case of type in ["ANALYSIS", "TREATMENT", "PROTOCOL", "WINEMAKINGPROTOCOL"]
          const row = getValues(`column.${substanceIndex}`);
          const old = row?.old;
          setValue(`column.${rowIndex}.value`, old)
          setValue(`column.${substanceIndex}.new`, old);
          setData((old) =>
            old.map((row, index) =>
              index === rowIndex ? originalData[rowIndex] : row
            )
          );
        } else {
          setOriginalData((old) =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
          );
        }
      },
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      getControl: () => control,
      getErrors: () => errors,
      getAdditional: () => additionalData,
      getValues: (path) => {return getValues(path)},
      addRow: (newR) => {
        if (newR && Object.keys(newR)?.length > 0) {
          if (['analysis', 'newAnalysisProtocol'].includes(type) && newR?.items?.length > 0) {
            newR?.items?.forEach((exam) => {
              const id = exam?.id;
              newR.id = id;
              newR.value = id;
              newR.removed = false;
              
              setValue(`column.${id}`, { ...newR });
              setValue(`removed.${id}`, false);
            })
          } else {
            setValue(`column.${newR?.value}.value`, newR.quantity);
            setValue(`removed.${newR?.value}`, false);
            
            if (newR.opType === 'PROTOCOL') {
              newR.id = newR.value;
              setValue(`column.${newR?.value}`, newR);
            }
          }
        }
        const setFunc = (old) => [
          ...old,
          newR && Object.keys(newR)?.length > 0 ? newR : newRow,
        ];
        setData(setFunc);
        setOriginalData(setFunc);
      },
      selectRow: (rowIndex, selected) => {
        const id =
          table.getRow(rowIndex).original?.substance_id ||
          table.getRow(rowIndex).original?.value ||
          table.getRow(rowIndex).original.id;
        setValue(`removed.${id}.value`, selected);
      },
      removeRow: (rowIndex) => {
        const row = table.getRow(rowIndex).original;
        if (['analysis', 'newAnalysisProtocol'].includes(type) && row?.items?.length > 0) {
          row?.items?.forEach((exam) => {
            const id = exam?.id || exam?.substance_id;
            if (id !== undefined) setValue(`removed.${id}`, true);
          });
        } else {
          const id =
            table.getRow(rowIndex).original.value ||
            table.getRow(rowIndex).original.id;
          setValue(`removed.${id}`, true);
        }
        const setFilterFunc = (old) =>
          old.filter((_row, index) => index !== rowIndex);

        setData(setFilterFunc);
        setOriginalData(setFilterFunc);

        if ("winemaking" === type)
          setRows(
            data
              ?.filter((op, index) => index !== rowIndex)
              ?.sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0))
          );
      },
      removeSelectedRows: (selectedRows) => {
        selectedRows?.forEach((rowIndex) => {
          const row = table.getRow(rowIndex).original;
          if (['analysis', 'newAnalysisProtocol'].includes(type) && row?.items?.length > 0) {
            row?.items?.forEach((exam) => {
              const id = exam?.id || exam?.substance_id;
              if (id !== undefined) setValue(`removed.${id}`, true);
            });
          } else {
            const id = ["analysis", "treatment"].includes(type)
              ? row?.substance_id
              : row?.value ||
                row?.id;
            if (id !== undefined) setValue(`removed.${id}`, true);
          }
        });

        const setFilterFunc = (old) =>
          old.filter((_row, index) => !selectedRows.includes(index));
        setData(setFilterFunc);
        setOriginalData(setFilterFunc);

        if ("winemaking" == type)
          setRows(
            data
              ?.filter((op, index) => !selectedRows.includes(index))
              ?.sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0))
          );
      },
    },
  });

  const ref = useRef(null);

  const setGlobalFilterByValue = (value) => {
    setGlobalFilter(String(value));
    setFilterOpened("");
    setIsFilters(String(value)?.length > 0);
  };

  const renderSearchBar = () => {
    return (
      <div className="search-box">
        <button
          className="btn-search"
          onClick={() => {
            ref.current.focus();
          }}
        >
          <FontAwesomeIcon icon="search" />
        </button>
        <DebouncedInput
          forwardRef={ref}
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilterByValue(value)}
          className="input-search"
          placeholder={getLabel("search_Placeholder")}
        />
      </div>
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const resetFilters = () => {
    table.resetColumnFilters();
    table.resetGlobalFilter();
    setIsFilters(false);
  };

  const [isGrouped, setIsGrouped] = useState(false);

  const groupOperations = () => {
    if (groupHandler) {
      const filteredData = groupHandler(data);
      if (!isGrouped) setData(filteredData);
      else setData(defaultData);
      setIsGrouped((prev) => !prev);
    }
  };

  const renderFilters = () => {
    return (
      <div className="filter-box">
        <div className="btn-filter">
          {groupHandler && (
            <Tooltip
              variant={"info"}
              html={getLabel(
                !isGrouped
                  ? "filtersRemoveRecurrentOpsTooltip"
                  : "filtersRestoreRecurrentOpsTooltip"
              )}
              place="left"
              events={["hover"]}
            >
              <CTA
                customClassName={`${isGrouped ? "complete" : ""}`}
                onClick={() => groupOperations()}
              >
                <FontAwesomeIcon icon="fa-group-arrows-rotate" />
              </CTA>
            </Tooltip>
          )}
          <Tooltip
            variant={"info"}
            html={getLabel("filtersRemoveTooltip")}
            place="left"
            events={["hover"]}
          >
            <CTA
              className="remove"
              disabled={!isFilters}
              onClick={() => resetFilters()}
            >
              <FontAwesomeIcon icon="fa-filter-circle-xmark" />
            </CTA>
          </Tooltip>
        </div>
        {/* <TableFilterModal
          isOpen={isOpen}
          onConfirm={null}
          onClose={handleCloseModal}
          description={getLabel("modalFilterDescription")}
        >
          {filterType === "lots" && (
            <>
            </>
          )}
        </TableFilterModal> */}
      </div>
    );
  };

  const renderLegend = () => {
    return (
      <div>
        <h5>{getLabel("legend")}</h5>
        <ul>
          <li>
            <span>{getLabel("filtersOn")}</span>
            <span className="icon red">
              <FontAwesomeIcon
                icon="fa-filter"
                style={{ color: "rgba(243, 239, 245, 1)" }}
                size={"xs"}
              />
            </span>
          </li>
          <li>
            <span>{getLabel("filtersOff")}</span>
            <span className="icon red">
              <FontAwesomeIcon
                icon="fa-filter"
                style={{ color: "rgba(243, 239, 245, 0.5)" }}
                size={"xs"}
              />
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const renderPaginationTools = () => {
    return (
      <>
        <div className="pagination-items">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="current-page">
            <div>{getLabel("page")}&nbsp;</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} {getLabel("of")}{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="">
            |&nbsp;&nbsp;&nbsp;{getLabel("goToPage")}:&nbsp;
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="input-page"
            />
          </span>
          <select
            className="button fix-height"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {getLabel(`show`)} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const [filterOpened, setFilterOpened] = useState("");
  const [isFilters, setIsFilters] = useState(null);
  const [filters, setFilters ] = useState([]);

  const handleFilter = (e, column, o = null) => {
    e.stopPropagation();
    const columnId = column.id;
    if (o !== null) {
      column.setFilterValue(o === "all" ? "" : o); // trick --> '-' li All selector
      setFilterOpened("");
      setIsFilters(o === "all" ? false : true);
    } else {
      setFilterOpened(columnId);
    }
  };

  const handleTextChange = (column, value) => {
    column.setFilterValue(value);
    setIsFilters(String(value)?.length > 0);
  };

  const isValidDate = (d) => {
    const parsedDate = new Date(d);
    return parsedDate instanceof Date && !Number.isNaN(parsedDate);
  };

  const Filter = ({ column, rows }) => {
    const filterRef = useRef();

    useClickOutside(filterRef, () => {
      if (filterOpened) {
        setFilterOpened("");
      }
    });

    const columnFilterValue = column.getFilterValue();
    const { filterVariant, selector, array, isFilterDisabled } =
      column.columnDef.meta ?? {};

    let sortedUniqueValues = useMemo(
      () =>
        ["range", "date"].includes(filterVariant) ||
        ["tank_name", "type", "aggregate", "cellarman_ids"].includes(selector)
          ? []
          : Array.from(column.getFacetedUniqueValues().keys())
              .sort()
              .slice(0, 5000),
      [column.getFacetedUniqueValues(), filterVariant]
    );


    const tmp = new Set();
    if (selector) {
      if (selector === "type") {
        data?.map((row) => {
          const opType = row?.[selector]?.split(" ")?.[0] || null;
          if (opType !== null) {
            tmp.add(getLabel(`operationType${opType}`));
          }
        });
      } else if (selector === "substance_name") {
        data?.forEach((d) =>
          d?.[array]?.map((row) => {
            tmp.add(
              array === "analysis"
                ? getLabel(`substance_${row?.substance_name}`)
                : row?.substance_name
            );
          })
        );
      } else {
        rows?.map((row) => {
          if (selector === "name") {
            if (row?.type === "BOTTLING" && array === "dest_tanks") return true;
            const arr =
              row?.[array]?.map((t) => t?.preOperation?.tank?.name) || [];
            if (arr?.length > 0) {
              arr.forEach((item) => tmp.add(item));
            }
          } else if (selector === "cellarman_ids") {
            const arr =
              array
                ?.filter((u) => row?.[selector].includes(u?.id))
                ?.map((u) => u?.username) || [];
            if (arr?.length > 0) {
              arr.forEach((item) => tmp.add(item));
            }
          } else if (selector === "tank_name") {
            const arr = row?.[array]?.map((t) => t?.[selector]) || [];
            if (arr?.length > 0) {
              arr.forEach((item) => tmp.add(item));
            }
          } else if (
            filterVariant === "range" &&
            selector === "actual_quantity"
          ) {
            const quantity = parseInt(row?.actual_quantity) || null;
            if (quantity !== null && quantity >= 0) {
              tmp.add(quantity);
            }
          }
        });
      }

      if (
        [
          "tank_name",
          "type",
          "name",
          "substance_name",
          "cellarman_ids",
        ].includes(selector)
      ) {
        sortedUniqueValues.push(
          ...Array.from(tmp)?.sort((a, b) =>
            a?.localeCompare(b, undefined, { numeric: true })
          )
        );
      } else if (selector === "tankType") {
        sortedUniqueValues = sortedUniqueValues?.map((s) => s?.split(" ")?.[0]);
      } else if (filterVariant === "range" && selector === "actual_quantity") {
        const arr = Array.from(tmp);
        if (arr?.length > 0) {
          sortedUniqueValues.push(Math.min(...arr), Math.max(...arr));
          // setIsFilters(true);
          // setFilterOpened(column.id);
        }
      }
    }

    let searchPlaceholder = `${getLabel("search_Placeholder")} `;
    if (!["aggregate", "substance_name"].includes(selector)) {
      searchPlaceholder += ` (${
        ["tank_name", "type", "name", "cellarman_ids"].includes(selector)
          ? sortedUniqueValues.length
          : Array.from(column.getFacetedUniqueValues().keys()).filter(val => val !== null).length
      })`;
    }

    const startDate = filterVariant === "date" ? columnFilterValue?.[0] : null;
    const endDate = filterVariant === "date" ? columnFilterValue?.[1] : null;
    // console.log(startDate, typeof startDate)

    if (isFilterDisabled) return;

    return (
      <div
        className={"filters"}
        ref={filterRef}
        onMouseEnter={() => setHeaderOver(-1)}
      >
        <div className={"dropdown-filter"}>
          <div className={"icon"} onClick={(e) => handleFilter(e, column)}>
            <FontAwesomeIcon
              icon="fa-filter"
              size={"xs"}
              style={{
                color:
                  column.getFilterValue()?.length > 0
                    ? "rgba(243, 239, 245, 1)"
                    : "rgba(243, 239, 245, 0.5)",
              }}
            />
          </div>
          {filterOpened === column.id && (
            <div
              className={`dropdown-filter-content ${
                String(filterOpened) === column.id ? "open" : ""
              }`}
            >
              {filterVariant === "range" ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <div className="range">
                    <DebouncedInput
                      type="number"
                      min={Number(sortedUniqueValues?.[0] ?? "")}
                      max={Number(sortedUniqueValues?.[1] ?? "")}
                      value={columnFilterValue?.[0] ?? ""}
                      onChange={(value) =>
                        column.setFilterValue((old) => [
                          Number(value),
                          old?.[1],
                        ])
                      }
                      placeholder={`Min ${
                        sortedUniqueValues?.[0]
                          ? `(${sortedUniqueValues?.[0]})`
                          : ""
                      }`}
                      className=""
                    />
                    <DebouncedInput
                      type="number"
                      min={Number(sortedUniqueValues?.[0] ?? "")}
                      max={Number(sortedUniqueValues?.[1] ?? "")}
                      value={columnFilterValue?.[1] ?? ""}
                      onChange={(value) =>
                        column.setFilterValue((old) => [
                          old?.[0],
                          Number(value),
                        ])
                      }
                      placeholder={`Max ${
                        sortedUniqueValues?.[1]
                          ? `(${sortedUniqueValues?.[1]})`
                          : ""
                      }`}
                      className=""
                    />
                  </div>
                </div>
              ) : filterVariant === "select" ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <ul>
                    <li
                      value={"all"}
                      onClick={(e) =>
                        columnFilterValue?.toString()
                          ? handleFilter(e, column, "all")
                          : null
                      }
                      key={"li-"}
                      className={`all ${
                        columnFilterValue?.toString() ? "" : "disabled"
                      }`}
                    >
                      {getLabel("filtersAll")} <FontAwesomeIcon icon="remove" />
                    </li>
                    {sortedUniqueValues?.map((o, i) => {
                      return (
                        <li
                          onClick={(e) => handleFilter(e, column, o)}
                          className={
                            columnFilterValue?.toString() === o ? "active" : ""
                          }
                          value={columnFilterValue?.toString()}
                          key={`li-${o}`}
                        >
                          {o}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : filterVariant === "text" ? (
                <div
                  onClick={(e) => handleFilter(e, column)}
                  className="debounced"
                >
                  {/* Autocomplete suggestions from faceted values feature */}
                  <datalist id={column.id + "list"} className="datalist">
                    {sortedUniqueValues.map((value) => (
                      <option value={value} key={value} />
                    ))}
                  </datalist>
                  <DebouncedInput
                    forwardRef={ref}
                    type="text"
                    debounce={1000}
                    value={columnFilterValue ?? ""}
                    onChange={(value) => handleTextChange(column, value)}
                    placeholder={searchPlaceholder}
                    className=""
                    list={column.id + "list"}
                    isAutofocus={true}
                    setFilterOpened={setFilterOpened}
                  />
                </div>
              ) : filterVariant === "date" ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <div className="range">
                    <DebouncedInput
                      type="date"
                      // min={Number(sortedUniqueValues?.[0] ?? "")}
                      // max={Number(sortedUniqueValues?.[1] ?? "")}
                      // value={columnFilterValue?.[0] ?? ""}
                      // onChange={(value) =>
                      //   column.setFilterValue((old) => [
                      //     new Date(value),
                      //     old?.[1],
                      //   ])
                      // }
                      // placeholder={`Min ${
                      //   sortedUniqueValues?.[0] ? `(${sortedUniqueValues?.[0]})` : ""
                      // }`}
                      // value={startDate ? startDate.format('YYYY-MM-DD') : ''}
                      value={startDate ? startDate?.toDateString() : ""}
                      onChange={(value) => {
                        if (
                          isValidDate(value) &&
                          value !== "" &&
                          value !== "Invalid Date"
                        ) {
                          column.setFilterValue((old) => [
                            new Date(value),
                            old?.[1],
                          ]);
                        }
                      }}
                      className=""
                    />
                    <DebouncedInput
                      type="date"
                      // value={columnFilterValue?.[1] ?? ""}
                      // onChange={(value) =>
                      //   column.setFilterValue((old) => [
                      //     new Date(value),
                      //     old?.[1],
                      //   ])
                      // }
                      value={endDate ? endDate.format("YYYY-MM-DD") : ""}
                      onChange={(value) => {
                        if (
                          isValidDate(value) &&
                          value !== "" &&
                          value !== "Invalid Date"
                        )
                          column.setFilterValue((old) => [
                            old?.[0],
                            new Date(value),
                          ]);
                      }}
                      className=""
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  };


    const applyCardFilters = () => {
        const filteredData = [];
        if (filters?.length === 0) return table.getRowModel().rows;

        table.getRowModel().rows?.forEach((dd) => {
          const d = dd.original;
            let include = true;
            filters?.forEach((f) => {
                let props = '';
                // because varieties, origin and years are array for lots
                if (f?.type === "text" && f?.params?.length > 1) 
                    f?.params?.map((pp) => props = props + (d?.[pp] ? ` ${(Array.isArray(d?.[pp]) ? d?.[pp]?.toString() : d?.[pp])?.trim()?.toLowerCase()}` : ""))
                const prop = (f?.type === "text" && f?.params?.length > 1) ? props : d?.[f?.param]?.trim()?.toLowerCase();
                if (!prop || !prop?.includes(f?.value)) include = false;
                if (!include) return false;
            });
            if (include) filteredData.push(dd);
        });

        return filteredData;
    }

  if (isMobile)
    return (
      <div className="list-container">
        <div className="list_header_wrapper">
          <div className="card_filters_wrapper">
              <CardFilters type={type} data={data} appliedFilters={filters} setAppliedFilters={setFilters}/>
          </div>
        </div>
        <div className="list-wrapper">
          {applyCardFilters()?.map((row) => (
            <ElementCard 
              data={row.original} type={type} 
              rowClickHandler={() => {
                if (String(filterOpened)?.length > 0) {
                  setFilterOpened("");
                  return;
                }
                if (rowClickHandler) {
                  rowClickHandler(row);
                }
              }}
              className={`${
                rowClickHandler
                  ? String(filterOpened)?.length > 0
                    ? ""
                    : "clickable"
                  : ""
              }`}
            />
          ))}
        </div>
      </div>
    );

  return (
    <>
      <div className="box-wrapper">
        <div className="left-box">{isSearchBar && renderSearchBar()}</div>
        <div className="right-box">{filterType && renderFilters()}</div>
      </div>
      <article className="table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i) => (
                  <th key={`header_${i}_${header.id}`} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? `cursor-pointer select-none ${
                                isSorting ? "sort" : ""
                              }`
                            : "",
                          onClick: isSorting
                            ? header.column.getToggleSortingHandler()
                            : () => {
                                return;
                              },
                        }}
                      >
                        <div
                          className="table-header"
                          onMouseEnter={() => setHeaderOver(i)}
                          onMouseLeave={() => setHeaderOver(-1)}
                        >
                          <div className="table-header-value">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                          {isSorting && (
                            <div className="table-header-sorting">
                              {" "}
                              {!header.column.getIsSorted() &&
                                headerOver === i && (
                                  <FontAwesomeIcon icon="fa-sort" size={"sm"} />
                                )}
                              {!header.column.getIsSorted() &&
                                headerOver !== i && <p>&nbsp;&nbsp;</p>}{" "}
                              {/* trick to avoid translation on hover */}
                              {{
                                asc: (
                                  <FontAwesomeIcon
                                    icon="fa-sort-up"
                                    size={"sm"}
                                  />
                                ),
                                desc: (
                                  <FontAwesomeIcon
                                    icon="fa-sort-down"
                                    size={"sm"}
                                  />
                                ),
                                // asc: <> &uarr;</>,
                                // desc: <> &darr;</>,
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                          )}
                          {header.column.getCanFilter() ? (
                            <Filter
                              column={header.column}
                              rows={table
                                .getFilteredRowModel()
                                ?.rows?.map((r) => r?.original)}
                            />
                          ) : null}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={`row_${row?.original?.value || row.id}`}
                onClick={() => {
                  if (String(filterOpened)?.length > 0) {
                    setFilterOpened("");
                    return;
                  }
                  if (rowClickHandler) {
                    rowClickHandler(row);
                  }
                }}
                className={`${
                  rowClickHandler
                    ? String(filterOpened)?.length > 0
                      ? ""
                      : "clickable"
                    : ""
                } 
                ${
                  row?.original?.endOfSrcNameBorder && sorting?.length === 0
                    ? "tankRowSpan"
                    : ""
                } 
                ${
                  row?.original?.endOfRepBorder && sorting?.length === 0
                    ? "rowSpan"
                    : ""
                } `}
              >
                {row.getVisibleCells().map((cell, i) => (
                  <td key={`cell_${i}_${cell.id}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {(isEditable || type === 'treatmentApplication' || type === 'treatmentApplicationUser') && (
            <tfoot>
              <tr>
                <th colSpan={table.getCenterLeafColumns().length} align="right">
                  <FooterCell table={table} type={type === 'treatmentApplication' ? 'treatmentRecap' : type === 'treatmentApplicationUser' ? 'treatmentRecapUser' : null}/>
                </th>
              </tr>
            </tfoot>
          )}
        </table>
      </article>
      <div className="pagination">
        {isPagination && renderPaginationTools()}
      </div>
      {isLegend && (
        <div className="primary_container_lower legend">{renderLegend()}</div>
      )}
    </>
  );
};

export default TableEdit;
