import { useFieldArray, useForm, useWatch } from "react-hook-form";
import useLabels from "../../hooks/useLabels";
import {
  DatePicker,
  Input,
  MultipleSelect,
  Select,
  Submit,
  TextArea,
} from "../FormComponents";
import { addMonths } from "date-fns";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getOperations, newOperation, updateOperation } from "../../services/utils";
import { add, empty, setOperations, update } from "../../context/operations/operationsSlice";
import { useEffect, useMemo, useState } from "react";
import Table from "../Table";
import { ConfirmationModal, OperationRecap, TankIcon } from "../Generic";
import { ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX, INT_REGEX } from "../../constants/regex";
import { toast } from "react-toastify";
import { selectOperations } from "../../context/selectors";

const TanksRow = ({
  field,
  srcBatchFieldArray,
  idx,
  src_lots,
  lots,
  tanks,
  control,
  errors,
  srcTankEnabledFieldArray,
  setSrcTankEnabledFieldArray,
  columns,
  toggleSetStatusAll,
}) => {
  const [getLabel] = useLabels();
  const [key, setKey] = useState(field.id);

  const renderTable = (lot_id, filteredTanks) => {
    if (filteredTanks?.length > 0) {
      return (
        <>
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleSetStatusAll(lot_id, true);
              setKey((prevState) => {
                return { key: prevState.key + 1 };
              });
            }}
            type="button"
            id={"selectAll-" + lot_id}
          >
            <span className="sub"></span>
            {getLabel("selectAll")}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleSetStatusAll(lot_id, false);
              setKey((prevState) => {
                return { key: prevState.key + 1 };
              });
            }}
            type="button"
            id={"unselectAll-" + lot_id}
          >
            <span className="sub"></span>
            {getLabel("unselectAll")}
          </button>
          <div className="tanks_list_table">
            <Table
              data={filteredTanks}
              prefix="analysisForm"
              customColumns={columns}
              isSearchBar={false}
            />
          </div>
        </>
      );
    }
  };

  return (
    <div key={field.id} className="operation_form_wrapper_row">
      <Select
        label={getLabel("operationLotName")}
        placeholder={getLabel("operationLotNamePlaceholder")}
        error={errors[`src_lots.${idx}`]}
        required={getLabel("inputRequiredError")}
        name={`src_lots.${idx}.lotData`}
        control={control}
        options={lots
          .filter(
            (lot) =>
              !srcBatchFieldArray?.fields?.some?.(
                (field) => field.lotData?.value == lot.id
              )
          )
          ?.map?.((lot) => {
            return {
              value: lot?.id,
              label:
                lot?.name +
                (lot?.state === "GRAPE"
                  ? ` (${getLabel("lotState_GRAPE")})`
                  : ""),
              isTank: tanks.filter((t) => t.batch_id == lot.id)?.length > 0,
            };
          })}
      />
      {src_lots[idx]?.lotData?.value &&
        renderTable(
          src_lots[idx]?.lotData.value,
          tanks
            .filter((t) => t.batch_id === src_lots[idx]?.lotData.value)
            ?.map((t) => ({
              id: t.id,
              name: t.name,
              quantity: t.quantity,
              batch_id: t.batch_id,
              idx: idx,
              enabled: srcTankEnabledFieldArray.includes(t.id) || false,
            }))
        )}
      {srcBatchFieldArray?.fields?.length > 1 && (
        <button
          onClick={(e) => {
            e.preventDefault();
            const tmpArray = srcTankEnabledFieldArray.filter(
              (el) =>
                !lots
                  .find(
                    (l) =>
                      l.id == srcBatchFieldArray?.fields[idx]?.lotData?.value
                  )
                  ?.tanks?.map((t) => t.tank_id)
                  .includes(el)
            );
            setSrcTankEnabledFieldArray(tmpArray);
            srcBatchFieldArray?.remove(idx);
          }}
        >
          -
        </button>
      )}
    </div>
  );
};

const BottlingForm = ({
  type,
  operation,
  protocols,
  activeWinery,
  tanks,
  users,
  configs,
  lots,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const operations = useSelector(selectOperations);
  const [isOpen, setIsOpen] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  const [recursiveDates, setRecursiveDates] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const [srcTankEnabledFieldArray, setSrcTankEnabledFieldArray] = useState([]);

  useEffect(() => {
    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      const srcs = [];

      const isOnTank = operation?.src_tanks?.[0]?.on_tank === true;
      setValue('bottlingType', {
        label: getLabel(isOnTank ? "tank" : "lot"),
        value: isOnTank ? "tank" : "lot"
      });

      operation?.src_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === Number(s.tank_id));
        srcs.push({
          quantity: s?.quantity,
          tankData: {
            value: s?.tank_id,
            label: tank?.name,
            availability: Number(tank?.quantity),
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            color: tank?.color,
            lot: tank?.batch_name ||
              getLabel("tankState_EMPTY"),  
            name: tank?.name + ` (${tank?.batch_name})`,
          },
        });
      });
      srcTankFieldArray.replace(srcs);

      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      setValue('expire_date', new Date(operation?.expire_date));
      
      setValue('note', operation?.note);
      // setValue('bottlingType', {
      //   value: operation?.subtype,
      //   label: getLabel(operation?.subtype)
      // });
      setIsExactDate(operation?.is_exact_date);
      }
  }, [operation, tanks]);

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };
  
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    reset,
    setError,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      priority: operation?.priority || "",
      // protocolFrequency: "",
      expire_date: operation?.expire_date || null,
      type: "BOTTLING",
      src_lots: operation?.src_lots || [
        { lotData: { value: null, label: null } },
      ],
      src_tanks: operation?.src_tanks || [
        { tankData: { value: null, label: null } },
      ],
      cellarman_ids: operation?.cellarman_ids || "",

    },
    mode: "onTouched",
  });
  
  const srcBatchFieldArray = useFieldArray({
    control,
    name: "src_lots",
  });

  const srcTankFieldArray = useFieldArray({
    control,
    name: "src_tanks",
  });

  const { src_lots, bottlingType, src_tanks } = useWatch({ control });

  const columns = useMemo(() => [
    {
      Header: getLabel("tankName"),
      accessor: "name",
    },
    {
      Header: getLabel("quantity") + ' (L)',
      accessor: "quantity",
    },
    {
      Header: getLabel("tankSelected"),
      accessor: (row) => (
        <Input
          name={`src_lots.${row.idx}.lotData.${row.id}`}
          register={register}
          label={getLabel(`operationSrcTankQuantity`)}
          placeholder={getLabel("operationSrcTanksQuantityPlaceholder")}
          error={errors[`src_tanks.${row.id}`]}
          //   required={getLabel("operationSrcTankQuantityRequiredError")}
          type={"checkbox"}
          isLabel={false}
          checked={row?.enabled}
          onChange={(e) => toggleTankEnabled([row.id], e.target.checked)}
        />
      ),
    },
  ]);

  const toggleTankEnabled = (tank_ids, checked) => {
    let tmpArray = srcTankEnabledFieldArray;
    tank_ids.forEach((tank_id) => {
      const found = tmpArray.includes(tank_id);
      if (!found && checked) {
        tmpArray.push(tank_id);
      } else if (found && !checked) {
        const foundIndex = tmpArray.indexOf(tank_id);
        if (foundIndex > -1) tmpArray = tmpArray.filter((t) => t != tank_id);
      }
    });

    setSrcTankEnabledFieldArray(tmpArray);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const customIdMissingDests = "custom-id-missing-dests";
  const customIdInvalidLotName =  "custom-id-invalid-lot-name";
  const customIdInvalidQuantities = "custom-id-invalid-quantities";
  // const customIdInvalidBatchTypes = "custom-id-invalid-batch-types";
  // const customId = "custom-id-different-color";

  
  const openFormModal = () => {
    // trigger();
    
    if (src_tanks?.filter((t) => t?.tankData?.value)?.length === 0) {
      toast(
        getLabel("toast_destemmingMissingDests"),
        {
          toastId: customIdMissingDests,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    
    if (src_tanks?.filter((t) => (!t?.batch_name_selector?.value && t?.batch_name_selector?.label?.length === 0) ||
     (t?.batch_name_selector?.value === true && t?.new_batch_name?.length === 0))?.length > 0) {
      toast(
        getLabel("toast_destemmingMissingLotSelectors"),
        {
          toastId: customIdMissingDests,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    toast.dismiss(customIdInvalidQuantities);
    setIsOpen(true);
  };

  const sendData = async () => {
    const data = getValues();
    const dests = [];
    const newBatchIds = [];
    try {
      src_tanks
        ?.filter(
          (d) =>
            d?.tankData?.value &&
            d?.quantity &&
            ((d?.batch_name_selector?.value === true && d?.new_batch_name) ||
              (d?.batch_name_selector?.value === false &&
                d?.batch_name_selector?.label))
        )
        ?.forEach((srcT, index) => {
          const bType = 
            (srcT?.batch_name_selector?.value && lots?.find((l) => l?.name === srcT?.batch_name_selector?.label) === undefined && !newBatchIds.includes(srcT?.new_batch_name)) ? 'NEW' :
            (!srcT?.batch_name_selector?.value && lots?.find((l) => l?.name === srcT?.batch_name_selector?.label) === undefined && newBatchIds.includes(srcT?.batch_name_selector?.label)) ? 'INHERITED' :
            (!srcT?.batch_name_selector?.value && lots?.find((l) => l?.name === srcT?.batch_name_selector?.label) !== undefined) ? 'OLD' : '';
          if (
            bType === "NEW" && !(srcT?.new_batch_name in newBatchIds)
          )
            newBatchIds.push(srcT?.new_batch_name);
          else if (!bType)
            throw new Error();
          console.log(srcT)
          const dest = {
            tank_id: srcT?.tankData?.value,
            quantity: Number(srcT?.quantity),
            new_batch_name: (srcT?.batch_name_selector?.value === true
              ? srcT?.new_batch_name
              : srcT?.batch_name_selector?.label
            )?.trim(),
            new_batch_type: bType,
            // old_batch_id:
            //   tanks?.find((t) => t.id === srcT?.tankData?.value)?.batch_id || "",
            // old_quantity:
            //   Number(srcT?.tankData?.quantity),
            // new_quantity: 0,
            // new_quantity:
            //   (Number(
            //     tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity || 0
            //   ) || 0) + (Number(destT.quantity) || 0),
          };
          
          dests.push(dest);
        });
      } catch (err) {
        toast.error(getLabel("toast_invalidDestTanks"), {
          toastId: customIdInvalidLotName,
          position: toast.POSITION.BOTTOM_RIGHT,
          exclude: true,
          autoClose: 5000,
        });  
        return;
      }
    console.log(dests, dests?.filter((d) => d?.new_batch_name?.length > 0))
    if (dests?.filter((d) => d?.new_batch_name?.length > 0)?.length !== src_tanks?.length) {
      toast.error(getLabel("toast_missingLotNames"), {
        toastId: customIdInvalidLotName,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
        autoClose: 5000,
      });
      return;
    }
    let isOnlyOneType = true;
    const isTank = data?.src_lots[0]?.lotData?.isTank; // isTank must be false/true for all the elements
    let src_tank_ids = [];
    
    if (new Date(data?.expire_date?.setHours(23, 59, 59)) < new Date()) {
      setError('expire_date', {
        type: "manual",
        message: getLabel("errorDateInThePast"),
      });
      return;
    }
        
    if (bottlingType.value === "lot" && (!Array.isArray(data?.src_lots) || data?.src_lots?.length === 0 || !data?.src_lots?.every(lot => lot?.lotData?.value && lot?.lotData?.label))) {
      toast(
        getLabel("toast_destemmingMissingDestsLots"),
        {
          toastId: customIdMissingDests,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    
    if (bottlingType.value === "lot") {
      for (const [s, ss] of Object.entries(data?.src_lots)) {
        if (isTank === null || isTank !== ss.lotData.isTank) {
          isOnlyOneType = false;
          break;
        }
        
        for (const [tank_id, selected] of Object.entries(ss.lotData)) {
          if (selected === true && Number.isInteger(Number(tank_id)) && isTank)
            src_tank_ids.push({ tank_id: tank_id, quantity: 0 }); // TO DO fix quantity: 0
        }
        if (!isTank) src_tank_ids.push({ batch_id: ss.lotData.value });
      }

      srcTankEnabledFieldArray?.forEach((tank_id) => {
        if (!src_tank_ids?.find((t) => t.tank_id == tank_id)) {
          src_tank_ids.push({ tank_id });
        }
      });

      if (!isOnlyOneType) {
        console.log("no");
        return;
      }
    } else {
      src_tank_ids = src_tank_ids.concat(
        data?.src_tanks?.map((s) => {
          return { tank_id: s.tankData.value, quantity: Number(s.quantity) };
        })
      );
    }

    if (bottlingType.value === "lot" && (src_tank_ids?.length === 0 || !src_tank_ids?.every(tank => tank?.tank_id))) {
      toast(
        getLabel("toast_destemmingMissingDestsLots"),
        {
          toastId: customIdMissingDests,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    for (let i = 0; i < src_tank_ids?.length; i++) {
      const t = tanks?.find((tt) => tt?.id == src_tank_ids?.[i]?.tank_id);
      if (Number(t?.quantity) <= 0 && data?.bottlingType?.value === 'tank') {
        setError(`src_tanks.${i}.tankData`, {
          type: "manual",
          message: getLabel("error0QuantityNewModify"),
        });
        return;
      }
    }
    
    const requestPayload = {
      src_tanks: src_tank_ids,
      dest_tanks: dests,
      priority: data?.priority?.value,
      is_exact_date: isExactDate,
      expire_date: new Date(data?.expire_date?.setHours(23, 59, 59)).toISOString(),
      type: type,
      bottleLot: data?.bottleLot,
      capLot: data?.capLot,
      subtype: data?.bottlingType?.value || 'tank',
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      note: data?.note,
    };
    console.log('requestPayload', requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      dispatch(empty());
      // navClickHandler();
    }
  };

  const computeLotNames = () => {

    const names = new Set();
    src_tanks?.reduce((_, item) => names.add(item?.tankData?.lot), null);
    
    // shows only 6 months old wine batches. TO DO: what if a batch of the previous year has the same name?? I hope it is deactivated
    lots?.filter((l) => (l?.state === 'WINE') && (new Date(l?.insertion_date) >= addMonths(new Date(), -6)))?.reduce((_, l) => names.add(l?.name), null);
    
    src_tanks?.filter((tank) => tank?.tankData?.quantity > 0)?.reduce((_, item) => names.add(item?.tankData?.lot), null);
    
    const nameObjects = [];
    names?.forEach((s) => {
      if (s) nameObjects.push({ value: false, label: s });
    });
    
    return nameObjects?.sort((a, b) => (a?.label > b?.label) ? 1 : ((b?.label > a?.label) ? -1 : 0));
  };

  const updateStore = (payload) => {
    if (payload.id) {
      dispatch(update(payload));
    } else {
      dispatch(add(payload));
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/${type}/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const toggleSetStatusAll = (lot_id, enableStatus = false) => {
    const tank_ids =
      tanks.filter((t) => t.batch_id === lot_id)?.map((t) => t.id) || [];
    toggleTankEnabled(tank_ids, enableStatus);
  };

  const getError = (idx, data, key) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  return (
    <div className="operation_form_wrapper">
      <form
        onSubmit={handleSubmit(openFormModal)}
        autoComplete="off"
        noValidate
      >
        <div className="operation_form_wrapper_row vertical">
        <Select
              label={getLabel("operationAnalysisTypeName")}
              placeholder={getLabel("operationAnalysisTypeNamePlaceholder")}
              error={errors}
              
              name="bottlingType"
              control={control}
              required={getLabel("inputRequiredError")}
              options={[
                {
                  label: getLabel("lot"),
                  value: "lot",
                },
                {
                  label: getLabel("tank"),
                  value: "tank",
                },
              ]}
              tooltip={getLabel("analysisSourceTypeTooltip")}
            />
          {bottlingType?.value === "lot" && (
            <>
              {srcBatchFieldArray?.fields?.map?.((field, idx) => {
                return (
                  <TanksRow
                    field={field}
                    idx={idx}
                    src_lots={src_lots}
                    srcTankEnabledFieldArray={srcTankEnabledFieldArray}
                    setSrcTankEnabledFieldArray={setSrcTankEnabledFieldArray}
                    srcBatchFieldArray={srcBatchFieldArray}
                    tanks={tanks}
                    lots={lots}
                    errors={errors}
                    control={control}
                    columns={columns}
                    toggleSetStatusAll={toggleSetStatusAll}
                  />
                );
              })}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  srcBatchFieldArray?.append({
                    lotData: {
                      value: null,
                      label: null,
                    },
                  });
                }}
              >
                +
              </button>
            </>
          )}
        </div>
        {bottlingType?.value === "tank" && (
          <>
            <div className="operation_form_wrapper_row vertical">
              {srcTankFieldArray?.fields?.map?.((field, idx) => {
                return (
                  <div key={field.id} className="operation_form_wrapper_row">
                    <Select
                      label={getLabel("operationSrcTankName")}
                      placeholder={getLabel("operationSrcTanksPlaceholder")}
                      error={errors}
                      required={getLabel("inputRequiredError")}
                      name={`src_tanks.${idx}.tankData`}
                      control={control}
                      options={tanks
                        .filter(
                          (tank) =>
                            tank?.quantity > 0 &&
                            !srcTankFieldArray?.fields?.some(
                              (field) => field.tankData.value == tank?.id
                            )
                        )
                        ?.map((tank) => {
                          return {
                            ...tank,
                            value: tank?.id,
                            label: tank.name,
                            name:
                              tank?.name +
                              ` (${
                                lots?.find((lot) => lot?.id === tank?.batch_id)
                                  ?.name || getLabel("tankState_EMPTY")
                              })`,
                            quantity: tank.quantity,
                            capacity: tank.capacity,
                            color: tank.color,
                            lot: tank.batch_name,
                            lot_id: tank?.batch_id || "",
                          };
                        })}
                      tooltip={
                        idx === 0 ? getLabel("analysisSourceTankTooltip") : ""
                      }
                    />
                    {getValues(`src_tanks.${idx}.tankData`)?.value && (
                      <>
                      <TankIcon
                        tank={getValues(`src_tanks.${idx}.tankData`)}
                        isText={true}
                        total={null}
                        isSrc={false}
                      />
                      <Input
                        name={`src_tanks.${idx}.quantity`}
                        register={register}
                        type="number"
                        label={getLabel(`operationDestTankQuantity`)}
                        placeholder={getLabel(
                          "operationDestTanksQuantityPlaceholder"
                        )}
                        required={getLabel("inputRequiredError")}
                        max={{
                          value:
                            Math.min(
                              src_tanks?.[idx]?.tankData?.quantity || 0
                            ),
                          message: getLabel("errorMaxValue", {
                            value: Math.min(
                              src_tanks?.[idx]?.tankData?.quantity || 0
                            ),
                          }),
                        }}
                        min={{
                          value: 1,
                          message: getLabel("errorMinValue", { value: 1 }),
                        }}
                        regExpPattern={{
                          value: INT_REGEX,
                          message: getLabel("errorInt"),
                        }}
                        error={getError(idx, errors?.src_tanks, 'quantity')}
                      />
                    </>
                    )}
                    {getValues(`src_tanks.${idx}.tankData`)?.value && (
                      <div className="batch_name">
                        <Select
                          control={control}
                          defaultValue={{
                            value: "",
                            label: "",
                          }}
                          name={`src_tanks.${idx}.batch_name_selector`}
                          label={getLabel(
                            "operationNewLotName"
                          )}
                          placeholder={getLabel(
                            "operationNewLotNamePlaceholder"
                          )}
                          error={errors}
                          required={getLabel("inputRequiredError")}
                          options={[
                            {
                              value: true,
                              label: getLabel("lotNewName"),
                            },
                            ...computeLotNames(),
                          ]}
                          tooltip={idx === 0 ? getLabel('destemmingTankDestBatchNameSelectorTooltip') : ""}
                        />
                        {getValues(`src_tanks.${idx}.batch_name_selector`)?.value && (
                          <Input
                            name={`src_tanks.${idx}.new_batch_name`}
                            error={getError(idx, errors?.src_tanks, 'new_batch_name')}
                            // name="new_batch_name"
                            register={register}
                            type="text"
                            label={getLabel("operationNewBatchName")}
                            placeholder={getLabel(
                              "operationNewBatchNamePlaceholder"
                            )}
                            // error={errors["new_batch_name"]}
                            required={getLabel("inputRequiredError")}
                            isReverse={true}
                            minLength={{
                              value: 3,
                              message: getLabel("errorMinLength", { value: 3 }),
                            }}
                            maxLength={{
                              value: 24,
                              message: getLabel("errorMaxLength", {
                                value: 24,
                              }),
                            }}
                            regExpPattern={{
                              value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                              message: getLabel("errorAlphaNumSpace_-"),
                            }}
                            validate={(value) => {
                              if (
                                getValues(`src_tanks.${idx}.batch_name_selector`)?.value &&
                                lots?.map((l) => l?.name)?.includes(value?.trim())
                              ) {
                                // toast.error(
                                //   getLabel("toast_lotNameAlreadyPresent"),
                                //   {
                                //     toastId: customIdInvalidLotName,
                                //     position: toast.POSITION.BOTTOM_RIGHT,
                                //     exclude: true,
                                //     autoClose: 5000,
                                //   }
                                // );
                                // return false;
                                return getLabel("errorNewBatchNameAlreadyTaken",
                                    {
                                      name: value,
                                    }
                                  );
                              }
                              // else {
                                // toast.dismiss(customIdInvalidLotName);
                                // return true;
                              // }
                            }}
                          />
                        )}
                      </div>
                    )}
                    {srcTankFieldArray?.fields?.length > 1 && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          srcTankFieldArray?.remove(idx);
                        }}
                      >
                        -
                      </button>
                    )}
                  </div>
                );
              })}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  srcTankFieldArray?.append({
                    tankData: {},
                  });
                }}
              >
                +
              </button>
            </div>
          </>
        )}
        <div className="operation_form_wrapper_row">
            <Input
              name={"bottleLot"}
              register={register}
              label={getLabel('bottleLot')}
              placeholder={getLabel(
                "bottleLotPlaceholder"
              )}
              error={errors["bottleLot"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", { value: 24 }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
              tooltip={getLabel("bottleLotTooltip")}
            />
            <Input
              name={"capLot"}
              register={register}
              label={getLabel('capLot')}
              placeholder={getLabel(
                "capLotPlaceholder"
              )}
              error={errors["capLot"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", { value: 24 }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
              tooltip={getLabel("capLotTooltip")}
            />
        </div>
        <div className="operation_form_wrapper_row">
          <MultipleSelect
              name={`cellarman_ids`}
              control={control}
              label={getLabel("operationUser")}
              placeholder={getLabel("operationUserPlaceholder")}
              error={errors["cellarman_ids"]}
              required={getLabel("inputRequiredError")}
              options={users?.map?.((user) => {
                return {
                  value: user?.id,
                  label: user?.username,
                };
              })}
              tooltip={getLabel("destemmingCellarmenTooltip")}
            />
          <Select
              name="priority"
              control={control}
              label={getLabel("operationPriority")}
              placeholder={getLabel("operationPriorityPlaceholder")}
              error={errors}
              required={getLabel("inputRequiredError")}
              options={[
                {
                  label: getLabel("lotQuality_HIGH"),
                  value: "2",
                },
                {
                  label: getLabel("lotQuality_MEDIUM"),
                  value: "1",
                },
                {
                  label: getLabel("lotQuality_LOW"),
                  value: "0",
                },
              ]}
              // defaultValue={{
              //     value: "",
              //     label: "",
              //   }}
            />
            <DatePicker
              name="expire_date"
              control={control}
              label={getLabel("operationExpireDateForm")}
              error={errors["expire_date"]}
              required={getLabel("inputRequiredError")}
              dateFormat={configs.shortDateFormat}
              placeholder={getLabel("expiringDatePlaceholder")}
            />
            <Input
              name={'expire_date_exact'}
              register={register}
              label={getLabel("isExactDate")}
              error={errors["expire_date_exact"]}
              // isLabel={false}
              type={"checkbox"}
              checked={isExactDate}
              onChange={() => setIsExactDate(prev => !prev)}
              customClassName={'confirm'}
              tooltip={getLabel("destemmingExactDate")}
            />
        </div>
        <div className="client_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div>
        <div className="operation_form_wrapper_row">
          <Submit label={getLabel("submitForm")}></Submit>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={sendData}
        onClose={handleCloseModal}
        description={getLabel("modalNewBottlingDescription")}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
        // configs={configs}
      >
        <OperationRecap
          type={"BOTTLING"}
          control={control}
          register={register}
          getValues={getValues}
          errors={errors}
          configs={configs}
          recursiveDates={recursiveDates}
          setRecursiveDates={setRecursiveDates}
          src_tanks={src_tanks?.map((s) => { return {...s, capLot: getValues('capLot'), bottleLot: getValues('bottleLot'), color: s?.tankData?.color}})}
        />
      </ConfirmationModal>
    </div>
  );
};
export default BottlingForm;
