import { useEffect, useState } from "react";
import useLabels from "../../hooks/useLabels";

const MultipleRowsCell = ({ getValue, row, column, table }) => {
  const columnMeta = column.columnDef.meta;
  const selector = columnMeta?.selector || "";
  const [data, setData] = useState([]);
  const [getLabel] = useLabels();

  const getRightUnit = (isName = false) => {
    const unit_id = row.original?.unit_id || null;
    if (!row.original?.ingredients || !unit_id) return [];

    switch(unit_id) {
      case 1:
      case 2:
      case 4:
      case 6:
      case 7:
        // Kg
        if (isName) return "Kg";
        return 4;
      case 3:
        // L
        if (isName) return "L";
        return 3;
      case 5:
        // unit
        if (isName) return "Unit";
        return 5;
    }

    return 5;
  }

  useEffect(() => {
    let tmp = [];

    if (selector) {
      switch (selector) {
        case "batch_unitary_cost":
        case "batch_number":
        case "batch_quantity":
        case "expire_date":
          const stockArray = row?.original?.[columnMeta?.array] || [];
          if (selector === "batch_number") {
            stockArray?.forEach(s => 
              // tmp.push({ batch_number: s?.batch_number, additional: `(x${s?.stock || '0'})` }));
              tmp.push({ batch_number: s?.batch_number }));
          } else if (selector === "batch_unitary_cost") {
            const perUnit = row?.original?.unit_id ? getLabel(`drugBatchUnit_${getRightUnit()}`) : '';
            stockArray?.forEach(s => 
              tmp.push({ batch_unitary_cost: `${s?.cost || '-'} € ${perUnit}`})
            );
          } else if (selector === "batch_quantity") {
            const perUnit = row?.original?.unit_id ? getLabel(`drugBatchUnit_${getRightUnit(true)}`) : '';
            stockArray?.forEach(s => 
              tmp.push({ batch_quantity: s?.stock ? `${s?.stock} ${perUnit}` : ''})
            );
          
          }
          else
            tmp.push(...stockArray);
          break;
        case "ingredients":
          const ingredientsArray = row?.original?.[columnMeta?.array];
          if (ingredientsArray && ingredientsArray?.length > 0) {
            const sortedIngrByQuantity = [...ingredientsArray]?.sort((a, b) => b.quantity - a.quantity);
            sortedIngrByQuantity?.forEach(i => {
              const ingredients = i?.name || "";
              const ingredientQuantity = `(${i.quantity}%)`
              tmp.push({ ingredients: ingredients, additional: ingredientQuantity });
            });
          }
          break;
        case "ranges":
          const rangesArray = row?.original?.[columnMeta?.array];
          if (rangesArray && rangesArray?.length > 0) {
            rangesArray?.forEach(r => {
              // if just one color, no need to explict the corresponding ranges 
              const colors = r?.colors?.length > 1 ? r?.colors?.reduce((accumulator, current) => {
                return accumulator + (accumulator ? ', ' : '') + getLabel(`lotColor_${current}`);
              }, '') : null;
              const colorsString = colors ? `[${colors}]` : '';
              tmp.push({
                ranges: `${r?.min_range ? String(r?.min_range) : 'n/a'} - ${r?.max_range ? String(r?.max_range) : 'n/a'} ${r?.unit || ""}`,
                additional: `${colorsString}`
              });
            });
          }
          break;
        case "states":
          const statesArray = row?.original?.[columnMeta?.array];
          if (statesArray && statesArray?.length > 0) {
            statesArray?.forEach(row => {
              const states = row?.states?.reduce((accumulator, current) => {
                return accumulator + (accumulator ? ', ' : '') + getLabel(`lotState_${current}`);
              }, '');
              tmp.push({
                states: '',
                additional: states
              });
            });
          }
          break;
        case "client_names":
          const clientNamesArray = row?.original?.[columnMeta?.array];
          if (clientNamesArray) {
            tmp = clientNamesArray?.map((v) => {
              return {
                [selector]: v,
              };
            }); 
            break;
          }
          break;
        case "unit":
        case "substance_name":
        case "quantity_unit":
          let array = row?.original[columnMeta?.array];
          if (selector === 'substance_name' && columnMeta?.array === 'analysis') {
            array = array?.map((t) => { return {...t, substance_name: getLabel("substance_" + t?.substance_name) }});
          }
          else if (selector === 'quantity_unit') {
            array = array?.map((t) => { return {...t, quantity_unit: selector === "quantity_unit" ? `${t?.quantity} ${t?.unit || ""}`: ""}});
          }
          
          if (array) {
            tmp = array;
            break;
          }
        case "usernames":
          const usernameArray = row?.original[columnMeta?.array];
          if (usernameArray) {
            tmp = usernameArray;
            break;
          }
        case "varieties":
        case "origins":
        case "years":
          const varArray = row?.original[columnMeta?.array];
          if (varArray) {
            tmp = varArray?.map((v) => {
              return {
                [selector]: v,
              };
            });
            break;
          }
        case "aggregate":
          const additional = [];
          const lot = row?.original;
          for (let i = 0; i < lot?.varieties?.length; i++) {
            const v = lot?.varieties?.[i] ? getLabel(`lotVarieties_${lot?.varieties?.[i]}`) : '';
            const y = lot?.years?.[i] ? ` (${lot?.years?.[i]})` : '';
            const o = lot?.origins?.[i] ? `, ${lot?.origins?.[i]}` : '';
            const p = lot?.percentages?.length > 1 ? ` (${lot?.percentages?.[i]}%)` : "";
            additional.push(`${v} ${y}${o} ${p}`);
          }
          if (additional) {
            tmp = additional?.map((v) => {
              return {
                [selector]: v,
              };
            });
            break;
          }
        case "tank_name":
          const tanks = row?.original[columnMeta?.array] || [];
          if (tanks) {
            tmp = tanks?.map((v) => {
              return {
                [selector]: v?.[selector],
                additional: v?.additional
              };
            });
            break;
          }
        default:
          break;
      }
    }

    setData([...tmp]);
  }, []);

  const getValueByParam = (r) => {
    if (
      !r ||
      Object.keys(r)?.length === 0 ||
      !selector ||
      !r.hasOwnProperty(selector)
    )
      return <span></span>;
    return <span>{r[selector] || ""} {r?.additional && <span className="additional_span">
      {r?.[selector] ? (
          <>
            &nbsp;&nbsp;
          </>
        ) : null}
      {r?.additional}</span>}</span>;
  };

  return (
    <div>
      {data?.map?.((r, index) => {
        return (
          <div
            key={`multiple_row_${r?.index || r?.substance_id || r[selector] || index}_${Math.floor(Math.random() * 10000)}`}
          >
            {getValueByParam(r)}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleRowsCell;
