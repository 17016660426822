import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { Button, RecapTankIcon } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveWinery,
  selectConfigs,
  selectLots,
  selectTanks,
  selectUser,
  selectUsers,
  selectWineLabels,
  selectWineVarieties,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  getLots,
  getTanks,
  getUsers,
  getWineLabels,
  getWineVarieties,
  newLot,
  updateLot,
} from "../../services/utils";
import { setLots, add, emptyLots } from "../../context/lots/lotsSlice";
import {
  DatePicker,
  Input,
  Label,
  MultipleSelect,
  Select,
  Submit,
  TextArea,
} from "../FormComponents";
import NewLotCreationForm from "../OperationsForm/NewLotCreationForm";
import { setTanks } from "../../context/tanks/tanksSlice";
import { setWineVarieties } from "../../context/wineVarieties/wineVarietiesSlice";
import { setWineLabels } from "../../context/wineLabels/wineLabelsSlice";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  ALPHA_SPACE_APEX_DOT_REGEX,
  INT_REGEX,
  NUM_ALPHA_SPACE_APEX_DOT_REGEX,
  YEAR_REGEX,
} from "../../constants/regex";
import { toast } from "react-toastify";
import { setUsers } from "../../context/users/usersSlice";

import bio from "../../assets/icons/bio.png";
import vegan from "../../assets/icons/vegan.png";
import "./styles/index.scss";


const LotForm = ({isOnlyForm = false, operation = null}) => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams(); 
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const lots = useSelector(selectLots);
  const tanks = useSelector(selectTanks);
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const configs = useSelector(selectConfigs);
  const wineLabels = useSelector(selectWineLabels);
  const wineVarieties = useSelector(selectWineVarieties);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [lot, setLot] = useState(
    lots?.length > 0 ? lots.find((dbLot) => dbLot.id === Number(id)) || {} : {}
  );
  const [doco, setDoco] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tankList, setTankList] = useState([]);
  const [lotLabelOpen, setLotLabelOpen] = useState(false);

  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      name: lot?.name || "",
      label:
        lot?.label?.map((l) => {
          return {
            value: l || "",
            label: getLabel(`lotLabel_${l}` || ""),
          };
        }) || [],
      varieties: lot?.varieties?.map((v) => {
        return {
          value: v || "",
          label: getLabel(`lotVarieties_${v}` || ""),
        };
      }) || [
        {
          label: "",
          value: "",
        },
      ],
      years: lot?.years || "",
      origins: lot?.origins || "",
      quantity: lot?.actual_quantity || "",
      // quality: {
      //   value: lot?.quality || "MEDIUM",
      //   label: getLabel(`lotQuality_${lot?.quality || "MEDIUM"}`),
      // },
      // state: {
      //   value: lot?.state || "GRAPE",
      //   label: getLabel(`lotState_${lot?.state || "GRAPE"}`),
      // },
      type: lot?.type || "",
      // color: {
      //   value: lot?.color || "RED",
      //   label: getLabel(`lotColor_${lot?.color || "RED"}`),
      // },
      tanks: lot?.tanks || 0,
    },
    mode: "onTouched",
  });

  const { color, label, varieties } = useWatch({ control });

  const {
    fields: varietiesFields,
    append: appendV,
    remove: removeV,
  } = useFieldArray({
    control,
    name: "varieties",
  });

  useEffect(() => {
    if (!lots || lots.length === 0) {
      loadLots();
    }
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }

    if (!users || users.length === 0) {
      loadUsers();
    }

    if (!wineLabels || wineLabels.length === 0) {
      loadWineLabels();
    }
    if (!wineVarieties || wineVarieties.length === 0) {
      loadWineVarieties();
    }
  }, []);

  useEffect(() => {
    const filteredSet = lot?.tanks?.map((tank) =>
      tanks.find((t) => t.id === tank.tank_id)
    );
    setTankList(filteredSet);
  }, [lot]);

  useEffect(() => {
    const singleLot = (id && lots.length > 0 && !isOnlyForm) ?
      (lots.find((dbLot) => dbLot.id === Number(id)) || null) :
      (isOnlyForm && operation !== null) ?
      (operation?.src_tanks?.[0] || null) : null;
    // TO DO ho tolto Object.keys(lot).length === 0 ma non so se funziona ovunque
    // const singleLot = (id && lots.length > 0 && Object.keys(lot).length === 0 && !isOnlyForm) ?
    //   (lots.find((dbLot) => dbLot.id === Number(id)) || null) :
    //   (isOnlyForm && operation !== null) ?
    //   (operation?.src_tanks?.[0] || null) : null;
    
     if (singleLot) {
      setLot(singleLot);
      setValue("quality", {
        value: singleLot?.quality || "LOW",
        label: getLabel(`lotQuality_${singleLot?.quality || "LOW"}`),
      });
      const varietyList = [];
      singleLot?.varieties?.forEach((element, i) => {
        varietyList.push({
          value: element || "",
          label: getLabel(`lotVarieties_${element}` || ""),
          quantity: singleLot?.percentages?.[i],
        });
      });

      setValue("varieties", varietyList);
      if (!isOnlyForm) {
        singleLot?.years?.forEach((element, i) => {
          setValue(`years.${i}`, singleLot?.years[i] || "");
        });
        singleLot?.origins?.forEach((element, i) => {
          setValue(`origins.${i}`, singleLot?.origins[i] || "");
        });
      } else {
        setValue("years", singleLot?.years[0] || "");
        setValue("origins", singleLot?.origins[0] || "");
      }
      setValue("state", {
        value: singleLot?.state,
        label: getLabel(`lotState_${singleLot?.state || "GRAPE"}`),
      });
      setValue("quantity", (isOnlyForm ? singleLot?.quantity : singleLot?.actual_quantity) || 0);
      const labelList = [];
      singleLot?.label?.forEach((element) => {
        labelList.push({
          value: singleLot?.element || "",
          label: getLabel(`lotLabel_${element || ""}`),
        });
      });
      setValue("label", labelList);
      setValue("type", singleLot?.type || "");
      setValue("tanks", singleLot?.tanks || "");
      setValue("name", singleLot?.name || "");
      setValue("color", {
        value: singleLot?.color || "RED",
        label: getLabel(`lotColor_${singleLot?.color || "RED"}`),
      });

      
      setValue("note", (isOnlyForm ? operation?.note : singleLot?.note) || "");
      
      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const userT = (user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users)?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: userT?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);
      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });
      setValue("expire_date", operation?.expire_date);
    }
  }, [lots, operation]);

  useEffect(() => {
    if (label?.length === 0) {
      setLotLabelOpen(false);
      return;
    }
    else setLotLabelOpen(label?.find((l) => l?.value && l?.type === undefined));
  }, [label])
  
  const testLabel = (limits) => {
    let status = "OK";
    if (!label || label?.length === 0) {
      return status;
    }
    
    limits?.forEach((limit) => {
      switch (limit?.type) {
        case "RANGE":
          varieties?.forEach((variety) => {
            if (!variety?.quantity || !limit?.wines?.includes(variety?.label))
              return true;
            const quantity = Number(variety?.quantity) || 0;
            if (limit?.min && quantity < limit?.min * 100)
              status = getLabel("toast_lotVarietiesStatus_MIN", {
                variety: variety?.label,
                quantity: quantity,
                value: limit?.min * 100,
              });
            else if (limit?.max && quantity > limit?.max * 100)
              status = getLabel("toast_lotVarietiesStatus_MAX", {
                variety: variety?.label,
                quantity: quantity,
                value: limit?.max * 100,
              });
          });
          break;
        case "SUM":
          const total = varieties
            ?.filter((v) => limit?.wines?.some((l) => v?.label === l))
            ?.reduce((acc, curr) => {
              return acc + (Number(curr?.quantity) || 0);
            }, 0);
          if (limit?.min && total < limit?.min * 100)
            status = getLabel("toast_lotVarietiesStatus_SUM_MIN", {
              varieties: limit?.wines,
              value: limit?.min * 100,
            });
          if (limit?.max && total > limit?.max * 100)
            status = getLabel("toast_lotVarietiesStatus_SUM_MAX", {
              varieties: limit?.wines,
              value: limit?.max * 100,
            });
          break;
        case "COLOR":
          const color = limit?.color;
          if (color in wineVarieties) {
            const varietiesByColor = wineVarieties[color];
            const totalColor = varieties
              ?.filter((v) => varietiesByColor?.some((l) => v?.label === l))
              ?.reduce((acc, curr) => {
                return acc + (Number(curr?.quantity) || 0);
              }, 0);

            if (limit?.min && totalColor < limit?.min * 100)
              status = getLabel("toast_lotVarietiesStatus_COLOR_MIN", {
                color: getLabel(`lotColor_${label?.color}`),
                varieties: limit?.wines,
                value: limit?.min * 100,
              });
            if (limit?.max && totalColor > limit?.max * 100)
              status = getLabel("toast_lotVarietiesStatus_COLOR_MAX", {
                color: getLabel(`lotColor_${label?.color}`),
                varieties: limit?.wines,
                value: limit?.max * 100,
              });
          }
          break;
        default:
          break;
      }
    });
    return status;
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const getTotalQuantity = () => {
    return getValues()?.dest_tanks?.reduce((acc, curr) => {
      return acc + (Number(curr?.quantity) || 0);
    }, 0) || 0;
  };

  const customIdInvalidQuantities = "custom-id-invalid-quantities";
  const openFormModal = () => {
    // console.log('errors', errors)
    trigger();
    if (getValues()?.dest_tanks?.filter((t) => t?.tankData?.value)?.length === 0) {
      toast(
        getLabel("toast_destemmingMissingDests"),
        {
          toastId: customIdMissingDests,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return false;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    
    if (getValues()?.dest_tanks?.filter((t) => (!t?.batch_name_selector?.value && t?.batch_name_selector?.label?.length === 0) ||
     (t?.batch_name_selector?.value === true && t?.new_batch_name?.length === 0))?.length > 0) {
      toast(
        getLabel("toast_destemmingMissingLotSelectors"),
        {
          toastId: customIdMissingDests,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return false;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    
    const creationLiters = getTotalQuantity();
    const totalLitersAvailable = Number(getValues("quantity"));
    if (
      creationLiters === totalLitersAvailable
    ) {
      toast.dismiss(customIdInvalidQuantities);
      setIsOpen(true);
      return true;
    } else {
      toast(
        getLabel("toast_lotCreationInvalidQuantities", {
          sum: creationLiters || '0',
          total: totalLitersAvailable || '0',
          state: getLabel(`lotState_${getValues('state')?.value}`)?.toLowerCase(),
        }),
        {
          toastId: customIdInvalidQuantities,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return false;
    }
  };

  const customId = "custom-id-invalid-label";
  const customIdQuantity = "custom-id-invalid-quantity";
  const customIdPerc = "custom-id-invalid-percentage";
  const customIdMissingDests = "custom-id-missing-dests";

  useEffect(() => {
    if (!label || label?.length === 0) {
      clearErrors("label");
      toast.dismiss(customIdPerc);
      return;
    }
    if (!varieties || varieties?.length === 0) return;
    const wLabel = label?.find((l) => l?.type === undefined);
    const wineLabel =
      wineLabels?.wine?.length > 0
        ? wineLabels?.wine?.find((l) => l.id === wLabel?.value)
        : null;

    let isValid = true;
    const labelStatus = testLabel(wineLabel?.limits || []);

    if (labelStatus !== "OK") {
      toast.warning(labelStatus, {
        toastId: customIdPerc,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
        autoClose: false,
      });
    } else toast.dismiss(customIdPerc);

    // if (testLabel(wineLabel?.limits || [])) {
    //   toast.warning(getLabel("toast_lotVarietiesPercentage"), {
    //     toastId: customIdPerc,
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     exclude: true,
    //     autoClose: false
    //   });
    // } else {
    //   toast.dismiss(customIdPerc);
    // }
    varieties?.forEach((variety) => {
      if (
        !variety?.label ||
        !wineLabel?.limits ||
        wineLabel?.limits?.length === 0
      )
        return false;
      isValid = wineLabel?.limits?.some((wine) =>
        wine?.wines?.includes(variety?.label)
      );
      if (!isValid) {
        toast.warning(getLabel("toast_lotVarietiesNotInLabel"), {
          toastId: customId,
          position: toast.POSITION.BOTTOM_RIGHT,
          exclude: true,
        });
        setError("label", {
          type: "manual",
          message: getLabel("errorWineLabel"),
        });
        return false;
      }
    });

    if (isValid) {
      toast.dismiss(customId);
      clearErrors("label");
    }
  }, [label, varieties]);

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers))
  }

  const loadWineLabels = async () => {
    const currentWineLabels = await getWineLabels(axiosPrivate);
    dispatch(setWineLabels(currentWineLabels));
  };

  const loadWineVarieties = async () => {
    const currentWineVarieties = await getWineVarieties(axiosPrivate);
    dispatch(setWineVarieties(currentWineVarieties));
  };

  const getError = (idx, data) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data[idx]?.quantity || null;
  };

  const submitForm = async (data) => {
    if (Object.keys(errors)?.length > 0) return;
    const newLotCreationStatus = ((!id || (id && isOnlyForm)) && Number(data?.quantity) > 0 && ['WINE', 'MUST'].includes(data?.state?.value));
    if (newLotCreationStatus) {
      openFormModal();
      return;
    }
    
    const total =
      varieties?.length === 1
        ? 100
        : varieties?.reduce((acc, curr) => {
            return acc + (Number(curr?.quantity) || 0);
          }, 0);
    if (total !== 100) {
      varieties?.forEach((v, index) =>
        setError(`varieties.${index}`, {
          type: "manual",
          message: getLabel("errorWinePercentage100"),
        })
      );
    } else {
      varieties?.forEach((v, index) => clearErrors(`varieties.${index}`));
    }
    if (errors?.length > 0) return;

    if (!id && lots?.find((l) => l?.name === data?.name?.trim())) {
      toast.error(getLabel("toast_lotNameNotAvailable"), {
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
      });
      return;
    }
    const percentages = varieties?.length > 1 ? varieties?.map(v => Number(v?.quantity)) : [100];

    const requestPayload = {
      ...data,
      percentages: percentages,
      varieties: data?.varieties?.map((v) => v?.label),
      years: Array.isArray(data?.years) ? data?.years : data?.varieties?.map(() => data?.years),
      origins: Array.isArray(data?.origins) ? data?.origins : data?.varieties?.map(() => data?.origins),
      state: data?.state?.value || "GRAPE",
      type: "",
      unit_id: 4,
      label: data?.label?.map((v) => v?.label),
      color: data?.color?.value || "RED",
      quality: data?.quality?.value || "LOW",
      winery_id: activeWinery.id,
      ...(id && { id: id }),
    };
    console.log('requestPayload', requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;
    if (id) {
      response = await updateLot(id, requestPayload, axiosPrivate);
    } else {
      response = await newLot(requestPayload, axiosPrivate);
    }

    toast.update(toastId, {
      render: response?.success 
        ? getLabel(response?.success, { name: data?.name || "" })
        : getLabel(response?.error),
      type: !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
      updateStore(requestPayload);
      navClickHandler();
    }
  };

  const updateStore = (payload) => {
    if (payload.id) {
      dispatch(emptyLots());
    } else {
      dispatch(add(payload));
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/lots/${id}`);
    } else {
      navigate("/lots");
    }
  };

  const getWineVarietiesOptionsByColor = () => {
    if (!getValues("color")?.value) return [];
    const color = getValues("color")?.value?.toLowerCase();

    return color in wineVarieties
      ? wineVarieties[color]?.filter(
          (v) => !varieties?.some?.((variety) => variety?.value == v)
        )
      : [];
  };

  const getWineLabelsOptions = () => {
    if (!wineLabels || wineLabels?.length === 0) return [];
    if (
      getValues("label") &&
      getValues("label")?.filter((l) => l?.type !== "label")?.length > 0
    )
      return wineLabels?.labels;
    return [...wineLabels?.wine, ...wineLabels?.labels] || [];
  };

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "white";
      case "ROSE":
        return "rose";
      default:
        return "red";
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      if (id) {
        navigate(`/lots/${id}`);
      } else {
        navigate("/lots");
      }
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{getLabel(id ? "lotsUpdatePage" : "lotsNewPage")}</h2>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="lot_form_wrapper">
        <div className="lot_form">
          <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
            <div className="lot_form_wrapper_row">
              <Input
                name="name"
                register={register}
                type="text"
                label={getLabel("lotName")}
                placeholder={getLabel("lotNamePlaceholder")}
                required={getLabel("inputRequiredError")}
                minLength={{
                  value: 3,
                  message: getLabel("errorMinLength", { value: 3 }),
                }}
                maxLength={{
                  value: 24,
                  message: getLabel("errorMaxLength", { value: 24 }),
                }}
                regExpPattern={{
                  value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                  message: getLabel("errorAlphaNumSpace_-"),
                }}
                error={errors["name"]}
                tooltip={getLabel("lotNameTooltip")}
              />
              <MultipleSelect
                name="label"
                control={control}
                label={getLabel("lotLabel")}
                placeholder={getLabel("lotLabelPlaceholder")}
                error={errors["label"]}
                // required={getLabel("inputRequiredError")}
                options={getWineLabelsOptions()?.map((l) => {
                  return { value: l?.id, label: l?.id, type: l?.type };
                })}
                tooltip={getLabel("lotLabelTooltip")}
              />
            </div>
            <div className="lot_form_wrapper_row">
              {(!id || isOnlyForm) && (
                <Select
                  name="color"
                  control={control}
                  label={getLabel("lotColor")}
                  placeholder={getLabel("lotColorPlaceholder")}
                  error={errors["color"]}
                  required={getLabel("inputRequiredError")}
                  isDisabled={id ? true : false}
                  options={[
                    {
                      value: "WHITE",
                      label: getLabel("lotColor_WHITE"),
                    },
                    {
                      value: "RED",
                      label: getLabel("lotColor_RED"),
                    },
                    ...(getValues("state")?.value && getValues("state")?.value !== 'GRAPE' ? [{ value: "ROSE", label: getLabel("lotColor_ROSE") }] : []),

                  ]}
                  // defaultValue={{
                  //   value: "RED",
                  //   label: getLabel("lotColor_RED"),
                  // }}
                  tooltip={getLabel("lotColorTooltip")}
                />
              )}
              <Select
                name="quality"
                control={control}
                label={getLabel("lotQuality")}
                placeholder={getLabel("lotQualityPlaceholder")}
                error={errors["quality"]}
                required={getLabel("inputRequiredError")}
                options={[
                  {
                    value: "LOW",
                    label: getLabel("lotQuality_LOW"),
                  },
                  {
                    value: "MEDIUM",
                    label: getLabel("lotQuality_MEDIUM"),
                  },
                  {
                    value: "HIGH",
                    label: getLabel("lotQuality_HIGH"),
                  },
                ]}
                // defaultValue={{
                //   value: "HIGH",
                //   label: getLabel("lotQuality_HIGH"),
                // }}
                tooltip={getLabel("lotQualityTooltip")}
              />
              {(!id || isOnlyForm) && (
                <Input
                  name="quantity"
                  register={register}
                  isDisabled={(id && !isOnlyForm) ? true : false}
                  type="number"
                  label={getLabel("lotQuantity")}
                  placeholder={getLabel("lotQuantityPlaceholder")}
                  error={errors["quantity"]}
                  required={getLabel("inputRequiredError")}
                  min={{
                    value: 1,
                    message: getLabel("errorMinValue", { value: 1 }),
                  }}
                  max={{
                    value: 50000,
                    message: getLabel("errorMaxValue", { value: 50000 }),
                  }}
                  regExpPattern={{
                    value: INT_REGEX,
                    message: getLabel("errorInt"),
                  }}
                  tooltip={getLabel("lotQuantityTooltip")}
                />
              )}
            </div>
            {(!id || isOnlyForm) && (
              <>
                <div className="lot_form_wrapper_row vertical">
                  {varietiesFields?.map?.((field, idx) => {
                    return (
                      <div key={field.id} className="lot_form_wrapper_row">
                        <Select
                          name={`varieties.${idx}`}
                          control={control}
                          label={getLabel("lotVarieties")}
                          placeholder={getLabel("lotVarietiesPlaceholder")}
                          error={errors["varieties"]}
                          required={getLabel("inputRequiredError")}
                          options={getWineVarietiesOptionsByColor()?.map((v) => {
                            return {
                              value: v,
                              label: getLabel(`lotVarieties_${v}`),
                            };
                          })}
                          isLabel={idx === 0}
                          tooltip={
                            idx === 0 ? getLabel("lotVarietiesTooltip") : ""
                          }
                        />
                        {varietiesFields?.length > 1 &&
                          getValues(`varieties.${idx}`)?.value && (
                            <>
                              <Input
                                name={`varieties.${idx}.quantity`}
                                register={register}
                                type="number"
                                label={getLabel(`lotVarietiesPercentage`)}
                                placeholder={getLabel(
                                  "lotVarietiesPercentagePlaceholder"
                                )}
                                isLabel={idx === 0}
                                error={getError(idx, errors?.varieties)}
                                required={getLabel("inputRequiredError")}
                                min={{
                                  value: 1,
                                  message: getLabel("errorMinValue", {
                                    value: 1,
                                  }),
                                }}
                                max={{
                                  value: 100,
                                  message: getLabel("errorMaxValue", {
                                    value: 100,
                                  }),
                                }}
                                regExpPattern={{
                                  value: INT_REGEX,
                                  message: getLabel("errorInt"),
                                }}
                                // onChange={updateErrors}
                              />
                            </>
                          )}
                        {varietiesFields?.length > 1 && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              removeV(idx);
                            }}
                          >
                            -
                          </button>
                        )}
                      </div>
                    );
                  })}
                  <div className="lot_form_wrapper_row">
                    <p>{getLabel("lotVarietiesAdd")}</p>
                    <button
                      id="varietiesB"
                      onClick={(e) => {
                        e.preventDefault();
                        appendV({
                          label: "",
                          value: "",
                        });
                      }}
                    >
                      +
                    </button>
                  </div>
                  {/* <Input
                  name="varieties"
                  register={register}
                  type="text"
                  label={getLabel("lotVarieties")}
                  placeholder={getLabel("lotVarietiesPlaceholder")}
                  error={errors["varieties"]}
                  regExpPattern={{
                    value: ALPHA_SPACE_APEX_DOT_REGEX,
                    message: getLabel("errorAlphaSpaceApex"),
                  }}
                  minLength={{
                    value: 3,
                    message: getLabel("errorMinLength", { value: 3 }),
                  }}
                  maxLength={{
                    value: 16,
                    message: getLabel("errorMaxLength", { value: 16 }),
                  }}
                  // tooltip={getLabel("lotVarietiesTooltip")}
                /> */}
                </div>
                <div className="lot_form_wrapper_row">
                  <Input
                    name="years"
                    register={register}
                    type="text"
                    label={getLabel("lotYears")}
                    placeholder={getLabel("lotYearsPlaceholder")}
                    error={errors["years"]}
                    required={getLabel("inputRequiredError")}
                    regExpPattern={{
                      value: YEAR_REGEX,
                      message: getLabel("errorYear"),
                    }}
                    minLength={{
                      value: 4,
                      message: getLabel("errorExactLength", { value: 4 }),
                    }}
                    maxLength={{
                      value: 4,
                      message: getLabel("errorExactLength", { value: 4 }),
                    }}
                    // tooltip={getLabel("lotYearsTooltip")}
                  />
                  <Input
                    name="origins"
                    register={register}
                    type="text"
                    label={getLabel("lotOrigins")}
                    required={getLabel("inputRequiredError")}
                    placeholder={getLabel("lotOriginsPlaceholder")}
                    error={errors["origins"]}
                    regExpPattern={{
                      value: NUM_ALPHA_SPACE_APEX_DOT_REGEX,
                      message: getLabel("errorAlphaSpaceApexNum"),
                    }}
                    minLength={{
                      value: 3,
                      message: getLabel("errorMinLength", { value: 3 }),
                    }}
                    maxLength={{
                      value: 32,
                      message: getLabel("errorMaxLength", { value: 32 }),
                    }}
                    // tooltip={getLabel("lotOriginsTooltip")}
                  />
                </div>
              </>
            )}
            <div className="lot_form_wrapper_row">
              {(!id || isOnlyForm) && (
                <Select
                  name="state"
                  control={control}
                  label={getLabel("lotState")}
                  placeholder={getLabel("lotStatePlaceholder")}
                  error={errors["state"]}
                  required={getLabel("inputRequiredError")}
                  options={[
                    {
                      value: "GRAPE",
                      label: getLabel("lotState_GRAPE"),
                    },
                    {
                      value: "MUST",
                      label: getLabel("lotState_MUST"),
                    },
                    {
                      value: "WINE",
                      label: getLabel("lotState_WINE"),
                    },
                  ]}
                  // defaultValue={{
                  //   value: "GRAPE",
                  //   label: getLabel("lotState_GRAPE"),
                  // }}
                  tooltip={getLabel("lotStateTooltip")}
                />
              )}
            </div>
            <div className="lot_form_wrapper_row">
              {(!id || isOnlyForm) && Number(getValues("quantity")) > 0 && ['WINE', 'MUST'].includes(getValues("state")?.value) && (
                <NewLotCreationForm
                  lots={lots}
                  tanks={tanks}
                  activeWinery={activeWinery}
                  state={getValues("state")?.value}
                  register={register}
                  control={control}
                  handleCloseModal={handleCloseModal}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setError={setError}
                  varieties={varieties}
                  wineLabels={wineLabels}
                  trigger={trigger}
                  isOpen={isOpen}
                />
              )}
            </div>
            {(id && !isOnlyForm) && (
              <div className="lot_form_wrapper">
                <div className="lot_form_wrapper_row">
                  <Label label={getLabel("lotVarieties")}>
                    {lot?.varieties?.map((v, index) => {
                      return <p key={`varieties-${index}`}>{v ? getLabel(`lotVarieties_${v}`) + (lot?.percentages?.length > 1 ? ` (${lot?.percentages?.[index]})%` : "") : ""}</p>;
                    }, "") || ""}
                  </Label>
                  <Label label={getLabel("lotYears")}>
                    {lot?.years?.map((y, index) => {
                      return (
                        <p key={`years-${index}`}>{y ? y : index > 0 ? lot?.years[index - 1] : ""}</p>
                      );
                    }, "") || ""}
                  </Label>
                  <Label label={getLabel("lotOrigins")}>
                    {lot?.origins?.map((o, index) => {
                      return <p key={`origins-${index}`}>{o}</p>;
                    }, "") || ""}
                  </Label>
                </div>
                <div className="lot_form_wrapper_row">
                  <Label
                    label={getLabel("lotColor")}
                    // tooltip={getLabel("lotColorTooltip")}
                  >
                    {getLabel(`lotColor_${lot?.color || "RED"}`)}
                  </Label>
                  <Label
                    label={getLabel("lotState")}
                    // tooltip={getLabel("lotColorTooltip")}
                  >
                    {getLabel(`lotState_${lot?.state || ""}`)}
                  </Label>
                  <Label
                    label={getLabel("lotQuantity")}
                    // tooltip={getLabel("lotColorTooltip")}
                  >
                    {lot?.actual_quantity || 0} L
                  </Label>
                </div>

                {/* {lot?.varieties?.map((l, index) => {
                  return (
                    <Select
                      name={`varieties.${index}`}
                      isLabel={index === 0}
                      control={control}
                      label={getLabel("lotVarieties")}
                      placeholder={getLabel("lotVarietiesPlaceholder")}
                      error={errors["varieties"]}
                      required={getLabel("inputRequiredError")}
                      options={getWineVarietiesOptionsByColor()?.map((v) => {
                        return { value: v, label: getLabel(`lotVarieties_${v}`) };
                      })}
                      tooltip={getLabel("lotVarietiesTooltip")}
                    />
                    // <Input
                    //   name={`varieties.${index}`}
                    //   register={register}
                    //   type="text"
                    //   label={getLabel("lotVarieties")}
                    //   isLabel={index === 0}
                    //   placeholder={getLabel("lotVarietiesPlaceholder")}
                    //   error={errors[`varieties`]?.[index]}
                    //   minLength={{
                    //     value: 3,
                    //     message: getLabel("errorMinLength", { value: 3 }),
                    //   }}
                    //   maxLength={{
                    //     value: 16,
                    //     message: getLabel("errorMaxLength", { value: 16 }),
                    //   }}
                    //   regExpPattern={{
                    //     value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                    //     message: getLabel("errorAlphaNumSpace_-"),
                    //   }}
                    //   //   tooltip={index === 0 ? getLabel("lotVarietiesTooltip") : ''}
                    // />
                  );
                })}
                {lot?.years?.map((l, index) => {
                  return (
                    <Input
                      name={`years.${index}`}
                      register={register}
                      type="number"
                      isLabel={index === 0}
                      label={getLabel("lotYears")}
                      placeholder={getLabel("lotYearsPlaceholder")}
                      error={errors[`years`]?.[index]}
                      minLength={{
                        value: 4,
                        message: getLabel("errorExactLength", { value: 4 }),
                      }}
                      maxLength={{
                        value: 4,
                        message: getLabel("errorExactLength", { value: 14 }),
                      }}
                      regExpPattern={{
                        value: YEAR_REGEX,
                        message: getLabel("errorYear"),
                      }}
                      //   tooltip={index === 0 ? getLabel("lotYearsTooltip") : ''}
                    />
                  );
                })}
                {lot?.origins?.map((l, index) => {
                  return (
                    <Input
                      name={`origins.${index}`}
                      register={register}
                      type="text"
                      isLabel={index === 0}
                      label={getLabel("lotOrigins")}
                      placeholder={getLabel("lotOriginsPlaceholder")}
                      error={errors[`origins`]?.[index]}
                      minLength={{
                        value: 3,
                        message: getLabel("errorMinLength", { value: 3 }),
                      }}
                      maxLength={{
                        value: 32,
                        message: getLabel("errorMaxLength", { value: 32 }),
                      }}
                      regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                      }}
                      //   tooltip={index === 0 ? getLabel("lotOriginsTooltip") : ''}
                    />
                  );
                })} */}
              </div>
            )}
            {(id && !isOnlyForm) && (
              <div>
                {lot.state !== "GRAPE" && (
                  <>
                    <h4>{getLabel("lotTanks")}</h4>
                    <div className="lot_info_container_row">
                      {tankList?.map((tank, index) => {
                        return (
                          <div className="tank" key={`tank-detail-${index}`}>
                            <RecapTankIcon
                              tank={tank}
                              wineColor={getWineColor(lot?.color)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
            {true && <div>
            {/* (!id || isOnlyForm) && Number(getValues("quantity")) > 0 && ['WINE', 'MUST'].includes(getValues("state")?.value) && <div className="lot_form_wrapper_row"> */}
            <>
              <h6>{getLabel("lotDoco")}</h6>
              <div className="lot_form_wrapper_row">
              <Input
                name={'doco'}
                register={register}
                label={getLabel('lotDocoCheckbox')}
                type={"checkbox"}
                checked={doco}
                onChange={() => setDoco(prev => !prev)}
              />
              {getValues('doco') && (<>
                <Input
                  name={'doco_id'}
                  label={getLabel("lotDoco")}
                  placeholder={getLabel("lotDocoPlaceholder")}
                  tooltip={getLabel("lotDocoTooltip")}
                  register={register}
                  error={errors['doco_id']}
                  required={getLabel("inputRequiredError")}
                  type="text"
                  minLength={{
                    value: 3,
                    message: getLabel("errorMinLength", { value: 3 }),
                  }}
                  maxLength={{
                    value: 24,
                    message: getLabel("errorMaxLength", { value: 24 }),
                  }}
                  regExpPattern={{
                    value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                    message: getLabel("errorAlphaNumSpace_-"),
                  }}
                />
                <DatePicker
                  name="doco_date"
                  control={control}
                  label={getLabel("lotDocoDate")}
                  error={errors["doco_date"]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.shortDateFormat}
                  placeholder={getLabel("expiringDatePlaceholder")}
                />
              </>)}
              </div>
            </>
            </div>}
            <div className="lot_form_wrapper_row">
            <TextArea
                name="note"
                register={register}
                label={getLabel("lotNotes")}
                placeholder={getLabel("lotNotesPlaceholder")}
                error={errors["note"]}
                tooltip={getLabel("lotNotesTooltip")}
              />
            </div>
            <div className="lot_form_wrapper_row">
              <Submit label={getLabel("submitForm")} />
            </div>
          </form>
        </div>
        <div className={`lot_label_container ${lotLabelOpen ? 'open' : ''}`}>
          <div className="lot_form_wrapper_row">
            <div>
              <h3>{lotLabelOpen?.value}</h3>
              <div className="label_wrapper">{renderLotLabelData()}</div>
            </div>
          </div>
          <div className="lot_form_wrapper_row">
            <div className="bio-vegan_wrapper">
              {(label?.find((l) => l?.label === 'Bio')) && <img className="icon" src={bio} alt={'bio'} />}
              {(label?.find((l) => l?.label === 'Vegan')) && <img className="icon" src={vegan} alt={'vegan'} />}
            </div>
          </div>
        </div>
    </div>
    );
  };

  const renderLotLabelData = () => {
    const wineLabel = wineLabels?.wine?.find((w) => w?.id === lotLabelOpen?.value) || null;
    if (wineLabel == null) return;

    const limits = wineLabel?.limits || [];
    const refinements = wineLabel?.refinements || [];
    
    return (
      <div>
        <h4 className="label_title">{getLabel('disciplinary')}</h4>
        <div>
          <h5 className="label_subtitle">{getLabel('wineComposition')}</h5>
          {limits?.map((l) => { return <div className="list-item">
            {l?.type === 'RANGE' && <p><span>{l?.wines?.[0] || ''}</span>{` ${l?.min * 100} - ${l?.max * 100}%`}</p>}
            {l?.type === 'COLOR' && <p><span>{getLabel(`lotColor_${l?.color}`) || ''}</span>{` ${l?.min * 100 || 0} - ${l?.max * 100 || 100}%`}</p>}
            {l?.type === 'OTHER_COLOR' && <p><span>{getLabel('otherVarieties')}</span>{" "}{getLabel(`lotColor_${l?.color}`) || ''} {` ${l?.min * 100 || 0} - ${l?.max * 100 || 100}%`}</p>}
            {l?.type === 'SUM' && <p><span>{l?.wines?.[0] || ''}</span>{` ${l?.min * 100} - ${l?.max * 100}%`}</p>}
          </div>})}
        </div>
        <div>
          <h5 className="label_subtitle">{getLabel('refinement')}</h5>
          {refinements?.map((r) => { return <div className="list-item">
            {r?.id && <p><span>{r?.id || ''}</span>{` ${r?.min || ''} - ${r?.max || '...'} ${getLabel('refinementMonths')}`}</p>}
          </div>})}
        </div>
      </div>
    )
  }
  
  return (<>
    {!isOnlyForm && <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("lotsNavLink"),
          getLabel(id ? "lotsUpdatePage" : "lotsNewPage"),
        ]}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>}
    {isOnlyForm && renderForm()}
  </>)
};

export default LotForm;
