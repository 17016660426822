const LOGIN = "/auth/login",
    REGISTER_URL = '/auth/register',
    WINERIES = '/wineries',
    WINELABELS = '/winelabels',
    VARIETIES = '/varieties',
    LABELS = '/labels',
    TANKS = '/tanks',
    SENSORS = '/sensors',
    OPERATIONS = '/operations',
    NOTE = '/note',
    USER = '/user',
    USERS = '/users',
    LOTS = '/batches',
    TRACKING = '/tracking',
    TREE = '/tree',
    PROTOCOLS = '/protocols',
    RESULTS = '/results',
    RECURRENT = '/recurrent',
    DRUGS = '/drugs',
    WAREHOUSE = '/warehouse',
    ANALYSIS = '/analysis',
    CLIENTS = '/clients',
    WINEMAKING = '/winemaking',
    STATE = '/state',
    WORK_MODE = '/work_mode',
    APPLY = '/apply',
    UTILS = '/utils',
    COOKIE = '/cookie',
    HISTORY = '/history',
    COUNT = '/count',
    EXPIRED = '/expired',
    UNITS='/units',
    UPDATE='/update',
    SETTINGS='/settings',
    PERMISSIONS='/permissions',
    EMAIL='/email',
    QR='/qr',
    NEW_PASSWORD='/new-password',
    PDF = '/create-pdf',
    EXCEL = '/create-excel',
    DICTIONARY = '/dictionary',
    TOKEN_KEY = 'auth'

export {
    LOGIN,
    REGISTER_URL,
    TOKEN_KEY,
    WINERIES,
    WINELABELS,
    LABELS,
    VARIETIES,
    TANKS,
    SENSORS,
    LOTS,
    OPERATIONS,
    NOTE,
    PROTOCOLS,
    RESULTS,
    RECURRENT,
    USERS,
    USER,
    DRUGS,
    WAREHOUSE,
    ANALYSIS,
    CLIENTS,
    WINEMAKING,
    STATE,
    WORK_MODE,
    APPLY,
    HISTORY,
    COUNT,
    EXPIRED,
    UTILS,
    COOKIE,
    TRACKING,
    TREE,
    UNITS,
    UPDATE,
    SETTINGS,
    EMAIL,
    QR,
    PERMISSIONS,
    NEW_PASSWORD,
    PDF,
    EXCEL,
    DICTIONARY,
}