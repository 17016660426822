import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectAnalysis, selectConfigs, selectExams, selectTanks, selectTreatments, selectUser, selectUsers } from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import { useEffect, useState } from "react";

import wine_press_img from "../../assets/icons/wine-press.png";
import bottling_img from "../../assets/icons/bottling.png";
import treatment_img from "../../assets/icons/treatment.png";
import analysis_img from "../../assets/icons/analysis.png";

import grapes_img from "../../assets/icons/grapes.png";
import must_img from "../../assets/icons/musts.png";
import wine_img from "../../assets/icons/wines.png";
import bottled_img from "../../assets/icons/bottles.png";

import "./styles/index.scss";
import Gauge from "./Gauge";

const ElementCard = ({ data, type, rowClickHandler, className }) => {
  const configs = useSelector(selectConfigs);
  const tanks = useSelector(selectTanks);
  const users = useSelector(selectUsers);
  const user = useSelector(selectUser);
  const treatments = useSelector(selectTreatments);
  const analysis = useSelector(selectAnalysis);
  const exams = useSelector(selectExams);
  // const exams = useSelector(selectD);
  const [img, setImg] = useState(null);
  const [getLabel] = useLabels();

  useEffect(() => {
    if (type === "operation") {
      switch (data?.type) {
        case 'DESTEMMING':
          setImg(wine_press_img);
          break;
        case 'BOTTLING':
          setImg(bottling_img);
          break;
        case 'TREATMENT':
          setImg(treatment_img);
          break;
        case 'ANALYSIS':
          setImg(analysis_img);
          break;
        default:
          break;
      }
    } else if (type === "lot") {
      switch (data?.state) {
        case 'GRAPE':
          setImg(grapes_img);
          break;
        case 'MUST':
          setImg(must_img);
          break;
        case 'WINE':
          setImg(wine_img);
          break;
        case 'BOTTLED':
          setImg(bottled_img);
          break;
        default:
          break;
      }
    } else if (type === "treatment") {
      setImg(treatment_img);
    } else if (type === "analysis" || type === "analysisDetail") {
      setImg(analysis_img);
    }
  });

  const computeAdditionalTypeString = () => {
    if (['CUT', 'DECANT', 'RACKING', 'ANALYSIS', 'TREATMENT', 'DESTEMMING', 'NEW_LOT', 'BOTTLING'].includes(data?.type)) return '';
    const protocolType = data?.src_tanks?.[0]?.protocolType || null;
    const homogenizationType = protocolType === 'HOMOGENIZATION_REASSEMBLY' ? data?.src_tanks?.[0]?.homogenizationType || null : null;
    
    // TO DO: aggiungi cappello o altro in omogenizzazione temperatura
    if (protocolType !== null && protocolType !== 'ADD') return ` (${getLabel(`protocolType_${protocolType}`)}${homogenizationType ? ` ${getLabel(`protocolHomogenizationMass_${homogenizationType}`)}` : ''}) `?.toLowerCase();
    return '';
  };

  const computeRecurrentString = () => {
    if (!["ANALYSIS", "TREATMENT"].includes(data?.type)) return "";
    const arr = (data?.type === 'TREATMENT' ? treatments : analysis) || [];
    const protocolName = arr?.find((p) => p?.protocols_id === data?.protocol_id)?.protocols_name || '';
    const pName = ""; // protocolName?.length > 0 ? ` [${protocolName}]` : '';
  
    if (!data?.repetitive) return ` #${data?.id}${pName}`;
    
    const current = data?.relative_id || 0;
    const total = data?.total || 0;

    return current > 0 && total > 0
      ? ` #${data?.main_id || data?.id} (${current}/${total})${pName}`
      : "";
  };

  const computeAdditionalLotInfo = () => {
    const additional = [];
    const MAX_VARIETIES_ROW = 2;
    for (let i = 0; i < data?.varieties?.length && i < MAX_VARIETIES_ROW; i++) {
      const v = data?.varieties?.[i] ? getLabel(`lotVarieties_${data?.varieties?.[i]}`) : '';
      const y = data?.years?.[i] ? ` (${data?.years?.[i]})` : '';
      const o = data?.origins?.[i] ? `, ${data?.origins?.[i]}` : '';
      additional.push(`${v} ${y}${o}`);
    }
    if (data?.varieties?.length > MAX_VARIETIES_ROW) additional.push("...");

    return additional;
  };

  const renderByType = () => {
    const values = { isTag: true, isDate: false };

    switch (type) {
      case "operation":
      case "expiredOperation":
        values.name = getLabel(`operationType${data?.type}`) + computeAdditionalTypeString() + computeRecurrentString();
        values.tanks = data?.src_tanks?.slice(0, 2)?.map((tank) => (
          !tank?.tank_id
              ? tank?.name
              : tanks?.find((t) => t?.id === Number(tank?.tank_id))
                  ?.name || "")) || [];
        
        values.tag = String(data?.priority) ? getLabel(`operationPriority${data?.priority}`) : "";
        values.border = tanks?.find((t) => t?.id === Number(data?.src_tanks?.[0]?.tank_id))?.color?.toLowerCase() || "red";
        values.tagClass = data?.priority === 2 ? "high" : data?.priority === 1 ? "medium" : "low";
        
        const cellarmen = [data?.cellarman_ids?.slice(0, 2)
          ?.filter((u) => u?.role !== 3 || u?.id === user?.id)
          ?.map((u) => (users?.find((uu) => uu?.id === Number(u))?.username || ""))];
        const currentUsername = user?.role === 3 && data?.cellarman_ids?.includes(user?.id) ? user?.username : null;
        if (currentUsername) cellarmen.unshift(currentUsername);
        values.cellarmen = cellarmen;

        values.isExpired = new Date(data?.expire_date) < new Date();
        values.isDate = true;
        values.expire_date = data?.expire_date
        ? format(
            new Date(data?.expire_date),
            configs.compactDateFormat
          )
        : "";
        const icons = [];
        if (data?.type === 'ANALYSIS') icons.push(data?.sampled ? "fa-flask-vial" : "fa-eye-dropper");
        if (!values?.isExpired && data?.is_exact_date) icons.push("fa-calendar-day");
        values.icons = icons;

        break;
      case "lot":
        values.name = data?.name;
        values.tanks = data?.tanks?.map((tank) => !tank?.tank_id
          ? tank?.name
          : tanks?.find((t) => t?.id === Number(tank?.tank_id))
              ?.name || "") || [];
        values.tag = data?.quality ? getLabel(`lotQuality_${data?.quality}`) : "";
        values.border = data?.color?.toLowerCase() || "red";
        values.tagClass = data?.quality === "HIGH" ? "high" : data?.quality === "MEDIUM" ? "medium" : "low";
        values.expire_date = [`${data?.quantity} ${data?.state === "GRAPE" ? "Kg" : "L"}`];
        values.cellarmen = computeAdditionalLotInfo();
        values.isDate = true;

        break;
      case "analysis":
      case "treatment":
      case "substance":
        values.name = data?.protocols_name;
        values.tanks = (type === "treatment" ? data?.drugs : data?.analysis)?.map((substance) => substance?.substance_name ? (type === "analysis" ? getLabel(`substance_${substance?.substance_name}`) : type === 'treatment' ? substance?.substance_name || '' : "") : ''
        ) || [];
        values.tag = data?.state ? getLabel(`lotState_${data?.state}`) : "";
        values.border = data?.color?.toLowerCase() || "red";
        values.isTag = type === "analysis";
        values.tagClass = "low";
        break;
      case "analysisDetail":
        values.name = data?.protocols_name;
        values.tanks = (type === "treatment" ? data?.drugs : data?.analysis)?.map((substance) => substance?.substance_name ? (type === "analysis" ? getLabel(`substance_${substance?.substance_name}`) : type === 'treatment' ? substance?.substance_name || '' : "") : ''
        ) || [];
        values.tag = data?.state ? getLabel(`lotState_${data?.state}`) : "";
        values.border = data?.color?.toLowerCase() || "red";
        values.isTag = type === "analysis";
        values.tagClass = "low";
        break;
        // return <>
        // <Gauge min={0} max={100} current={33} unit={"mg/hL"}/>
        // </>
      default:
        return;
    }
    return renderCard(values);
  };

  const renderCard = (values) => {
    return (
      <div className={`element-card-container ${className}`} onClick={() => {if (rowClickHandler) rowClickHandler()}}>
       {values?.isTag && <div className="priority_tag_wrapper">
          <p className={`priority_tag ${values?.tagClass}`}>
            {values?.tag}
          </p>
        </div>}
        <div className={`element-card ${values?.border}`}>
          <div className="card-content">
            <div className="content">
              <div className="upper">
                <p className="title">
                  {values?.name}
                </p>
                {values.isDate && <div className="info_wrapper">
                  <p className={`date ${values?.isExpired ? "expired" : ""}`}>
                    {values?.icons?.map((i, index) => <span className="icon" key={`icon-${index}`}><FontAwesomeIcon icon={i?.icon} size="xs" /></span>)}
                    {values?.expire_date}
                  </p>
                </div>}
              </div>
              <div className="lower">
                <div className="info_wrapper">
                  <div className="src_tanks">
                    {values?.tanks?.slice(0, 2)?.map((val, index) => (
                      <p className="tank_name" key={`tank-${index}`}>
                        {val}
                      </p>
                    ))}
                    {values?.tanks?.length > 2 && (
                      <p className="tank_name">...</p>
                    )}
                  </div>
                  <div className="icon-wrapper">
                      {img && <img className={`icon`} src={img} alt={'icon'} />}
                  </div>
                  <div className="cellarmen">
                    {values?.cellarmen?.map((c, index) => <p className="cellarman_name"  key={`cellarman-${index}`}>{c}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className="list-element">{renderByType()}</div>;
};

export default ElementCard;
