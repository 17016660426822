import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { deleteProtocol, getProtocols } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { selectWineMaking } from "../../context/selectors";
import SecondaryBar from "../SecondaryBar";
import { Button, ConfirmationModal, CTA } from "../Generic";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Label } from "recharts";
import TableEdit from "../TableEdit";
import TableCell from "../TableEdit/TableCell";
import { createColumnHelper } from "@tanstack/react-table";
import {
  removeWineMaking,
  setWineMaking,
} from "../../context/protocols/wineMakingSlice";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
import { getItem } from "../../services/SessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WineMakingProtocolDetail = () => {
  const { type, id } = useParams();
  const wineMakingOps = useSelector(selectWineMaking);
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [operationsP, setOperationP] = useState(
    wineMakingOps?.length > 0
      ? wineMakingOps.find((dbProtocol) => dbProtocol.id === Number(id))
          ?.operations || []
      : []
  );
  const [isOpen, setIsOpen] = useState(false);
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const navigate = useNavigate();

  const pageName = getLabel("wineMakingProtocolTitlePage", {
    name:
      wineMakingOps.find((dbProtocol) => dbProtocol.id === Number(id))?.name ||
      id,
  });

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) => row?.day + (row?.toDay ? ` - ${row?.toDay}` : ""),
      {
        header: getLabel("protocolDay"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    columnHelper.accessor(
      (row) => (row?.phase ? getLabel(`protocolPhase_${row.phase}`) : ""),
      {
        header: getLabel("protocolPhase"),
        cell: TableCell,
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.operation ? getLabel(`protocolOperation_${row.operation}`) : "",
      {
        header: getLabel("protocolOperation"),
        cell: TableCell,
        meta: {
          type: "text",
        },
      }
    ),
    columnHelper.accessor(
      (row) => (row?.operation === "SO2" ? "" : row?.product_name || ""),
      {
        header: getLabel("protocolProduct"),
        cell: TableCell,
        meta: {
          type: "text",
        },
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.quantity
          ? row?.protocolType === "HOMOGENIZATION_TEMP"
            ? `${row?.quantity} \u00b0C`
            : (row?.quantity || 0) +
              " " +
              (row?.product?.unit || row?.product_unit || "")
          : "",
      {
        header: getLabel("protocolQuantity"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    // columnHelper.accessor("cost", {
    //   header: getLabel("protocolCost"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //   },
    // }),
    columnHelper.accessor(
      (row) =>
        row?.protocolType
          ? getLabel(`protocolType_${row?.protocolType}`) +
            (row?.protocolType === "HOMOGENIZATION_REASSEMBLY" &&
            row?.homogenizationType
              ? ` (${getLabel(
                  `protocolHomogenizationMass_${row?.homogenizationType}`
                )})`
              : "") +
            (row?.protocolType === "CUSTOM"
              ? ` (${row?.oxygenationDoseHours}h:${row?.oxygenationDoseMinutes}m)`
              : "")
          : "",
      {
        header: getLabel("protocolType"),
        cell: TableCell,
        meta: {
          type: "text",
        },
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.workTime && (row?.pauseTime || row?.homogenizationPauseTime)
          ? `${row?.workTime}min / ${
              row?.pauseTime || row?.homogenizationPauseTime
            }h`
          : "",
      {
        header: getLabel("protocolWorkPauseTime"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    // columnHelper.accessor(
    //   (row) => (row?.workTime ? row?.workTime + " min" : ""),
    //   {
    //     header: getLabel("protocolWorkTime"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    // columnHelper.accessor(
    //   (row) =>
    //     row?.homogenizationPauseTime ? row?.homogenizationPauseTime + " h" : "",
    //   {
    //     header: getLabel("protocolPauseTime"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    columnHelper.accessor(
      (row) =>
        row?.homogenizationMotorSpeed
          ? row?.homogenizationMotorSpeed + "%"
          : "",
      {
        header: getLabel("protocolMotorSpeed"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    columnHelper.accessor("iterPerDay", {
      header: getLabel("protocolIterationPerDay"),
      cell: TableCell,
      meta: {
        type: "number",
      },
    }),
    columnHelper.accessor("goal", {
      header: getLabel("protocolGoals"),
      cell: TableCell,
    }),
    columnHelper.accessor("note", {
      header: getLabel("protocolNotes"),
      cell: TableCell,
    }),
    // columnHelper.accessor("oxygenationType", {
    //   header: getLabel("protocolOxygenationType"),
    //   cell: TableCell,
    //   meta: {
    //     type: "text",
    //     register: register,
    //   },
    // }),
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await removeProtocol();
    handleCloseModal();
  };

  useEffect(() => {
    if (!wineMakingOps || wineMakingOps.length === 0) {
      loadProtocols();
    }
  }, []);

  useEffect(() => {
    if (wineMakingOps && wineMakingOps.length > 0) {
      setOperationP(
        wineMakingOps.find((dbProtocol) => {
          return Number(dbProtocol.id) === Number(id);
        })?.operations || []
      );
    }
  }, [wineMakingOps]);

  const loadProtocols = async () => {
    const currentProtocols = await getProtocols("winemaking", axiosPrivate);
    dispatch(setWineMaking(currentProtocols));
  };

  const removeProtocol = async () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await deleteProtocol(id, "winemaking", axiosPrivate);
    const requestPayload = {
      ...(id && { id: Number(id) }),
    };

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: operationsP?.name || "" })
        : getLabel(response?.error),
      type: !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      dispatch(removeWineMaking(requestPayload));
      navigate(`/protocols/winemaking/${type}`);
    }
  };

  const buildJson = () => {
    const data = [];
    operationsP?.forEach((row) => {
      const tmp = [
        row?.day,
        getLabel(`protocolPhase_${row?.phase}`),
        getLabel(`protocolOperation_${row?.operation}`),
        getLabel(`protocolType_${row?.protocolType}`),  //"TIPO OP."
        row?.product_name,
        row?.quantity,
        row?.product_unit,
        row?.cost,
        row?.type,
        row?.workTime,
        row?.pauseTime,
        row?.homogenizationMotorSpeed,
        row?.iterPerDay,
        row?.oxygenationDoseHours,
        row?.oxygenationDoseMinutes,
        row?.note,
        row?.goal,
      ];

      if (row?.protocolType === 'HOMOGENIZATION_TEMP') tmp[6] = '\u00b0C';
      if (row?.operation === 'SO2') tmp[4] = '';
      else if (row?.protocolType === 'HOMOGENIZATION_REASSEMBLY' && row?.homogenizationType) {
        tmp[8] = getLabel(`protocolHomogenizationMass_${row?.homogenizationType}`)
        tmp[10] = row?.homogenizationPauseTime;
      } else if (row?.operation === 'OXYGENATION' && row?.protocolType) {
        tmp[8] = getLabel(`protocolOXYGENATION_${row?.protocolType}`)
      }
      data.push(tmp);
    });
    return data;
  };

  const handleExport = () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
      isLoading: true,
    });

    const data = buildJson();

    const language = getItem("i18LanguageLocale") || "it";
    const headers = [];
    switch (language) {
      case "en":
        headers.push(
          ...[
            "DAY",
            "PHASE",
            "OPERATION",
            "OP. TYPE",
            "OENOLOGICAL PRODUCT",
            "QUANTITY",
            "UNIT",
            "COST",
            "TYPE",
            "WORK TIME (min)",
            "PAUSE TIME (hh)",
            "MOTOR SPEED (% )",
            "NUMBER x DAY (hh)",
            "CUSTOM OXYG. HOURS (hh)",
            "CUSTOM OXIG. MINUTES (min)",
            "NOTE",
            "GOAL",
          ]
        );
        break;
      default:
        headers.push(
          ...[
            "GIORNO",
            "FASE",
            "OPERAZIONE",
            "TIPO OP.",
            "PRODOTTO ENOLOGICO",
            "QUANTITA'",
            "UNITA'",
            "COSTO",
            "TIPO",
            "TEMPO LAVORO (min)",
            "TEMPO PAUSA (hh)",
            "VELOCITA' MOTORE (%)",
            "NUMERO x GIORNO (hh)",
            "ORE OSSIG. PERSON. (hh)",
            "MINUTI OSSIG. PERSON. (min)",
            "NOTE",
            "OBIETTIVI",
          ]
        );
    }

    toast.update(toastId, {
      render:
        data?.length === 0
          ? getLabel("toast_exportWinemakingProtocolError")
          : getLabel("toast_exportWinemakingProtocolSuccess"),
      type: data?.length === 0 ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (data?.length === 0) return;

    const worksheet = XLSX.utils.json_to_sheet(data);
    // var worksheet = XLSX.utils.aoa_to_sheet([
    //   [ '' ], // A1
    //   [ {t: "n", f: "A2+A3"}, {t: "s", f: 'CHOOSE("","red","blue","green")'} ] // A2
    // ]);

    const wscols = [
      { wch: 10 },
      { wch: 15 },
      { wch: 13 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 18 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
    ];
    worksheet["!cols"] = wscols;
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const protocolName = wineMakingOps.find((dbProtocol) => dbProtocol.id === Number(id))?.name || 'data';
    XLSX.writeFile(
      workbook,
      `${protocolName}.xlsx`,
      { compression: true }
    );
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate(`/protocols/winemaking/${type}`);
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        {role === ROLES.CLIENT && (
          <div className="primary_container_row">
            <NavLink to={`/protocols/winemaking/${type}/${id}/update`}>
              <CTA>{getLabel("updateProtocol")}</CTA>
            </NavLink>

            <CTA onClick={handleOpenModal}>{getLabel("deleteProtocol")}</CTA>
            <CTA onClick={handleExport}>
              {getLabel("operationExcelExport")}{" "}
              <FontAwesomeIcon icon="fa-file-arrow-down" />
            </CTA>
            <ConfirmationModal
              isOpen={isOpen}
              onConfirm={handleFormSubmit}
              onClose={handleCloseModal}
              description={getLabel("modalDeleteProtocolDescription", {
                name: operationsP?.protocols_name || "",
              })}
            ></ConfirmationModal>
          </div>
        )}
      </div>
    );
  };

  const renderNextOperation = () => {
    return (
      <div>
        <div className="primary_container_menu">
          <h4>{getLabel("protocol_nextOperations")}</h4>
          <NavLink to={"/programming/new"}>
            <CTA>{getLabel("programOperation")}</CTA>
          </NavLink>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("protocolsNavLink"),
          getLabel("protocolTitlePage", {
            name: "",
            type: "",
          }),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      <h4 className="">{getLabel("wineMakingProtocolSubstances")}</h4>

      <TableEdit
        defaultData={
          operationsP?.map((s) => {
            return {
              ...s,
              opType: "WINEMAKINGPROTOCOL",
            };
          }) || []
        }
        columns={columns}
        isSearchBar={false}
      />
      <div className="protocol_info_container_row">
        <Label
          width={400}
          height={150}
          label={getLabel("protocolNotes")}
          customClassName={"notes"}
        >
          {operationsP.notes}
        </Label>
      </div>

      {renderNextOperation()}
    </div>
  );
};

export default WineMakingProtocolDetail;
