import { differenceInCalendarDays, isAfter, isBefore } from "date-fns";

export const selectLabels = (state) => state.labels;
export const selectUser = (state) => state.user;
export const selectConfigs = (state) => state.configs;
export const selectHistory = (state) => state.history;
export const selectWineries = (state) => state.wineries.list;
export const selectWineryDisplayGrid = (state) =>
  state.configs.wineriesDisplayGrid;
export const selectActiveWinery = (state) => state.wineries.active;
export const selectTanks = (state) => state.tanks;
export const selectWineLabels = (state) => state.wineLabels;
export const selectWineVarieties = (state) => state.wineVarieties;
export const selectTankDisplayGrid = (state) => state.configs.tanksDisplayGrid;
export const selectOperations = (state) => state.operations;
export const selectUsers = (state) => state.users;
export const selectSensors = (state) => state.sensors;
export const selectNextOperations = (state, dates) =>
  state.operations.filter(
    (operation) =>
      isBefore(new Date(operation.expire_date), dates.endDate) &&
      isAfter(new Date(operation.expire_date), dates.startDate) // ||
    // (isBefore(new Date(operation.insertion_date), dates.endDate) &&
    //   isAfter(new Date(operation.insertion_date), dates.startDate))
  );

// export const selectNextOperationsByTank = (state, dates, tankId) => state.operations.filter((operation) => {
//     return (
//         !operation.completion_date &&
//         (operation?.dest_tanks?.some?.((tank) => Number(tank.tank_id) === tankId) ||
//         operation?.src_tanks?.some?.((tank) => Number(tank.tank_id) === tankId)) &&
//         (isBefore(new Date(operation.expire_date), dates.endDate) &&
//             isAfter(new Date(operation.expire_date), dates.startDate)) ||
//         (isBefore(new Date(operation.insertion_date), dates.endDate) &&
//             isAfter(new Date(operation.insertion_date), dates.startDate))
//     )
// })

export const selectNextOperationsByType = (state, dates, id, type) =>
  [...state.operations, ...state.expOperations]?.filter((operation, index) => {
    if (type === "TANK")
      return (
        !operation.completion_date &&
        (operation?.dest_tanks?.some?.((tank) => Number(tank.tank_id) === id) ||
          operation?.src_tanks?.some?.(
            (tank) => Number(tank.tank_id) === id
          )) &&
        (index >= state.operations?.length ||
          (index < state.operations?.length &&
            isBefore(new Date(operation.expire_date), dates.endDate) &&
            isAfter(new Date(operation.expire_date), dates.startDate)) ||
          (isBefore(new Date(operation.insertion_date), dates.endDate) &&
            isAfter(new Date(operation.insertion_date), dates.startDate)))
      );
    else
      return (
        !operation.completion_date &&
        (operation?.batch_id_in === id ||
          operation?.dest_tanks?.some?.(
            (tank) => Number(tank.batch_id) === id
          ) ||
          operation?.src_tanks?.some?.(
            (tank) =>
              Number(tank.batch_id) === id ||
              Number(tank?.preOperation?.batch?.id) === id
          )) &&
        (index >= state.operations?.length ||
          (index < state.operations?.length &&
            isBefore(new Date(operation.expire_date), dates.endDate) &&
            isAfter(new Date(operation.expire_date), dates.startDate)) ||
          (isBefore(new Date(operation.insertion_date), dates.endDate) &&
            isAfter(new Date(operation.insertion_date), dates.startDate)))
      );
  });

export const selectToBeCompletedOperations = (state) =>
  state.operations.filter((operation) => !operation.completion_date);
export const selectExpiringOperations = (state) =>
  state.operations.filter(
    (operation) =>
      !operation.completion_date &&
      differenceInCalendarDays(new Date(operation.expire_date), new Date()) <= 7
  );
export const selectExpiredOperations = (state) =>
  state.expOperations.filter(
    (operation) => (
      !operation.completion_date &&
      differenceInCalendarDays(new Date(operation.expire_date), new Date()) <= 0)
  ) || [];

export const selectUnits = (state) => state.units;
export const selectDrugs = (state) => state.drugs;
export const selectWineMaking = (state) => state.wineMaking;
export const selectExams = (state) => state.exams;
export const selectStoredAnalysisResults = (state) => state.analysisResults;
export const selectSubstancesDisplayGrid = (state) =>
  state.configs.substancesDisplayGrid;
export const selectSubstancesWarehouseDisplayGrid = (state) =>
  state.configs.toggleSubstancesWarehouseDisplayGrid
export const selectTreatments = (state) => state.treatments;
export const selectTreatmentsDisplayGrid = (state) =>
  state.configs.treatmentsDisplayGrid;
export const selectAnalysis = (state) => state.analysis;
export const selectAnalysisDisplayGrid = (state) =>
  state.configs.analysisDisplayGrid;
export const selectClients = (state) => state.clients;
export const selectClientDisplayGrid = (state) =>
  state.configs.clientsDisplayGrid;
export const selectRealView = (state) => state.configs.realView;
export const selectLotDisplayGrid = (state) => state.configs.lotsDisplayGrid;
export const selectLots = (state) => state.lots;
export const selectProtocolDisplayGrid = (state) =>
  state.configs.protocolsDisplayGrid;
export const selectUsersDisplayGrid = (state) => state.configs.usersDisplayGrid;
export const selectNotificationsUnread = (state) =>
  state.configs.notificationsUnreadGrid;
export const selectUserSockets = (state) => state.userSockets;
export const selectAllLotNames = (state) => {
  const lotNames = new Set();
  state.lots?.reduce(
    (_, item) => lotNames.add({ name: item?.name, state: item?.state }),
    null
  );
  state.operations?.map((o) =>
    o?.dest_tanks
      ?.filter((d) => d?.new_batch_type !== "OLD" && d?.new_batch_name)
      ?.reduce(
        (_, item) =>
          lotNames.add({ name: item?.new_batch_name, ...(getStateColor(o, state.lots)) }),
        null
      )
  );

  return Array.from(lotNames);
};
const getStateColor = (operation, lots) => {
  const tmp = {};
  const src = operation?.src_tanks?.[0] || {};
  tmp.color = src?.color || 'RED';

  switch (operation?.type) {
    case "DESTEMMING":
      tmp.state = "MUST";
      break;
    case "RACKING":
      tmp.state = "WINE";
      break;
    case "BOTTLING":
      tmp.state = "BOTTLED";
      break;
    default:  // RACKING CUT NEW_LOT TENNISSAGE BOIS ENZYME SO2 HOMOGENIZATION_MASS OXYGENATION NITROGEN INOCULATION BOTTLING"
      console.log(src, operation?.dest_tanks)
      tmp.state = src?.state || lots?.find((lot) => lot?.id === src?.batch_id)?.state;
      break;
  }
  return tmp;
}
export const selectVirtualTanks = (state) => {
  const virtualTanks = [];
  for (const tank of state.tanks) {
    const virtualTank = {
      ...tank,
    };
    const relatedOps = state.operations?.filter(
      (o) =>
        !["ANALYSIS", "TREATMENT"].includes(o?.type) &&
        (o?.src_tanks?.some((s) => s.tank_id === tank?.id) ||
          o?.dest_tanks?.some((s) => s.tank_id === tank?.id))
    );
    for (const op of relatedOps) {
      if (op?.type === "CUT") {
      } else {
        const opDest = op?.dest_tanks?.find((s) => s.tank_id === tank?.id);
        virtualTank.batch_id = null;
        virtualTank.batch_name = opDest?.new_batch_name;
        virtualTank.color = "RED";
        virtualTank.quantity = Number(tank?.quantity) + Number(opDest?.quantity);
        virtualTank.state = "MUST";
      };
    }
      virtualTanks.push(virtualTank);
    }

  return virtualTanks;
};
