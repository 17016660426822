import { useDispatch, useSelector } from "react-redux";
import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import {
  selectActiveWinery,
  selectConfigs,
  selectExpiredOperations,
  selectExpiringOperations,
  selectLots,
  selectNextOperations,
  selectTanks,
  selectToBeCompletedOperations,
  selectUsers,
  selectAnalysis,
  selectTreatments,
  selectOperations,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { lazy, Suspense, useEffect, useState } from "react";
import { addDays, addMonths, format } from "date-fns";
import { NavLink, useNavigate } from "react-router-dom";
import {
  BatchColumn,
  CTA,
  CellarmanColumn,
  Dropdown,
  TankColumn,
  BottlingColumn,
} from "../Generic";
import {
  getExpiredOperations,
  getLots,
  getOperations,
  getPDF,
  getProtocols,
  getTanks,
  getUsers,
} from "../../services/utils";
import { setTanks } from "../../context/tanks/tanksSlice";
import { setUsers } from "../../context/users/usersSlice";
import { setOperations } from "../../context/operations/operationsSlice";
import { setLots } from "../../context/lots/lotsSlice";

import "./styles/index.scss";
import ReactDatePicker from "react-datepicker";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
// import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useMedia } from "react-use";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import { setExpOperations } from "../../context/expOperations/expOperationsSlice";
import { SkeletonTableEdit } from "../Skeleton/Skeleton";

const TableEdit = lazy(() => import("../TableEdit"));

const OperationsHub = ({ isMobile }) => {
  // const isMobile = useMedia("(max-width: 480px)");
  // const isTablet = useMedia("(min-width: 481px) and (max-width: 736px)");
  const configs = useSelector(selectConfigs);
  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: addMonths(
      new Date(),
      configs.settings.nextOperationMonthsInFutureDefault || 3
    ),
  });
  const analysis = useSelector(selectAnalysis);
  const treatments = useSelector(selectTreatments);
  const [getLabel] = useLabels();
  const activeWinery = useSelector(selectActiveWinery);
  // const operations2 = useSelector((state) =>
  //   selectNextOperations(state, {
  //     startDate: dates.startDate,
  //     endDate: dates.endDate,
  //   })
  // );
  const operations = useSelector(selectOperations);
  const toBeDoneOperations = useSelector(selectToBeCompletedOperations);
  const expiringOperations = useSelector(selectExpiringOperations);
  const expiredOperations = useSelector(selectExpiredOperations);
  // const [expiredOperations, setExpiredOperations] = useState([]);
  // const expiredOperations = useSelector(selectExpiredOperations);
  const users = useSelector(selectUsers);
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const user_id = auth?.user_id || null;

  useEffect(() => {
    // find min and max dates
    const dates = [...expiredOperations, ...operations]?.map((op) => new Date(op?.expire_date));
    if (!dates.length) return;

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    setDates({
      startDate: addDays(minDate.setHours(0, 0, 0), -1),
      endDate: addMonths(
        maxDate.setHours(0, 0, 0),
        configs.settings.nextOperationMonthsInFutureDefault || 3
      ),
    });
  }, [operations, expiredOperations]);
  
  const computeAdditionalTypeString = (operation) => {
    if (
      [
        "CUT",
        "DECANT",
        "RACKING",
        "ANALYSIS",
        "TREATMENT",
        "DESTEMMING",
        "NEW_LOT",
        "BOTTLING",
      ].includes(operation?.type)
    )
      return "";
    const protocolType = operation?.src_tanks?.[0]?.protocolType || null;
    const homogenizationType =
      protocolType === "HOMOGENIZATION_REASSEMBLY"
        ? operation?.src_tanks?.[0]?.homogenizationType || null
        : null;

    // TO DO: aggiungi cappello o altro in omogenizzazione temperatura
    if (protocolType !== null && protocolType !== "ADD")
      return ` (${getLabel(`protocolType_${protocolType}`)}${
        homogenizationType
          ? ` ${getLabel(`protocolHomogenizationMass_${homogenizationType}`)}`
          : ""
      }) `?.toLowerCase();
    return "";
  };

  const computeRecurrentString = (operation) => {
    if (!["ANALYSIS", "TREATMENT"].includes(operation?.type)) return "";
    const arr = (operation?.type === "TREATMENT" ? treatments : analysis) || [];
    const protocolName =
      arr?.find((p) => p?.protocols_id === operation?.protocol_id)
        ?.protocols_name || "";
    const pName = protocolName?.length > 0 ? ` [${protocolName}]` : "";

    if (!operation?.repetitive) return ` #${operation?.id}${pName}`;

    const current = operation?.relative_id || 0;
    const total = operation?.total || 0;

    return current > 0 && total > 0
      ? ` #${operation?.main_id || operation?.id} (${current}/${total})${pName}`
      : "";
  };

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  useEffect(() => {
    if (!treatments || treatments.length === 0) {
      loadTreatments();
    }
    if (!analysis || analysis.length === 0) {
      loadAnalysis();
    }
  }, []);

  const loadAnalysis = async () => {
    const currentAnalysis = await getProtocols("analysis", axiosPrivate);
    dispatch(setAnalysis(currentAnalysis));
  };

  const loadTreatments = async () => {
    const currentTreatments = await getProtocols("treatment", axiosPrivate);
    dispatch(setTreatments(currentTreatments));
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) =>
        getLabel(`operationType${row.type}`) +
        computeAdditionalTypeString(row) +
        computeRecurrentString(row),
      {
        header: getLabel("operationType"),
        cell: TableCell,
        meta: {
          type: "operationType",
          filterVariant: "select",
          selector: "type",
        },
      }
    ),
    columnHelper.accessor(
      (row) => getLabel(`operationPriorityLong${row.priority}`),
      {
        header: getLabel("priority"),
        cell: TableCell,
        meta: {
          filterVariant: "select",
        },
        sortingFn: "PrioritySortingFn",
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.expire_date
          ? format(
              new Date(row.expire_date),
              sameDay(new Date(row?.expire_date), new Date())
                ? configs.hourShortDateFormat
                : configs.shortDateFormat
            )
          : "",
      {
        // TO DO short date se repetitive nell stesso giorno
        header: getLabel("expiringDate"),
        cell: TableCell,
        meta: {
          isDate: true,
          iconType: "fa-calendar-day",
          isFilterDisabled: true,
          colorExpired: true,
          // filterVariant: 'date',
        },
        // filterFn: 'DateInRange',
      }
    ),
    columnHelper.accessor("operationSrcTank", {
      header: getLabel("operationSrcTank"),
      cell: (props) =>
        props.row?.original?.type === "NEW_LOT" ? (
          props.row?.original?.src_tanks?.[0]?.name || ""
        ) : props.row?.original?.src_tanks?.find(
            (tank) =>
              tank?.batch_id != null &&
              ["ANALYSIS", "TREATMENT"].includes(props.row?.original?.type)
          ) || props.row.original?.batch_id_in ? (
          <BatchColumn
            lots={lots}
            rowData={props.row?.original}
            type={props.row?.original?.type}
          />
        ) : (
          <TankColumn rowData={props.row?.original?.src_tanks} tanks={tanks} />
        ),
      meta: {
        array: "src_tanks",
        selector: "name",
        filterVariant: "text",
      },
      filterFn: "MultipleRowsFilterFn",
    }),
    columnHelper.accessor("operationDestTank", {
      header: getLabel("operationDestTank"),
      cell: (props) =>
        props.row?.original?.type === "BOTTLING" ? (
          <BottlingColumn rowData={props?.row?.original?.src_tanks?.[0]} />
        ) : props.row?.original?.dest_tanks?.find((tank) => tank?.batch_id) ? (
          <BatchColumn
            lots={lots}
            rowData={props.row?.original}
            type={props.row?.original?.type}
          />
        ) : (
          <TankColumn rowData={props.row?.original?.dest_tanks} tanks={tanks} />
        ),
      meta: {
        array: "dest_tanks",
        selector: "name",
        filterVariant: "text",
      },
      filterFn: "MultipleRowsFilterFn",
    }),

    // {
    //   Header: getLabel("operationCompletionDate"),
    //   accessor: (row) =>
    //     row.completion_date
    //       ? format(new Date(row.completion_date), configs.shortDateFormat)
    //       : "-",
    // },
    // {
    //   Header: getLabel("operationNotes"),
    //   accessor: (row) => row.notes,
    // },
  ];

  if (role === ROLES.CLIENT) {
    columns.push(
      columnHelper.accessor("cellarman", {
        header: getLabel("cellarman"),
        cell: (props) => (
          <CellarmanColumn
            rowData={props.row?.original?.cellarman_ids}
            users={users}
          />
        ),
        meta: {
          array: users,
          selector: "cellarman_ids",
          filterVariant: "text",
        },
        filterFn: "MultipleRowsFilterFn",
      })
    );
  }
  const loadOperations = async () => {
    const currentOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(currentOperations));
  };

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers));
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery?.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery?.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadExpiredOperations = async () => {
    const currentExpiredOperations = await getExpiredOperations(
      activeWinery?.id,
      axiosPrivate
    );
    // setExpiredOperations([...currentExpiredOperations]);
    dispatch(setExpOperations(currentExpiredOperations));
  };

  const initData = async () => {
    const promiseArray = [];
    if (operations?.length === 0) {
      promiseArray.push(loadOperations());
    }
    if (users?.length === 0) {
      promiseArray.push(loadUsers());
    }
    if (tanks?.length === 0) {
      promiseArray.push(loadTanks());
    }
    if (lots?.length === 0) {
      promiseArray.push(loadLots());
    }
    if (expiredOperations?.length === 0) {
      promiseArray.push(loadExpiredOperations());
    }

    return Promise.all(promiseArray);
  };

  useEffect(() => {
    initData();
  }, []);

  // useEffect(() => {
  //   if (operations?.length > 0)
  //     setAllOperations([
  //       ...operations?.filter((o) => new Date() > new Date(o?.expire_date))?.sort(function(a, b) {
  //         let adate = new Date(a?.expire_date),
  //             bdate = new Date(b.expire_date),
  //             rv = adate - bdate;
  //         if (rv === 0) {
  //             rv = b?.priority - a?.priority;
  //         }
  //         return rv;
  //       }),
  //     ...operations?.filter((o) => new Date() <= new Date(o?.expire_date))?.sort(function(a, b) {
  //       let adate = new Date(a?.expire_date),
  //           bdate = new Date(b.expire_date),
  //           rv = adate - bdate;
  //       if (rv === 0) {
  //           rv = b?.priority - a?.priority;
  //       }
  //       return rv;
  //     })
  //     ]);
  // }, [operations]);

  const requestPDF = async (expiringTodayOnly = false) => {
    const data = {
      pdf_type: "operations",
      type: "operations",
      title: getLabel("operations"),
      rows: [
        ...operations?.filter(
          (op) =>
            !expiringTodayOnly ||
            new Date(op?.expire_date).setHours(0, 0, 0, 0) ==
              new Date().setHours(0, 0, 0, 0)
        ),
        ...expiredOperations,
      ]?.map((operation) => operation.id),
      columns: [
        getLabel("priority"),
        getLabel("operationType"),
        getLabel("operationSrcTank"),
        getLabel("operationDestTank"),
        ...(role === ROLES.CLIENT ? [getLabel("cellarman")] : []),
        getLabel("expiringDate"),
        // getLabel("protocolAdditional"),
        // getLabel("operationNotes"),
      ],
      headers: [
        "priority",
        "type",
        "src_tanks",
        "dest_tanks",
        ...(role === ROLES.CLIENT ? ["cellarman_ids"] : []),
        "expire_date",
        // "protocolAdditional",
        // "note",
      ],
      winery_name: activeWinery?.name || "",
      order_by: "expire_date",
      // "order_sort": "desc",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language:
        navigator.languages?.length > 0
          ? navigator.languages[0]?.replace("_", "-")
          : "it-IT",
    };

    await getPDF(data, axiosPrivate);
  };

  const renderWarning = (type) => {
    const operations = (
      type === "tbd" ? toBeDoneOperations : expiringOperations
    )?.filter((op) => new Date(dates.startDate) < new Date(op.expire_date));
    // TO DO user settings: the user can chose to use dates.startDate or now()
    return (
      <div
        className={`warning_wrapper warning_${
          type === "exp" ? (operations.length > 0 ? "exp" : "ok") : type
        } split ${operations.length === 0 ? "is_grey" : ""}`}
      >
        {operations?.length > 0 ? (
          <div className="warning_label">
            {getLabel(
              type === "tbd" ? `operationsToBeCompleted` : "expiringOperations"
            )}
          </div>
        ) : (
          <div className="warning_label">
            {getLabel(
              type === "tbd"
                ? "noOperationsToBeCompleted"
                : "expiringOperations"
            )}
          </div>
        )}
        {operations?.length > 0 && (
          <div className="warning_value">{operations.length}</div>
        )}
        {(!operations || operations?.length === 0) && (
          <div className="warning_value">0</div>
        )}
      </div>
    );
  };

  const renderExpired = () => {
    if (expiredOperations?.length > 0)
      return (
        <div className="warning_wrapper warning_due full">
          <div className="warning_label">{getLabel("expiredOperations")}</div>
          <div className="warning_value">{expiredOperations.length}</div>
          {/* {(!operations || operations?.length === 0) && (
          <div className="warning_value">0</div>
        )} */}
        </div>
      );
  };

  const renderNavMenu = () => {
    return (
      <>
        {/* <div className="primary_container_menu"> */}
        <h2>{getLabel("programmingNavLink")}</h2>
        <div className="hub_primary_container_row cards">
          <div className="hub_primary_container_row_warning_wrapper">
            <div className="hub_primary_container_row_warning_wrapper_upper">
              <div className="modify-button">
                <NavLink to={user_id ? `/users/${user_id}/customize` : ""}>
                  <CTA>
                    <FontAwesomeIcon icon="fa-gear" />
                  </CTA>
                </NavLink>
              </div>
              {renderWarning("tbd")}
              {expiringOperations?.length > 0 && renderWarning("exp")}
            </div>
            {renderExpired()}
          </div>
        </div>
        <div className="hub_primary_container_row no_wrap">
          {role === ROLES.CLIENT && (
            <div className="operation_dropdown">
              <Dropdown
                label={
                  <>
                    {getLabel("programOperation")}{" "}
                    <FontAwesomeIcon icon="fa-calendar-plus" />
                  </>
                }
                options={[
                  {
                    label: getLabel("operationTypeANALYSIS"),
                    handler: () => navigate("/programming/ANALYSIS/new"),
                  },
                  {
                    label: getLabel("operationTypeCUT"),
                    handler: () => navigate("/programming/CUT/new"),
                  },
                  {
                    label: getLabel("operationTypeDECANT"),
                    handler: () => navigate("/programming/DECANT/new"),
                  },
                  {
                    label: getLabel("operationTypeDESTEMMING"),
                    handler: () => navigate("/programming/DESTEMMING/new"),
                  },
                  {
                    label: getLabel("operationTypeRACKING"),
                    handler: () => navigate("/programming/RACKING/new"),
                  },
                  {
                    label: getLabel("operationTypeTREATMENT"),
                    handler: () => navigate("/programming/TREATMENT/new"),
                  },
                  {
                    label: getLabel("operationTypeWINEMAKING"),
                    handler: () => navigate("/programming/WINEMAKING/new"),
                  },
                  {
                    label: getLabel("operationTypeWINEMAKINGPOST"),
                    handler: () => navigate("/programming/WINEMAKINGPOST/new"),
                  },
                  {
                    label: getLabel("operationTypeBOTTLING"),
                    handler: () => navigate("/programming/BOTTLING/new"),
                  },
                ]}
              ></Dropdown>
            </div>
          )}

          <div className="operation_additional">
            {/* <CTA onClick={requestPDF}>{isMobile ? <FontAwesomeIcon icon="fa-print" /> : getLabel("print")}</CTA> */}
            <Dropdown
              label={
                isMobile ? (
                  <FontAwesomeIcon icon="fa-print" />
                ) : (
                  <>
                    {getLabel("print")} <FontAwesomeIcon icon="fa-print" />
                  </>
                )
              }
              options={[
                {
                  label: getLabel("operationPrintToday"),
                  handler: () => requestPDF(true),
                },
                {
                  label: getLabel("operationPrintAll"),
                  handler: () => requestPDF(),
                },
              ]}
            />
            <NavLink to="/programming/history" className="history">
              <CTA customClassName="height-100">
                {isMobile ? (
                  <FontAwesomeIcon icon="fa-book" />
                ) : (
                  <>
                    {getLabel("showHistory")} <FontAwesomeIcon icon="fa-book" />
                  </>
                )}
              </CTA>
            </NavLink>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  };

  const renderInterval = () => {
    return (
      // <div className="primary_container_menu">
      <div className="hub_primary_container_row end">
        <div className="interval_row">
          <div className="interval_value">
            <div className="interval_vwrapper">
              <span>{getLabel("dateFrom")}</span>
              <ReactDatePicker
                selected={dates.startDate}
                onChange={(date) =>
                  setDates({
                    ...dates,
                    startDate: date.setHours(0, 0, 0),
                  })
                }
                selectsStart
                startDate={dates.startDate}
                endDate={dates.endDate}
                minDate={dates.startDate}
                dateFormat={configs.shortDateFormat}
              />
            </div>
            <div className="interval_vwrapper">
              <span>{getLabel("dateTo")}</span>
              <ReactDatePicker
                selected={dates.endDate}
                onChange={(date) =>
                  setDates({
                    ...dates,
                    endDate: date.setHours(23, 59, 59),
                  })
                }
                selectsEnd
                startDate={dates.startDate}
                endDate={dates.endDate}
                minDate={addDays(dates.startDate, 1)}
                dateFormat={configs.shortDateFormat}
              />
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  };

  const renderList = () => {
    if (operations.length > 0 || expiredOperations?.length > 0) {

      return (
        // <div className="primary_container_menu">
        <div className="hub_primary_container_row">
          <div className="operations_list_table">
            <h5>{getLabel("programmedOperations")}</h5>
            <Suspense fallback={<SkeletonTableEdit colCount={6} />}>
              <TableEdit
                isMobile={isMobile}
                type={"operation"}
                defaultData={[
                  ...expiredOperations?.sort(function (a, b) {
                    let adate = new Date(a?.expire_date),
                      bdate = new Date(b.expire_date),
                      rv = adate - bdate;
                    if (rv === 0) {
                      rv = b?.priority - a?.priority;
                    }
                    return rv;
                  }),
                  ...operations
                    ?.filter((o) => new Date() <= new Date(o?.expire_date))
                    ?.sort(function (a, b) {
                      let adate = new Date(a?.expire_date),
                        bdate = new Date(b.expire_date),
                        rv = adate - bdate;
                      if (rv === 0) {
                        rv = b?.priority - a?.priority;
                      }
                      return rv;
                    }),
                ]?.filter((o) => new Date(o?.expire_date) >= dates.startDate && new Date(o?.expire_date) <= dates.endDate)}
                // defaultData2={operations?.sort(
                //   (b, a) => new Date(b.expire_date) - new Date(a.expire_date)
                // )}

                columns={columns}
                rowClickHandler={(row) => {
                  navigate(
                    `/programming/${row.original.type}/${row.original.id}`
                  );
                }}
                isPagination={true}
                filterType={"operations"}
                isLegend={false}
                groupHandler={(data) =>
                  data?.filter(
                    (o) =>
                      !(
                        o?.repetitive &&
                        Number(o?.relative_id) > 1 &&
                        Number(o?.relative_id) > Number(o?.completed)
                      )
                  )
                }
              />
            </Suspense>
          </div>
        </div>
        // </div>
      );
    }
    return (
      <>
        {(operations.length > 0 || expiredOperations?.length > 0) && (
          <h5>{getLabel("programmedOperations")}</h5>
        )}
        <h5 className="alert_no_info margin_top">
          {getLabel("noOperationsFound")}
        </h5>
      </>
    );
  };

  const renderExpiredList = () => {
    if (expiredOperations.length > 0) {
      return (
        <div className="hub_primary_container_row">
          <div className="operations_list_table">
            <h5>{getLabel("expiredOperations")}</h5>
            <TableEdit
              isMobile={isMobile}
              type={"operation"}
              // defaultData={expiredOperations?.sort(
              //   (b, a) => new Date(b.expire_date) - new Date(a.expire_date)
              // )}
              defaultData={expiredOperations?.sort(function (a, b) {
                let adate = new Date(a?.expire_date),
                  bdate = new Date(b.expire_date),
                  rv = adate - bdate;
                if (rv === 0) {
                  rv = b?.priority - a?.priority;
                }
                return rv;
              })}
              columns={columns}
              rowClickHandler={(row) => {
                navigate(
                  `/programming/${row.original.type}/${row.original.id}`
                );
              }}
              filterType={"expiredOperations"}
              isLegend={false}
              groupHandler={(data) =>
                data?.filter(
                  (o) =>
                    !(
                      o?.repetitive &&
                      Number(o?.relative_id) > 1 &&
                      Number(o?.relative_id) > Number(o?.completed)
                    )
                )
              }
            />
          </div>
        </div>
      );
    }
    return;
  };

  const renderLegend = () => {
    return (
      <div>
        <h5>{getLabel("legend")}</h5>
        <ul>
          {/* <li><span>{getLabel("operationExpired")}</span><span className="icon">&#10071;</span></li> */}
          <li>
            <span>{getLabel("operationExpiringExact")}</span>
            <span className="icon">
              <FontAwesomeIcon icon="fa-calendar-day" />
            </span>
          </li>
          <li>
            <span>{getLabel("operationAnalysisToBeSampled")}</span>
            <span className="icon">
              <FontAwesomeIcon icon="fa-eye-dropper" />
            </span>
          </li>
          <li>
            <span>{getLabel("operationAnalysisSampled")}</span>
            <span className="icon">
              <FontAwesomeIcon icon="fa-flask-vial" />
            </span>
          </li>
          {!isMobile && (
            <li>
              <span>{getLabel("filtersOn")}</span>
              <span className="icon red">
                <FontAwesomeIcon
                  icon="fa-filter"
                  style={{ color: "rgba(243, 239, 245, 1)" }}
                  size={"xs"}
                />
              </span>
            </li>
          )}
          {!isMobile && (
            <li>
              <span>{getLabel("filtersOff")}</span>
              <span className="icon red">
                <FontAwesomeIcon
                  icon="fa-filter"
                  style={{ color: "rgba(243, 239, 245, 0.5)" }}
                  size={"xs"}
                />
              </span>
            </li>
          )}
          {isMobile && (
            <li>
              <span>{getLabel("operationExpired")}</span>
              <span className="operation expired">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </li>
          )}
          {isMobile && (
            <li>
              <span>{getLabel("operationProgrammed")}</span>
              <span className="operation">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className="hub_primary_container">
      <div className="hub_primary_container_wrapper">
        <SecondaryBar breadCrumb={[getLabel("programmingNavLink")]} />
        <div className="hub_primary_container_menu">
          {renderNavMenu()}
          {renderInterval()}
          {/* {renderExpiredList()} */}
          {renderList()}
        </div>
      </div>
      <div className="primary_container_lower legend">{renderLegend()}</div>
    </div>
  );
};

export default OperationsHub;
