import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons, toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import {
  selectLabels,
  selectNotificationsUnread,
} from "../../../context/selectors";
import useLabels from "../../../hooks/useLabels";
import { Switch } from "../../FormComponents";
import { toggleNotificationsUnreadGrid } from "../../../context/configs/configsSlice";
import { Button, Tooltip } from "../../Generic";
import "./styles/index.scss";
import "../../../components/Header/styles/index.scss";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { ROLES } from "../../../constants/base";
import useAuth from "../../../hooks/useAuth";
import { getOperations, markNotificationAsRead } from "../../../services/utils";
import { socket } from "../../../socket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const variants = {
  container: {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: -10,
      opacity: 0,
    },
  },
  content: {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
  item: {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  },
};

const NotificationsCenter = ({ isOpen, setCounter }) => {
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLabelsLoaded = useSelector(selectLabels);
  const showUnreadOnly = useSelector(selectNotificationsUnread);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;

  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    remove,
    unreadCount,
    add,
  } = useNotificationCenter({
    filter: (item) => item.data.exclude === false,
  });

  useEffect(() => {
    const delay = 5000; // Delay in milliseconds
  
    // Attach listener immediately when component mounts
    socket.on("notifications", (data) => onNotifications(data));
  
    // Delay handling notification fetching
    const timeout = setTimeout(() => {
      if (!isLabelsLoaded) {
        return; // Exit if labels are not loaded
      }
  
      // Fetch notifications after delay
      socket.emit("fetch notifications");
    }, delay);
  
    return () => {
      clearTimeout(timeout);
      socket.off("notifications"); // Cleanup listener on unmount
    };
  }, [isLabelsLoaded]); // Only re-run when isLabelsLoaded changes
  
  const onNotifications = (data) => {
    {
      // console.log("data", data);
      // Remove duplicate notifications
      const filteredNotifications = Object.values(
        data?.reduce((acc, curr) => {
          const key = `${curr.type}-${curr.operation_id}-${curr.from_user_id}`;
          if (!acc[key]) {
            acc[key] = curr;
          }
          return acc;
        }, {})
      );
  
      for (const notification of data) {
        const isLink =
          notification?.data?.operation_id &&
          notification?.data?.operation_type &&
          (notification.type === "NOTE" || notification.type === "NEW_OPERATION" || notification.type === "UPDATE_OPERATION")
            ? () => {
                markAsRead(notification.id);
                navigate(
                  `/programming/${notification?.data?.operation_type}/${notification?.data?.operation_id}`
                );
              }
            : null;

        const content = buildToastMessage(notification);
        toast.info(content, {
          position: toast.POSITION.BOTTOM_RIGHT,
          data: { exclude: false },
          toastId: notification?.id,
          ...(isLink && { onClick: isLink }),
        });
      }
    }
  }

  // Utility to check if two dates are on the same day
  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  useEffect(() => {
    // Listen for 'operation_completed' events
    socket.on("operation_completed", (data) => {
      const notification = {
        id: data.operationData.id,
        content: data.message,
        icon: Icons.success({ theme: "light" }),
        read: false,
        data: { exclude: false },
      };
      // add(notification);

      // Show a toast notification
      toast.success(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });

    // Clean up the socket connection
    return () => {
      socket.off("operation_completed");
    };
  }, []);

  const buildToastMessage = (notification) => {
    switch (notification?.type) {
      case "NOTE":
        const opTypeNewNote =
          getLabel(`operationType${notification?.data?.operation_type}`) || "-";
        const opExtendedIdNewNote = notification?.data?.operation_id || "";
        const headingNewNote = getLabel("newNoteFrom");
        return `${headingNewNote}: ${notification?.from_username} --> ${opTypeNewNote} ${opExtendedIdNewNote}`;

      case "NEW_OPERATION":
      case "DELETE_OPERATION":
      case "UPDATE_OPERATION":
        const opTypeNewOp =
          getLabel(`operationType${notification?.data?.operation_type}`) || "-";
        const opExtendedIdNewOp =
          notification?.data?.operation_id_extended || "";
        const headingNewOp = getLabel(
          notification?.type === "NEW_OPERATION"
            ? "programmingNewOp" :
          notification?.type === "DELETE_OPERATION"
          ? "programmingDeleteOp"
            : "programmingUpdateOp"
        );
        return `${headingNewOp}: ${notification?.from_username} --> ${opTypeNewOp} ${opExtendedIdNewOp}`;

      default:
        return null;
    }
  };

  // Daily report logic based on user role
  const isDailyReport = async () => {
    if (role === ROLES.CLIENT) {
      const startTime = new Date();
      startTime.setHours(0, 0, 0, 0);
      const completedOps =
        (await getOperations(axiosPrivate, true, null, startTime)) || [];
      if (completedOps?.length > 0) {
        const customIdDailyReport = "custom-id-daily-report";
        toast(getLabel("toast_dailyReport"), {
          toastId: customIdDailyReport,
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          icon: Icons.success({
            theme: "light",
            type: toast.TYPE.SUCCESS,
          }),
          position: toast.POSITION.BOTTOM_RIGHT,
          data: {
            exclude: false,
          },
        });
      }
    }
  };

  // Update unread counter when notifications change
  useEffect(() => {
    setCounter(unreadCount);
  }, [unreadCount, notifications]);

  // Schedule daily report notifications
  useEffect(() => {
    const endTime = new Date();
    endTime.setHours(19, 54, 30, 0);
    const timeout = endTime - new Date();

    if (role === ROLES.CLIENT && timeout > 0 && sameDay(endTime, new Date()))
      setTimeout(isDailyReport, timeout);
  }, [role]);

  // Toggle unread-only mode
  const toggleDisplayMode = () => {
    dispatch(toggleNotificationsUnreadGrid());
  };

  const readNotification = async (notification_id, isRemove = false) => {
    const response = await markNotificationAsRead(
      notification_id,
      axiosPrivate
    );

    if (response?.success) {
      if (isRemove) {
        remove(notification_id);
      } else {
        markAsRead(notification_id);
      }
    }
  };

  const deleteAll = async () => {
    notifications?.forEach(n => readNotification(n?.id, true));
  }

  return (
    <>
      {isOpen && (
        <motion.aside
          onClick={(e) => e.stopPropagation()}
          className="motion-aside-container"
          initial={false}
          variants={variants.container}
          animate={isOpen ? "open" : "closed"}
        >
          <header className="header">
            <h3>{getLabel("notifications")}</h3>
            <div className="unread-filter">
              <Switch
                negative={true}
                leftOption={{
                  label: showUnreadOnly
                    ? getLabel("notificationsShowUnread")
                    : getLabel("notificationsShowAll"),
                }}
                rightOption={{
                  label: "",
                }}
                toggleHandler={toggleDisplayMode}
                status={showUnreadOnly}
              />
            </div>
          </header>
          <AnimatePresence>
            <motion.section
              className="motion-section-content"
              variants={variants.content}
              animate={isOpen ? "open" : "closed"}
            >
              {(!notifications.length ||
                (unreadCount === 0 && showUnreadOnly)) && (
                <motion.h4
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {getLabel("notificationsNoItems")}
                </motion.h4>
              )}
              <AnimatePresence>
                {(showUnreadOnly
                  ? notifications.filter((v) => !v.read)
                  : notifications
                ).map((notification) => (
                  <motion.div
                    key={notification.id}
                    layout
                    initial={{ scale: 0.4, opacity: 0, y: 50 }}
                    exit={{
                      scale: 0,
                      opacity: 0,
                      transition: { duration: 0.2 },
                    }}
                    animate={{ scale: 1, opacity: 1, y: 0 }}
                    style={{ padding: "0.8rem" }}
                  >
                    <motion.article
                      className="motion-article-item"
                      key={notification.id}
                      variants={variants.item}
                    >
                      <div className="icon-wrapper">
                        {notification.icon ||
                          Icons.info({
                            theme: notification.theme || "light",
                            type: toast.TYPE.INFO,
                          })}
                      </div>
                      <div className="content">
                        <div>{notification.content}</div>
                      </div>
                      <div className="tools">
                        {(
                          // (notification?.data?.note_type === "NOTE" ||
                          // notification?.data?.note_type === "NEW_OPERATION") && 
                          notification?.read === true) ? (
                          <FontAwesomeIcon
                            size={"lg"}
                            icon="fa-check"
                            style={{ color: "#4bbd7f" }}
                          />
                        ) : (
                          <Tooltip
                            variant={"info"}
                            html={getLabel("notificationsMarkAsRead")}
                            place="left"
                            events={["hover"]}
                          >
                            <div
                              className="pulsating-dot"
                              onClick={() => readNotification(notification.id)}
                            >
                              <div className={`circle ${notification.type}`}>
                                <div
                                  className={`ring ${notification.type} `}
                                ></div>
                              </div>
                            </div>
                          </Tooltip>
                        )}
                        <Tooltip
                          variant={"info"}
                          html={getLabel("notificationsMarkAsRemoved")}
                          place="left"
                          events={["hover"]}
                        >
                          <div
                            className="remove"
                            onClick={() =>
                              readNotification(notification.id, true)
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-remove"
                              style={{ color: "#bb4456" }}
                              size={"lg"}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </motion.article>
                  </motion.div>
                ))}
              </AnimatePresence>
            </motion.section>
          </AnimatePresence>
          <footer className="footer">
            <div className="footer_wrapper">
              <Button
                isRed={true}
                isFull={true}
                label={getLabel("notificationsClearAll")}
                customClassName="sidebar_container_action"
                isNavLink={true}
                disableIcon={true}
                onClick={deleteAll}
              />
              <Button
                isRed={true}
                isFull={true}
                label={getLabel("notificationsMarkAll")}
                customClassName="sidebar_container_action"
                isNavLink={true}
                disableIcon={true}
                onClick={markAllAsRead}
              />
            </div>
          </footer>
        </motion.aside>
      )}
    </>
  );
};

export default NotificationsCenter;
