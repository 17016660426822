import React from "react";
import { Handle } from "react-flow-renderer";
import { TankIcon } from "../../Generic";
import { DestLotDetail } from "../../OperationsDetail/Details";
import useLabels from "../../../hooks/useLabels";

import './styles/index.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NodeWrapper = ({ data }) => {
  const [getLabel] = useLabels();

  if (!data) return;

  const { operation_type, tank, lot, treatments, handleIds } = data; // Expect handleIds as part of the node data

  return (
    <div className="node_wrapper">
      {operation_type !== undefined && <div className={`node_wrapper_header color_${(tank || lot || "")?.color?.toLowerCase()}`}>{getLabel(`operationType${operation_type}`)}</div>}
      {(operation_type === 'DESTEMMING' || operation_type === 'NEW_LOT') &&
        <DestLotDetail 
          lot={{
            label: lot?.source_batch_name,
            color: lot?.source_batch_color,
            varieties: lot?.source_batch_varieties,
            origins: lot?.source_batch_origins,
            years: lot?.source_batch_years,
            initial_quantity: lot?.source_batch_initial_quantity,
            quantity: lot?.source_batch_quantity,
          }}
          isRecap={true}
          isAdditional={true}
          isDestemming={true}
        />
      }
      {!['DESTEMMING', 'NEW_LOT', 'TREATMENT', 'ANALYSIS'].includes(operation_type) &&
        <TankIcon tank={{ ...tank, quantity2: tank?.source_batch_quantity }} />
      }
      {['TREATMENT', 'ANALYSIS'].includes(operation_type) && <div className="node_wrapper_treatment">
          <span className="icon"><FontAwesomeIcon size={"2xl"} icon="fa-flask-vial" color={treatments?.[0]?.color}/></span>
        </div>}
      <Handle
        type="target"
        position="top"
        id={handleIds?.target} // Dynamic target handle ID
        style={{ background: "#555",  opacity: (operation_type === 'DESTEMMING' || operation_type === 'NEW_LOT') ? 0 : 100 }} // opacity is a trick to hide the handle without impacting the layout
        isConnectable={false} // Disable manual connections for source
      />
      <Handle
        type="source"
        position="bottom"
        id={handleIds?.source} // Dynamic source handle ID
        style={{ background: "#555" }}
        isConnectable={false} // Disable manual connections for source
      />
    </div>
  );
};

export default NodeWrapper;
