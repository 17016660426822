import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useLabels from "../../hooks/useLabels";

const EditCell = ({ row, column, table }) => {
  const [getLabel] = useLabels();
  const meta = table.options.meta;
  const columnMeta = column.columnDef.meta;
  const isWarning = ["PROTOCOL"].includes(row.original?.opType);
  const isDisabled = isWarning ? false : 
    meta.getErrors()?.column?.length > 0 && meta.getErrors()?.column[row.id]; //column[row.original.id || W || row.id];

  const isEdit = columnMeta?.isEditable === true;
  const isOnlyManual = columnMeta?.isOnlyManual === true;
  const isRepetitiveValid =
    ["ANALYSIS", "TREATMENT", "PROTOCOL", "WINEMAKINGPROTOCOL"].includes(
      row.original?.opType
    ) &&
    (!row.original?.repetitive ||
      (row.original?.repetitive && row.original?.isEditable));
    const [id, setId] = useState(row.original.substance_id || row.original.value);

  const setWineMakingRowData = () => {
    
    meta?.setValue("day", row.original?.day);
    meta?.setValue("phase", {
      label: getLabel(`protocolPhase_${row.original?.phase}`),
      value: row.original?.phase,
    });
    meta?.setValue("operation", {
      label: getLabel(`protocolOperation_${row.original?.operation}`),
      value: row.original?.operation,
    });
    
    if (Number(row?.original?.toDay) > 0) {
      meta?.setValue("toDay", row?.original?.toDay);
      meta?.setValue("interval", true);
    } else {
      meta?.setValue("toDay", "");
      meta?.setValue("interval", false);  
    }

    if (row.original?.operation === "HOMOGENIZATION_MASS") {
      meta?.setValue("protocolType", {
        label: getLabel(`protocolOperation_${row.original?.protocolType}`),
        value: row.original?.protocolType || "",
      });
    }

    if (
      !["OXYGENATION", "DECANT", "RACKING", "HOMOGENIZATION_MASS"].includes(
        row.original?.operation
      )
    ) {
      if (row?.original?.operation !== "SO2") {
        meta?.setValue("product", {
          // label: row.original?.product_name,
          label: row.original?.product_name + (row.original?.product_unit ? " (" + row.original?.product_unit + ")" : ""),
          value: row.original?.product,
          id: row.original?.product,
          name: row.original?.product_name,
          unit: row.original?.product_unit,
          protocolType: "ADD"
        });
      } else {
        meta?.setValue("product", getLabel("protocolProduct_SO2") + " (g/hL)");
      }
      meta?.setValue("quantity", row?.original?.quantity);
    }
    if (
      row?.original?.operation === "HOMOGENIZATION_MASS" &&
      "HOMOGENIZATION_TEMP" === row.original?.protocolType
    ) {
      meta?.setValue("homogenizationType", {
        label: getLabel(
          `protocolHomogenizationMass_${row.original?.homogenizationType}`
        ),
        value: row.original?.homogenizationType,
      });
      meta?.setValue("homogenizationTemp", row.original?.quantity);
    }
    if (
      row?.original?.operation === "HOMOGENIZATION_MASS" &&
      "HOMOGENIZATION_REASSEMBLY" === row.original?.protocolType
    ) {
      meta?.setValue("homogenizationType", {
        label: getLabel(
          `protocolHomogenizationMass_${row.original?.homogenizationType}`
        ),
        value: row.original?.homogenizationType,
      });
      meta?.setValue("homogenizationWorkTime", row.original?.workTime);
      meta?.setValue(
        "homogenizationPauseTime",
        row.original?.homogenizationPauseTime
      );
      meta?.setValue(
        "homogenizationMotorSpeed",
        row.original?.homogenizationMotorSpeed
      );
      meta?.setValue("homogenizationIterPerDay", row.original?.iterPerDay);
    }
    
    if ("OXYGENATION" === row.original?.operation) {
      meta?.setValue("oxigenationType", {
        label: getLabel(`protocolOXYGENATION_${row.original?.protocolType}`),
        value: row.original?.protocolType,
      });
      meta?.setValue("oxygenationDose", row.original?.quantity);
      if ("CUSTOM" === row.original?.protocolType) {
        meta?.setValue("oxygenationDoseHours", row.original?.oxygenationDoseHours);
        meta?.setValue("oxygenationDoseMinutes", row.original?.oxygenationDoseMinutes);
      }    
      // meta?.setValue(
      //   "oxygenationIterPerDay",
      //   row.original?.iterPerDay
      // );
    }
    meta?.setValue("goal", row.original?.goal);
    meta?.setValue("note", row.original?.note);
    
    meta.setModifyMode(row.id);
    meta.modalHandler();
  };

  const setEditedRows = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if ("winemaking" === meta.type) {
      setWineMakingRowData();
      return;
    }

    const elName = e.currentTarget.name;
    if (isDisabled && elName === "done") return;
    
    meta?.setEditedRows((old) => ({
      ...old,
      [row.id]: !old[row.id],
    }));

    if (elName !== "edit") {
      meta?.revertData(row.index, elName === "cancel", ["ANALYSIS", "TREATMENT", "PROTOCOL", "WINEMAKINGPROTOCOL"].includes(
        row.original?.opType
      ) && elName === "cancel" ? id : row.index);
    } else if (elName !== "cancel") {
      meta?.revertData(row.index, elName === "cancel");
    }
    e.stopPropagation();
  };

  const removeRow = () => {
    meta?.removeRow(row.index);
  };
  
  if (isEdit && isOnlyManual && row.original?.protocolType === 'MANUAL') {
    return (
      <div className="edit-cell-container">
        <div className="edit-cell-action">
          <button onClick={setEditedRows} name="edit">
          <FontAwesomeIcon icon="fa-gear" />
          </button>
      </div>
    </div>
    )
  } else if (isOnlyManual) return;

  return (
    (isRepetitiveValid || row.original?.opType === 'PROTOCOL') && (
      <div className="edit-cell-container">
        {meta?.editedRows[row.id] ? (
          <div className="edit-cell-action">
            <button onClick={setEditedRows} name="cancel">
              −
            </button>{" "}
            <button
              onClick={setEditedRows}
              name="done"
              style={isDisabled ? { cursor: "not-allowed" } : {}}
              disabled={isDisabled}
            >
              ✔
            </button>
          </div>
        ) : (
          <div className="edit-cell-action">
            {/* {isEdit && row.original?.opType !== 'PROTOCOL' && ( */}
            {isEdit && (
              <button onClick={setEditedRows} name="edit">
                {!["ANALYSIS"].includes(row.original?.opType) ? (
                  "✐"
                ) : <FontAwesomeIcon icon="fa-flask-vial" />
                }
                {/* {!["ANALYSIS", "TREATMENT"].includes(row.original?.opType) ? (
                  "✐"
                ) : "ANALYSIS" === row.original?.opType ? (
                  <FontAwesomeIcon icon="fa-flask-vial" />
                )
                 : (
                  <FontAwesomeIcon icon="fa-prescription-bottle" />
                )} */}
              </button>
            )}
            {!["ANALYSIS", "TREATMENT"].includes(row.original?.opType) && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeRow();
                }}
                name="remove"
              >
                X
              </button>
            )}
          </div>
        )}
        {!["ANALYSIS", "TREATMENT"].includes(row.original?.opType) && (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        )}
      </div>
    )
  );
};

export default EditCell;
