import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import { InfoCard } from "../Generic";
import {
  selectDrugs,
} from "../../context/selectors";
import { getDrugs } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrugs } from "../../context/substances/drugsSlice";

import additives from "../../assets/icons/additives.png";
import adjuvants from "../../assets/icons/adjuvants.png";
import { SkeletonTableEdit } from "../Skeleton/Skeleton";

const SubstanceWarehouseList = lazy(() => import("../SubstanceWarehouseList"));

const Warehouse = ({ isMobile }) => {
  const [getLabel] = useLabels();

  const substances = useSelector(selectDrugs);
  const [substancesLen, setSubstancesLen] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const loadSubstances = async () => {
    const currentSubstances = await getDrugs(axiosPrivate);
    dispatch(setDrugs(currentSubstances));
  };

  useEffect(() => {
    if (!substances || substances.length === 0) {
      loadSubstances();
    }
  }, []);

  const renderHeader = () => {
    return (
      <div className="primary_container_menu">
        <h2>{getLabel("warehouse")}</h2>
      </div>
    );
  };

  useEffect(() => {
    if (!substances || substances?.length === 0) return;
    const tmp = {};
    tmp.tot = substances?.length || 0;
    const additive = substances?.filter((t) => t?.type === "ADDITIVE");
    const adjuvant = substances?.filter((t) => t?.type === "ADJUVANT");
    tmp.additive = additive?.length;
    tmp.adjuvant = adjuvant?.length;
    
    setSubstancesLen(tmp);
  }, [substances]);

  const renderDrugsNavMenu = () => {
    return (
      <div className="primary_container_menu">
        <div>
          <h5 className="tanks_title">
            {getLabel("numTotSubstances")}
            <span className="tanks_span">{substancesLen?.tot || "0"}</span>
          </h5>
        </div>
        <div className={`tanks_wrapper`}>
          <div className="tanks_recap">
            <div className="tanks_recap_wrapper">
              <InfoCard
                label={getLabel("numTotAdjuvants")}
                number={substancesLen?.adjuvant}
                img={isMobile || true ? adjuvants : null}
              />
              <InfoCard
                label={getLabel("numTotAdditives")}
                number={substancesLen?.additive}
                img={isMobile || true ? additives : null}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[getLabel("warehouseNavLink")]}
        isBasePathNeeded={true}
      />
      {renderHeader()}
      <div>
        {/* {renderDrugsNavMenu()} */}
        <Suspense fallback={<SkeletonTableEdit colCount={3} />}>
          <SubstanceWarehouseList
            substances={substances}
            isMobile={isMobile}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default Warehouse;
