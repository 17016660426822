import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { INT_FLOAT_REGEX } from "../../constants/regex";
import useLabels from "../../hooks/useLabels";
import { Input, Select, Switch } from "../FormComponents";
import { Tooltip, CTA } from "../Generic";
import RangeSlider from "../RangeSlider";
import "./styles/index.scss";

const TableCell = ({ getValue, row, column, table }) => {
  const [getLabel] = useLabels();
  const initialValue = getValue();

  const columnMeta = column.columnDef.meta;
  const additional = columnMeta?.additional;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);
  const [substance_id, setSubstanceId] = useState(Number(row.original.id || row.original.substance_id || row.original.value));
  const errors = tableMeta.getErrors();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  const onSelectChange = (e) => {
    setValue(e.target.value);
    tableMeta?.updateData(row.index, column.id, e.target.value);
  };

  const getError = () => {
    if (!errors || !errors?.length === 0 || Object.keys(errors)?.length === 0)
      return;
    
    return errors?.column?.[row.id]?.value;
  };

  const renderExactDay = () => {
  }

  const renderAdditional = () => {
    switch (additional) {
      case "examColor":
        if (row.original?.isShowFirstLineData) return <span className="additional">{row.original?.color}</span>
        return null;;
      case "unit":
        if (row.original?.unit) return <span className="additional">{row.original?.unit}</span>
        return null;;
      default:
        return null;
    }
  }

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "rgb(249, 213, 115)";
      case "ROSE":
        return "rgb(199, 79, 89)";
      default:
        return "#64242e";
    }
  };
  
  const getState = (state) => {
    switch (state) {
      case "GRAPE":
        return "fa-cubes-stacked";
      case "MUST":
        return "fa-cubes-stacked";
      case "WINE":
        return "fa-droplet";
      case "BOTTLED":
        return "fa-wine-bottle";
      default:
        return "fa-wine-bottle";
    }
  };

  const getTankType = (type) => {
    switch (type) {
      case "TANK":
        return "fa-cubes-stacked";
      case "AUTOCLAVE":
        return "fa-cubes-stacked";
      case "TONNEAU":
        return "fa-droplet";
      case "BARRIQUE":
        return "fa-wine-bottle";
      default:
        return "#64242e";
    }
  };
  
  const getIcon = (iconType) => {
    const icon = { icon: iconType, style: {} };
    switch(iconType) {
      case 'fa-droplet': 
        if (row.original?.color) icon.style.color = getWineColor(row.original?.color); 
        break;
  
      case 'state':
      case 'stateWithColor':
      //   if (row.original?.state) icon.icon = getState(row.original.state); 
      //   if (row.original?.state === 'BOTTLED') icon.style.transform = 'rotate(-45deg)';
      //   if (iconType === 'stateWithColor' && row.original?.color) icon.style.color = getWineColor(row.original.color);
        icon.icon = null;
        break;
      
      // case 'tankType':
      //   if (row.original?.type) icon.icon = getTankType(row.original?.type);
      //   break;
    }

    return icon;
  }

  const renderIcon = () => {
    if (columnMeta?.iconType) {
      const icon = getIcon(columnMeta.iconType);
      if (icon?.icon)
        return <span>{" "}<FontAwesomeIcon icon={icon.icon} style={icon.style} size="xs" /></span>
    }
  }

  const getBatchDataOptions = () => {
    const options = columnMeta?.options?.filter(item => row.original?.batches?.map(batch => batch?.batch_number).includes(item?.value));
    options.unshift({ label: '', value: '' });
    return options?.map(o => { return { ...o, substance_id: substance_id}}) || [];
  }

  const selectRow = () => {
    setValue((prev) => !prev);
    tableMeta?.selectRow(row.index, !value);
  };

  const getExamStatus = ({ min_range, max_range, results }) => {
    const minValue = min_range !== undefined ? min_range : 0.01; // Default min is 0.01
    const maxValue = max_range !== undefined ? max_range : Infinity; // Default max is Infinity
    return (results >= minValue && results <= maxValue) ? "is_ok" : "is_warning";
  };

  if (columnMeta?.type === 'switch') {
    return <Switch
      leftOption={{
          label: getLabel('listView')
      }}
      rightOption={{
          label: getLabel('gridView')
      }}
      // toggleHandler={toggleDisplayMode}
      // status={displayModeGrid}
    />
  } else if (columnMeta?.type === 'checkbox') {
    return <input
      type="checkbox"
      checked={value}
      // checked={row.getIsSelected()}
      // onChange={row.getToggleSelectedHandler()}
      onChange={() => selectRow()}
    />
  } else if (columnMeta?.type === 'email_validity') {
    return (<>
      <span>{value ? getLabel('userEmailVerified') : getLabel('userEmailUnverified')}</span>
      {!value && <span style={{marginLeft: '10px'}}>
      <CTA onClick={e => {console.log('Resend e-mail...'); e.stopPropagation();}}>
        {getLabel('userEmailResendInvite')}
      </CTA>
      </span>}
    </>
    )
  } else if (columnMeta?.type === 'remove_cookie') {
    return (<>
      {value === true && 
      <CTA onClick={columnMeta?.onClickHandler}>
        {getLabel('cookie_settings_mandatory_viewed_cookie_policy_deny')}
      </CTA>}
      {!value && <span>{getLabel("cookie_settings_mandatory_no_deny")}</span>}
    </>
    )
  } else if (columnMeta?.type === 'select' && tableMeta?.type === 'treatment') {
    if (getBatchDataOptions()?.length <= 1) return  <p>{getLabel("drugNoBatches")}</p>
    else return (
      <Select
        name={`drug_batch.${row.id}`}
        control={columnMeta?.control}
        placeholder={getLabel("drugBatchNumbersPlaceholder")}
        // error={errors["drug_batch"]}
        // error={errors}
        options={getBatchDataOptions()}
        isSearchable={true}
        isLabel={false}
        defaultValue={{
          value: "",
          label: "",
        }}
      />
    )
  } else if (columnMeta?.isCompleted && row.original?.opType === "ANALYSIS" && row.original?.results){// && ([7].includes(Number(row.id)))) {
    return <RangeSlider
      min={row.original?.min_range}
      max={row.original?.max_range} 
      current={row.original?.results?.find(r => r?.tank_id === row.original?.tank_id && r?.substance_id === row.original?.substance_id)?.value || ""}
      unit={row.original?.unit}
    />
  } else if (
    (tableMeta?.editedRows[row.id] && columnMeta?.editable) ||
    (tableMeta?.analysisState === "INSERTING" &&
      Number(row.id) >= tableMeta?.minEditableIndex &&
      Number(row.id) < tableMeta?.maxEditableIndex &&
      columnMeta?.editable)
  ) {
    return columnMeta?.type === "select" ? (
      <select onChange={onSelectChange} value={initialValue || ""}>
        {columnMeta?.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <Input
        onChange={(e) => {
          if (row.original?.opType === "ANALYSIS") {
            const tank_id = (row.original?.tank_id) ? row.original?.tank_id : row.original?.batch_id;
            
            if (tank_id) {
              const value = Number(e.target.value);
              // get the current array of substances for the specific tank_id
              const currentSubstances = tableMeta.getValues(`column.tanks`) || [];

              // check if the substance already exists
              const existingSubstanceIndex = currentSubstances?.findIndex(
                (substance) => substance.substance_id === substance_id && substance.tank_id === tank_id
              );
              
              let updatedSubstances;
              if (existingSubstanceIndex === -1) {
                // create a new entry if it doesn't exist
                const newEntry = { substance_id: Number(substance_id), value: value, tank_id: tank_id };
                updatedSubstances = [...currentSubstances, newEntry];
                console.log('newEntry', newEntry, 'tank_id', tank_id);
              } else {
                // update the existing entry
                updatedSubstances = currentSubstances.map((substance, index) => 
                  index === existingSubstanceIndex 
                    ? { ...substance, value } // update the value
                    : substance // keep the existing substance
                );
              }

              // set the updated array back into the form state
              tableMeta.setValue(`column.tanks`, updatedSubstances);
            }
          } else {
            tableMeta.setValue(`column.${substance_id}.old`, e.target.defaultValue);
            tableMeta.setValue(`column.${substance_id}.new`, e.target.value);
            tableMeta.setValue(`column.${substance_id}.substance_id`, row.original?.value || row.original?.substance_id);
          }
          setValue(columnMeta?.noStoreValue ? '' : (`${e.target.value || row.original?.quantity}`));
        }}
        onBlur={onBlur}
        name={`column.${row.id}.value`}
        register={columnMeta?.register}
        isLabel={false}
        type={columnMeta?.type || "text"}
        required={getLabel("inputRequiredError")}
        customClassName={`quantity`}
        placeholder={
          row.original?.opType === "ANALYSIS"
            ? getLabel("examResultPlaceholder")
            : getLabel("drugQuantityPlaceholder")
        }
        // defaultValue={row.original?.opType === 'PROTOCOL' ? parseInt(value, 10) : value}
        defaultValue={value}
        error={getError()}
        regExpPattern={{
          value: INT_FLOAT_REGEX,
          message: getLabel("errorIntFloat"),
        }}
        isWarning={["ANALYSIS", "PROTOCOL"].includes(row.original?.opType)}
        validate={{
          value: (value) => {
            const numericValue = parseFloat(value);
            if (numericValue === 0) {
              return getLabel("errorDrugQuantityZero");
            }
            if (numericValue < (row.original?.min_range || 0.01)) {
              return row.original?.opType === "ANALYSIS" ? getLabel("errorExamQuantityUnderMin") : getLabel("errorDrugQuantityUnderMin");
            }
            return true; // return true if no errors
          },
        }}
        min={{
          value: row.original?.min_range?.toString() || 0.01,
          message: row.original?.opType === "ANALYSIS" ? getLabel("errorExamQuantityUnderMin") : getLabel("errorDrugQuantityUnderMin"),
        }}
        max={{
          value: row.original?.max_range?.toString() || null,
          message: row.original?.opType === "ANALYSIS" ? getLabel("errorExamQuantityOverMax") : getLabel("errorDrugQuantityOverMax"),
        }}
      />
    );
  }
  
  return (column?.id?.startsWith('note') || column?.id === 'goal') ? (
    <Tooltip
      variant={"info"}
      html={value}
      place="left"
      events={["hover"]}
    >
      <span className={'note'}>
        {value}
      </span>
    </Tooltip>
  ) : (
  <span
    className={`${column?.id === getLabel("examResult") ? `quantity ${getExamStatus(row.original)}` : ''} 
    ${(columnMeta?.customClassName === 'hide' && !row.original?.isTankFirst) ? 'hide' : ''}
    ${columnMeta?.colorExpired && row.original?.expire_date && new Date(row.original?.expire_date) <= new Date() ? 'expired' : ''}`}
  >
    {renderIcon()}{" "}<span className={`${(columnMeta?.customClassName === 'hide' && !row.original?.isTankFirst) ? 'hide' : columnMeta?.customClassName2 || ''}`}>{value}</span>{renderExactDay()}{renderAdditional()}
  </span>);
};

export default TableCell;
