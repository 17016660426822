import { useFieldArray, useWatch } from "react-hook-form";
import { DatePicker, Input, MultipleSelect, Select } from "../FormComponents";
import useLabels from "../../hooks/useLabels";
import { empty, setOperations } from "../../context/operations/operationsSlice";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getOperations,
  getUsers,
  newOperation,
  updateOperation,
} from "../../services/utils";
import { ConfirmationModal, OperationRecap, TankIcon } from "../Generic";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_REGEX,
} from "../../constants/regex";
import {
  selectConfigs,
  selectOperations,
  selectUser,
  selectUsers,
} from "../../context/selectors";
import { addMinutes, addMonths } from "date-fns";
import { toast } from "react-toastify";
import { emptyLots } from "../../context/lots/lotsSlice";
import { setUsers } from "../../context/users/usersSlice";
import { useEffect, useState } from "react";
import { DestTankDetail } from "../OperationsDetail/Details";

const NewLotCreationForm = ({
  operation,
  lots,
  activeWinery,
  tanks,
  state,
  handleCloseModal,
  register,
  control,
  errors,
  setError,
  clearErrors,
  getValues,
  setValue,
  varieties,
  isOpen,
  isMobile,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const operations = useSelector(selectOperations);
  const configs = useSelector(selectConfigs);
  const [isExactDate, setIsExactDate] = useState(false);

  useEffect(() => {
    if (operations?.length === 0) {
      loadOperations();
    }
    if (users?.length === 0) {
      loadUsers();
    }
  }, []);

  const { dest_tanks } = useWatch({ control });

  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "dest_tanks",
  });

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers));
  };

  useEffect(() => {
    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      // const chosenQuantity = operation?.dest_tanks?.reduce((acc, curr) => {
      //   return acc + (Number(curr?.quantity) || 0);
      // }, 0);
      // setValue("batch_id_in_quantity", chosenQuantity);

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      // setValue("batchType", {
      //   label: getLabel(`lotType${operation?.batch_type}`),
      //   value: operation?.batch_type,
      // });

      const dests = [];
      operation?.dest_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === s.tank_id);
        dests.push({
          quantity: s?.quantity,
          tankData: {
            value: tank?.id,
            name: tank?.name,
            label: tank?.name,
            state: tank?.state,
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            availability: Number(tank?.capacity) - Number(tank?.quantity),
            color: tank?.color,
            lot:
              lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
              getLabel("tankState_EMPTY"),
          },
          new_batch_name: s?.new_batch_type === "NEW" ? s?.new_batch_name : "",
          batch_name_selector:
            s?.new_batch_type === "NEW"
              ? {
                  value: true,
                  label: getLabel("lotNewName"),
                }
              : s?.new_batch_type === "INHERITED"
              ? {
                  value: false,
                  label: s?.new_batch_name,
                }
              : {
                  value: false,
                  label: s?.new_batch_name,
                },
        });
      });
      replace(dests);

      // setNewBatchNames(computeLotNames());

      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      setValue("expire_date", operation?.expire_date);

      setValue("note", operation?.note);
    }
  }, [operations, operation, lots]);

  useEffect(() => {
    const actual_state = dest_tanks?.find((t) => t?.tankData?.state !== null)
      ?.tankData?.state;
    if (actual_state !== state && actual_state !== undefined) {
      replace([]);
    }
  }, [state]);

  const computeLotNames = (isValidate = false) => {
    const names = new Set();
    if (getValues("name")) names.add(getValues("name"));

    // shows only 6 months old must batches. TO DO: what if a batch of the previous year has the same name?? I hope it is deactivated
    lots
      ?.filter(
        (l) =>
          l?.state === "MUST" &&
          new Date(l?.insertion_date) >= addMonths(new Date(), -6)
      )
      ?.reduce((_, l) => names.add(l?.name), null);

    if (dest_tanks?.length === 1 && dest_tanks?.[0]?.tankData?.value === null)
      return names;
    dest_tanks
      ?.filter((tank) => tank?.tankData?.quantity > 0)
      ?.reduce((_, item) => names.add(item?.tankData?.lot), null);

    if (!isValidate) {
      for (const tank of dest_tanks) {
        if (tank?.new_batch_name?.length > 0) names.add(tank?.new_batch_name);
      }
      let i = 0;
      for (const tank of dest_tanks?.filter(
        (t) => t?.tankData?.value && "batch_name_selector" in t
      )) {
        if (
          tank?.batch_name_selector?.value === false &&
          !Array.from(names.values())?.includes(
            tank?.batch_name_selector?.label
          )
        ) {
          setValue(`dest_tanks.${i}.batch_name_selector`, {
            value: "",
            label: "",
          });
        }
        i += 1;
      }
    }

    const nameObjects = [];
    names?.forEach((s) => {
      if (s) nameObjects.push({ value: false, label: s });
    });

    return nameObjects?.sort((a, b) =>
      a?.label > b?.label ? 1 : b?.label > a?.label ? -1 : 0
    );
  };

  const customIdInvalidLotName = "custom-id-invalid-lot-name";

  const sendData = async () => {
    if (Object.keys(errors)?.length > 0) return;
    const data = getValues();

    // new lot data
    const total =
      varieties?.length === 1
        ? 100
        : varieties?.reduce((acc, curr) => {
            return acc + (Number(curr?.quantity) || 0);
          }, 0);
    if (total !== 100) {
      varieties?.forEach((v, index) =>
        setError(`varieties.${index}`, {
          type: "manual",
          message: getLabel("errorWinePercentage100"),
        })
      );
    } else {
      varieties?.forEach((v, index) => clearErrors(`varieties.${index}`));
    }
    if (errors?.length > 0) return;

    if (!id && lots?.find((l) => l?.name === data?.name?.trim())) {
      toast.error(getLabel("toast_lotNameNotAvailable"), {
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
      });
      return;
    }

    const newLotRequestPayload = {
      // ...data,
      name: data?.name,
      quantity: data?.quantity,
      varieties: data?.varieties?.map((v) => v?.label),
      years: [data?.years],
      origins: [data?.origins],
      state: data?.state?.value || "GRAPE",
      type: "",
      unit_id: 4,
      label: data?.label?.map((v) => v?.label),
      color: data?.color?.value || "RED",
      quality: data?.quality?.value || "LOW",
      winery_id: activeWinery.id,
      ...(data?.doco && {
        is_doco: true,
        doco_id: data?.doco_id,
        doco_date: addMinutes(
          new Date(data?.doco_date)?.setHours(23, 59, 59),
          -new Date(data?.doco_date).getTimezoneOffset()
        ).toISOString(),
      }),
      ...(id && { id: id }),
    };
    // console.log('newLotRequestPayload', newLotRequestPayload);
    // return;

    // new tanks organization
    const dests = [];

    const newBatchIds = [];
    const sorted_dest_tanks = data?.dest_tanks?.sort(function (a, b) {
      return b?.batch_name_selector?.value - a?.batch_name_selector?.value;
    })?.filter(
      (d) =>
        d?.tankData?.value &&
        d?.quantity &&
        ((d?.batch_name_selector?.value === true && d?.new_batch_name) ||
          (d?.batch_name_selector?.value === false &&
            d?.batch_name_selector?.label))
    );
    // console.log(sorted_dest_tanks)
    sorted_dest_tanks
      ?.forEach((destT, i) => {
        // const type =
        // ((destT?.batch_name_selector?.value === true && destT?.new_batch_name?.length > 0 && !newBatchIds?.includes(destT?.new_batch_name)) || (destT?.batch_name_selector?.value === false && destT?.batch_name_selector?.label?.length > 0 && destT?.batch_name_selector?.label === data?.name)) ? 'NEW' :
        // (destT?.batch_name_selector?.value === false && destT?.new_batch_name?.length === 0 && newBatchIds?.includes(destT?.batch_name_selector?.label)) ? 'INHERITED' : 'OLD';

        // if (destT?.batch_name_selector?.value === true && destT?.new_batch_name?.length > 0 && !newBatchIds?.includes(destT?.new_batch_name)) newBatchIds.push(destT?.new_batch_name)
        // console.log(destT?.batch_name_selector, '----', destT?.new_batch_name)
        // return false;
        const type =
          ((destT?.batch_name_selector?.value === true &&
          destT?.new_batch_name?.length > 0 &&
          !newBatchIds?.includes(destT?.new_batch_name) &&
          lots?.find(
            (l) =>
              (l?.name === destT?.new_batch_name &&
                destT?.batch_name_selector?.value === true) ||
              (l?.name === destT?.batch_name_selector?.label &&
                destT?.batch_name_selector?.value === false)
          ) === undefined) || 
          (data?.name === destT?.batch_name_selector?.label && 
            destT?.batch_name_selector?.value === false && 
            !destT?.new_batch_name &&
            !newBatchIds?.includes(destT?.batch_name_selector?.label)))
            ? "NEW"
            : (destT?.batch_name_selector?.value === false &&
                destT?.new_batch_name?.length === 0 &&
                newBatchIds?.includes(destT?.batch_name_selector?.label)) ||
              newBatchIds?.includes(destT?.batch_name_selector?.label)
            ? "INHERITED"
            : (lots?.find(
                (l) =>
                  (l?.name === destT?.new_batch_name &&
                    destT?.batch_name_selector?.value === true) ||
                  (l?.name === destT?.batch_name_selector?.label &&
                    destT?.batch_name_selector?.value === false)
              ) ===
                undefined) !==
              undefined
            ? destT?.new_batch_name && lots?.find((l) => l?.name === destT?.new_batch_name) ===
                undefined &&
              !destT?.batch_name_selector?.value &&
              destT?.batch_name === undefined
              ? "NEW" // lot not existing yet but created in another operation
              : "OLD"
            : null;
        // console.log('lotNames', lotNames) // TO DO va messo il check nel 2o "NEW"

        if (type === null) {
          toast.error(getLabel("toast_invalidType"), {
            toastId: customIdInvalidLotName,
            position: toast.POSITION.BOTTOM_RIGHT,
            exclude: true,
            autoClose: 5000,
          });
          return;
        }
        
        // if (
        //   destT?.batch_name_selector?.value === true &&
        //   destT?.new_batch_name?.length > 0 &&
        //   !newBatchIds?.includes(destT?.new_batch_name)
        // )
        const newName = destT?.batch_name_selector?.value === true ? destT?.new_batch_name : destT?.batch_name_selector?.label;
        if (type === "NEW" && !newBatchIds?.includes(newName)) {
          newBatchIds.push(newName);
          // TO DO check all cases
        }

        const dest = {
          tank_id: destT?.tankData?.value,
          quantity: Number(destT.quantity),
          new_batch_name: (destT?.batch_name_selector?.value === true
            ? destT?.new_batch_name
            : destT?.batch_name_selector?.label
          )?.trim(),
          new_batch_type: type,
          old_batch_id:
            tanks?.find((t) => t.id === destT?.tankData?.value)?.batch_id || "",
          old_quantity:
            tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity || 0,
          new_quantity:
            (Number(
              tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity || 0
            ) || 0) + (Number(destT.quantity) || 0),
        };
        dests.push(dest);
      });

    if (dests?.length === 0) {
      toast.error(getLabel("toast_missingDestTanks"), {
        toastId: customIdInvalidLotName,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
        autoClose: 5000,
      });
      return;
    }

    const tmpDate = new Date(data?.expire_date)?.setHours(23, 59, 59);

    const requestPayload = {
      dest_tanks: dests,
      priority: data?.priority?.value,
      state: data?.state?.value,
      is_exact_date: isExactDate,
      // expire_date: addMinutes(
      //   new Date(data?.expire_date)?.setHours(23, 59, 59),
      //   -new Date(data?.expire_date).getTimezoneOffset()
      // ).toISOString(),
      expire_date: new Date(tmpDate)?.toISOString(),
      type: "NEW_LOT",
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      note: data?.note,
      src_tanks: [newLotRequestPayload],
    };
    console.log("requestPayload", requestPayload);
    // return;

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    let response = null;
    if (id) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    console.log(response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      updateStore();
      navClickHandler();
    }
  };

  const getTotalQuantity = () => {
    return (
      dest_tanks?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0) || 0
    );
  };

  const updateStore = () => {
    dispatch(empty());
    dispatch(emptyLots());
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/NEW_LOT/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const getError = (idx, data, key) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const getTankData = (t) => {
    const tank = t?.tankData;
    const quantity =
      tank?.availability >= Number(t?.quantity) && Number(t?.quantity) >= 0
        ? Number(t?.quantity)
        : 0;
    const new_batch_name =
      t?.batch_name_selector?.value === true && t?.new_batch_name
        ? t.new_batch_name
        : t?.batch_name_selector?.value === false
        ? t?.batch_name_selector?.label
        : "";

    const tankData = {};
    tankData.arrow_quantity = quantity;
    tankData.capacity = tank.capacity;
    tankData.old_quantity = tank.quantity;
    tankData.old_batch_name = tank.lot || "";
    tankData.quantity = tank.quantity + quantity;
    tankData.color = tank?.color;
    tankData.batch_name = new_batch_name;

    return tankData;
  };

  return (
    <div className="operation_form_wrapper">
      <div className="operation_form_wrapper_row vertical">
        <h6>{getLabel("toTanks")}</h6>
        {fields?.map?.((field, idx) => {
          return (
            <div key={field.id} className="operation_form_wrapper_row">
              <Select
                label={getLabel("operationDestTankName")}
                placeholder={getLabel("operationDestTanksPlaceholder")}
                error={errors}
                required={getLabel("inputRequiredError")}
                name={`dest_tanks.${idx}.tankData`}
                control={control}
                options={tanks
                  ?.filter(
                    (tank) =>
                      (tank?.state === state || tank?.quantity === 0) &&
                      !["BARRIQUE", "TONNEAU"].includes(tank?.type) &&
                      !dest_tanks?.some?.(
                        (field) => field.tankData.value == tank.id
                      ) &&
                      (tank?.state === state ||
                        Number(tank?.quantity || 0) === 0)
                  )
                  ?.sort((a, b) =>
                    (a?.name).localeCompare(b?.name, undefined, {
                      numeric: true,
                    })
                  )
                  ?.map?.((tank) => {
                    return {
                      value: tank?.id,
                      label: tank?.name,
                      name:
                        tank?.name +
                        ` (${
                          lots?.find((lot) => lot?.id === tank?.batch_id)
                            ?.name || getLabel("tankState_EMPTY")
                        })`,
                      state: tank?.state,
                      capacity: Number(tank?.capacity),
                      quantity: Number(tank?.quantity),
                      availability:
                        Number(tank?.capacity) - Number(tank?.quantity),
                      color: tank?.color,
                      lot:
                        lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
                        getLabel("tankState_EMPTY"),
                    };
                  })}
                tooltip={
                  idx === 0
                    ? getLabel("newLotTankDestTooltip", {
                        state: getLabel(`lotState_${state}`)?.toLowerCase(),
                      })
                    : ""
                }
              />
              {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                // <TankIcon
                //   tank={getValues(`dest_tanks.${idx}.tankData`)}
                //   isText={true}
                //   total={null}
                //   isDestemming={true}
                //   isSrc={false}
                // />
                <DestTankDetail
                  tank={getTankData(dest_tanks?.[idx])}
                  isDrop={false}
                  isName={false}
                  isFirst={idx === 0}
                  isMobile={isMobile}
                ></DestTankDetail>
              )}
              {fields?.length > 0 &&
                getValues(`dest_tanks.${idx}.tankData`)?.value && (
                  <Input
                    name={`dest_tanks.${idx}.quantity`}
                    register={register}
                    type="number"
                    label={getLabel(`operationSrcTankQuantity`)}
                    placeholder={getLabel(
                      "operationDestTanksQuantityPlaceholder"
                    )}
                    error={getError(idx, errors?.dest_tanks, "quantity")}
                    required={getLabel("inputRequiredError")}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: Math.min(
                        dest_tanks[0]?.tankData?.availability,
                        Number(getValues("quantity"))
                      ),
                      message: getLabel("errorMaxValue", {
                        value: Math.min(
                          dest_tanks[0]?.tankData?.availability,
                          Number(getValues("quantity"))
                        ),
                      }),
                    }}
                    validate={() => {
                      if (getTotalQuantity() <= Number(getValues("quantity")))
                        dest_tanks?.forEach((_, index) =>
                          clearErrors(`dest_tanks.${index}.quantity`)
                        );
                      else return getLabel("toast_invalidQuantity");
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                    tooltip={
                      idx === 0 ? getLabel("cutTankInQuantityTooltip") : ""
                    }
                  />
                )}
              {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                <div className="batch_name">
                  <Select
                    control={control}
                    defaultValue={{
                      value: "",
                      label: "",
                    }}
                    name={`dest_tanks.${idx}.batch_name_selector`}
                    // name={`batch_name_selector.${idx}`}
                    // name="batch_name_selector"
                    label={getLabel("operationNewLotName")}
                    placeholder={getLabel("operationNewLotNamePlaceholder")}
                    error={errors}
                    required={getLabel("inputRequiredError")}
                    options={[
                      {
                        value: true,
                        label: getLabel("lotNewName"),
                      },
                      ...computeLotNames(),
                    ]}
                    tooltip={
                      idx === 0
                        ? getLabel("destemmingTankDestBatchNameSelectorTooltip")
                        : ""
                    }
                  />
                  {getValues(`dest_tanks.${idx}.batch_name_selector`)
                    ?.value && (
                    <Input
                      name={`dest_tanks.${idx}.new_batch_name`}
                      error={getError(
                        idx,
                        errors?.dest_tanks,
                        "new_batch_name"
                      )}
                      // name="new_batch_name"
                      register={register}
                      type="text"
                      label={getLabel("operationNewBatchName")}
                      placeholder={getLabel("operationNewBatchNamePlaceholder")}
                      // error={errors["new_batch_name"]}
                      required={getLabel("inputRequiredError")}
                      isReverse={true}
                      minLength={{
                        value: 3,
                        message: getLabel("errorMinLength", { value: 3 }),
                      }}
                      maxLength={{
                        value: 24,
                        message: getLabel("errorMaxLength", {
                          value: 24,
                        }),
                      }}
                      regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                      }}
                      validate={(value) => {
                        if (
                          getValues(`dest_tanks.${idx}.batch_name_selector`)
                            ?.value &&
                          lots?.map((l) => l?.name)?.includes(value?.trim())
                        ) {
                          return getLabel("errorNewBatchNameAlreadyTaken", {
                            name: value,
                          });
                        }
                      }}
                    />
                  )}
                </div>
              )}
              {fields?.length > 1 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    remove(idx);
                  }}
                >
                  -
                </button>
              )}
            </div>
          );
        })}
        <button
          id="tanksD"
          onClick={(e) => {
            e.preventDefault();
            append({
              tankData: {},
              quantity: 0,
              new_batch_name: "",
              batch_name_selector: "",
            });
          }}
        >
          +
        </button>
      </div>
      <div className="operation_form_wrapper_row">
        <MultipleSelect
          name={`cellarman_ids`}
          control={control}
          label={getLabel("operationUser")}
          placeholder={getLabel("operationUserPlaceholder")}
          error={errors["cellarman_ids"]}
          required={getLabel("inputRequiredError")}
          options={(user.role === 2
            ? users?.filter(
                (u) => u.username != user.userName && u.role_id === 3
              )
            : users
          )?.map?.((user) => {
            return {
              value: user?.id,
              label: user?.username,
            };
          })}
          tooltip={getLabel("destemmingCellarmenTooltip")}
        />
        <Select
          name="priority"
          control={control}
          label={getLabel("operationPriority")}
          placeholder={getLabel("operationPriorityPlaceholder")}
          error={errors}
          required={getLabel("inputRequiredError")}
          options={[
            {
              label: getLabel("lotQuality_HIGH"),
              value: "2",
            },
            {
              label: getLabel("lotQuality_MEDIUM"),
              value: "1",
            },
            {
              label: getLabel("lotQuality_LOW"),
              value: "0",
            },
          ]}
          // defaultValue={{
          //     value: "",
          //     label: "",
          //   }}
        />
        <DatePicker
          name="expire_date"
          control={control}
          label={getLabel("operationExpireDateForm")}
          error={errors["expire_date"]}
          required={getLabel("inputRequiredError")}
          dateFormat={configs.shortDateFormat}
          placeholder={getLabel("expiringDatePlaceholder")}
        />
        <Input
          name={"expire_date_exact"}
          register={register}
          label={getLabel("isExactDate")}
          error={errors["expire_date_exact"]}
          // isLabel={false}
          type={"checkbox"}
          checked={isExactDate}
          onChange={() => setIsExactDate((prev) => !prev)}
          customClassName={"confirm"}
          tooltip={getLabel("destemmingExactDate")}
        />
      </div>
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={sendData}
        onClose={handleCloseModal}
        description={getLabel("modalNewLotDescription", {
          name: operation?.id || "",
        })}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
      >
        <OperationRecap
          type={"NEW_LOT"}
          control={control}
          register={register}
          getValues={getValues}
          errors={errors}
          lots={lots}
          lotNames={lots?.map((l) => l?.name)}
          dest_tanks={dest_tanks}
          src_tanks={[
            {
              name: getValues("name"),
              quantity: getValues("quantity"),
              initial_quantity: getValues("quantity"),
              capacity: getValues("quantity"),
              color: getValues("color")?.value,
              state: getValues("state")?.value,
            },
          ]}
        />
      </ConfirmationModal>
    </div>
  );
};

export default NewLotCreationForm;
